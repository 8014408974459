<ng-form class="login-form" [formGroup]="cvForm">

    <div class="main_row content-component">

        <div class="left_side_container left-content">
          <div class="companyjob-information sec2" fxShow="true" fxShow.xs="true" fxShow.sm="true"
          fxShow.lg="true" fxShow.md="true">
          <!-- column to contain third part into second column  -->
            <!--<div *ngIf="job.image" class="jobimage" fxLayout="column" fxFlex="30" fxLayoutAlign="space-between stretch" >
              <div class="card-image-box" [style.height]="'240px'">
                <span class="urgent-icon" *ngIf="job.urgent === 1">
                  <mat-icon svgIcon="icon_urgent"></mat-icon>
                </span>
                <div class="card-image-box-size">
                  <img mat-card-md-image class="job-image"
                    src="{{ imageBaseUrl + job.image }}" alt="">
                </div>
              </div>
            </div>-->
            <div class="infos" fxFlex="100" fxLayout="column" fxLayoutAlign="space-between stretch" >
              <!-- first row imagee/facebook/linkedin with space between -->
              <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="40">
                <div *ngIf="job && job.logo" class="clogo" fxFlex="50" fxLayoutAlign="start">
                  <img class="companyIcon" style="cursor: pointer; height: 130px; width: 130px;"
                    src="{{ imageBaseUrl + job?.logo }}" width="130px">
                </div>
                <div fxFlex="100" fxLayoutAlign="end">
                  <button mat-icon-button *ngIf="job && job.facebook">
                    <a href="http://www.google.com" target="_blank" rel="noopener noreferrer">
                      <mat-icon svgIcon="facebook"></mat-icon>
                    </a>
                  </button>
                  <button mat-icon-button *ngIf="job && job.linkedIn">

                    <a href="{{job.linkedIn}}" target="_blank" rel="noopener noreferrer">
                      <mat-icon svgIcon="icon_linkedin"></mat-icon>
                    </a>
                  </button>
                </div>
              </div>
              <!-- second row but he must contain two different bloc -->
              <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="31">
                <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="45">
                  <div fxFlex="65" fxLayoutAlign="start">
                    <div class="text-truncate jobtitle" style="font-weight: bold; font-size: 20px; color: #42475E">
                      {{ job?.jobName }}
                      <!-- <span style="font-weight: bold; font-size: 12px !important;">
                        {{job.jobType}}
                      </span> -->
                    </div>
                  </div>
                  <div fxFlex="35" fxLayoutAlign="end">
                    <div style="font-weight: bold; font-size: 16px; color: #42475E; word-wrap: break-word;">
                      {{ job?.salary === '0.00' ? 'N/A' : job?.salary | number:'1.2-2' }} {{job?.currency}}</div>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="22">
                  <div fxFlex="65" fxLayoutAlign="start">
                    <div style="font-size: 11px; color: #42475E; text-transform: capitalize;" class="text-truncate">
                      {{ job?.jobType }} - {{ job?.address }}
                    </div>
                  </div>
                  <div fxFlex="25" fxLayoutAlign="end">
                    <span style="font-size: 16px; color: #42475E;">net<!--/mois--></span>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="33">
                  <button class="button" fxFlex="65">
                    <strong class="jobCategoryThumbnail" style="font-weight: bold; display: inline-flex">
                      <mat-icon [svgIcon]="job?.jobCategoryImage"></mat-icon>
                      <span style="font-size: 11; color: #42475E;">{{ job?.jobCategoryName }}</span>
                    </strong>
                  </button>
                </div>
              </div>

              <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="19">
                <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="40">
                  <div fxFlex="100" fxLayoutAlign="start">
                    <div style="font-size: 14px; color: #42475E; text-transform: capitalize;">
                      Ecoutez l'offre d'emploi
                    </div>
                  </div>
                </div>
                <!--<audio controls style="width: inherit;" fxFlex="45" src="{{ imageBaseUrl + job?.audio }} ">
                </audio>-->
                <!--job-single-player-->
                <div class="job-single-player" fxLayout="row" fxLayoutAlign="stretch center" fxFlex="60">
                  <div fxFlex="15">
                    <img src="assets/image/picto_audio_seul.svg" />
                  </div>
                  <div fxFlex="10">
                    <button mat-icon-button fxFlex="100" *ngIf="state?.playing" (click)="pause()">
                      <mat-icon class="icoWhyte">pause</mat-icon>
                    </button>
                    <button mat-icon-button fxFlex="100" (click)="play()" [disabled]="state?.error"
                      *ngIf="!state?.playing">
                      <mat-icon class="icoWhyte">play_circle_filled </mat-icon>
                    </button>
                  </div>
                  <div fxFlex="15">
                    <span class="time">{{ state?.readableCurrentTime }}</span>
                  </div>
                  <div fxFlex="50">
                    <mat-slider class="time-slider w-100 text-white leftview" min="0" [max]="state?.duration" step="1"
                      [value]="state?.currentTime" (input)="onSliderChangeEnd($event)" [disabled]="state?.error">
                    </mat-slider>
                  </div>
                  <div fxFlex="15">
                    <span class="time">{{ state?.readableDuration }}</span>
                  </div>
                  <div fxFlex="10" *ngIf="!show">
                    <button mat-icon-button fxFlex="100" (click)="show=true">
                      <mat-icon class="icoWhyte" *ngIf="val">volume_up</mat-icon>
                      <mat-icon class="text-white" *ngIf="!val" mat-list-icon> volume_off</mat-icon>
                    </button>
                  </div>
                  <div fxFlex="10" *ngIf="show" fxLayout="column">
                    <mat-slider vertical class="volume-slider w-50" min="0" fxFlex="100" [max]="1" step=".1"
                      [value]="state?.volume" (input)="onVolumeChangeEnd($event)"></mat-slider>
                    <button mat-icon-button fxFlex="100" (click)="mute()">
                      <mat-icon class="icoWhyte" *ngIf="val">volume_up</mat-icon>
                      <mat-icon class="text-white" *ngIf="!val" mat-list-icon> volume_off</mat-icon>
                    </button>
                  </div>
                  <div fxFlex="10">
                    <button mat-icon-button fxFlex="100">
                      <mat-icon class="icoWhyte"> more_vert</mat-icon>
                    </button>
                  </div>
                </div>
                <!--job-single-player-->
                <!--<button class="btn mbtne blue btn-ova" fxFlex="25">
                  <mat-icon class="tab-icon-space" svgIcon="icon_contact"></mat-icon>
                  <span style="color: #68827e;" class="botton-text">Contacter l'employeur</span>
                </button>-->
              </div>
            </div>
          </div>
        </div>

        <div class="right_side_container principal">

            <div class="right_top_side_header">
                <div class="back-to">
                    <a routerLink="" mat-button *ngIf="isFromExternUrl == 0">
                        <mat-icon style="color: green">keyboard_backspace</mat-icon>
                        <span> Accueil </span>
                    </a>
                </div>
                <div fxLayoutAlign="center center" fxLayout="column">
                    <mat-icon svgIcon="gotaf_color" class="login-logo"></mat-icon>
                    <p style="color: #c3c4c6; font-size: 18px;">Créez votre CV pour Postuler</p>

                </div>
            </div>

            <div class="right_side_center">
                <mat-horizontal-stepper #stepper class="center container">
                    <mat-step>
                        <div fxLayout="column" fxLayoutAlign="space-around ">
                            <!-- <h2 class="element"> -->
                            <!--<div>
                                <p class="swiper-div">
                                    <mat-icon class="swipe-icon"> touch_app</mat-icon>
                                </p>
                                <div class="category-row">
                                    <div class="bloc-category">
                                        <div fxFlex="134px" fxFlex.xs="110px" fxFlex.sm="110px" fxFlex.md="134px" class="bloc-mat-tile" *ngFor="let jobCategory of jobCategories; let i = index">
                                            <button [ngClass]="{'selected-card': isSelect(i)}" class="mt-sm-0" mat-button (click)="addToSelected(jobCategory, i)">
                                                <p style="font-size: 10px; color: #2e4057; font-weight: 600;"> {{
                                                    jobCategory.name }} </p>
                                                <mat-icon [svgIcon]="jobCategory.image"></mat-icon>
                                            </button>
                                            <img class="category-audio-icon" (click)="$event.stopPropagation();playAudio(imageBaseUrl+jobCategory.audio)" src="assets/image/picto_audio_medium.png">
                                        </div>

                                    </div>
                                </div>
                            </div>-->
                            <div style="margin-top: 1em;" fxLayout="column">

                                <div class="title-actions" style="padding-top: 2em;" fxFlex="50" fxLayout="row" fxLayoutAlign="center center">
                                  <div fxFlex="50">Enregistrez votre message audio</div>
                                  <div fxFlex="50">Ecoutez un exemple</div>
                                </div>
                                <div style="padding-top: 2em;" fxFlex="50" fxLayout="row" fxLayoutAlign="center center">
                                    <div fxFlex="40">
                                        <app-reccord-audio (audioBlob)="buildAudioFile($event)" (recordFile)="playRecord($event)" (shows)="stop($event)">
                                        </app-reccord-audio>
                                    </div>
                                    <div *ngIf="!cvForm.controls.audioFileCheck.valid" style="color:red;margin-top:10px;">
                                      <div *ngIf="cvForm.controls.audioFileCheck.hasError('required')">
                                        <mat-icon>keyboard_arrow_left</mat-icon> Votre Cv Audio est requis
                                      </div>
                                    </div>

                                    <div fxFlex="40">
                                        <button mat-fab style="width: 5em; height: 5em;" style="background-color: rgb(69, 128, 118);" matTooltip="Exemple de CV audio">
                                            <mat-icon svgIcon="resume_audio" class="resume-audio"
                                                style="width: 5em; height: 5em; margin-top: -20px; margin-left: -10px; cursor: default;">
                                            </mat-icon>
                                        </button>
                                        <div style="height: 0;">
                                            <img class="audio-icon-badge" (click)="playAudio(cvAudioUrl)" style="cursor: pointer;" src="assets/image/picto_audio_medium.png">
                                        </div>
                                    </div>

                                    <!--                                <span style="margin-left: 1%; margin-right: 1%; color: #42465d;">-->
                                    <!--                                    OU-->
                                    <!--                                </span>-->
                                    <!--                                <input type="file" #fileUpload id="fileUpload" name="fileUpload" accept="audio/*"-->
                                    <!--                                    style="display:none;" />-->

                                    <!--                                &lt;!&ndash; <input style="display: none" type="file" (change)="onFileSelect($event)" #hiddenfileinput> &ndash;&gt;-->
                                    <!--                                <button mat-button style="color: #42465d;" (click)="onClick()">-->
                                    <!--                                    <mat-icon class="attach-button">attach_file</mat-icon>-->
                                    <!--                                    Charger un audio-->
                                    <!--                                </button>-->
                                </div>
                            </div>

                            <div style="margin-top: 1em;">

                                <!--experience chart-->
                                <!--<div class="char-container">
                                    <canvas id="expChart" style="align-self: center;"></canvas>
                                </div>
                                <mat-slider class="time-slider w-100 text-white" min="1" [max]="3" step="1" value="{{cvForm.controls.experienceLevel}}" tickInterval="1" style="width: -webkit-fill-available; max-width: 380px; margin: auto; z-index: 0;" (input)="onSliderChangeExp($event)">
                                </mat-slider>-->
                                <!--end experience chart-->

                            </div>
                            <div style="width: 100%; margin-top: 5%; max-width: 670px; margin: auto;">
                                <mat-form-field appearance="none" style="width: 100%; color: #42465d;">
                                    <input class="login-input" matInput placeholder="Nom" formControlName="lastName">
                                    <div *ngIf="!cvForm.controls.lastName.valid" style="color:red;margin-top:10px;">
                                        <div *ngIf="cvForm.controls.lastName.hasError('required')">
                                            Le Nom de Famille est requis
                                        </div>
                                    </div>
                                </mat-form-field>

                                <mat-form-field appearance="none" style="width: 100%; color: #42465d;">
                                    <input class="login-input" matInput placeholder="Prénom" formControlName="firstName">
                                    <div *ngIf="!cvForm.controls.firstName.valid" style="color:red;margin-top:10px;">
                                        <div *ngIf="cvForm.controls.firstName.hasError('required')">
                                            Le Prénom est requis
                                        </div>
                                    </div>
                                </mat-form-field>

                                <mat-form-field appearance="none" style="width: 100%;" *ngIf="!user">
                                  <input class="login-input login_country" matInput placeholder="Téléphone" formControlName="userPhone" autocomplete="off" >
                                  <mat-select [(ngModel)]="countryCodeSelected" formControlName="country"  [ngClass]="countryCodeSelected" id="select_country" >
                                      <mat-option value="option1">+1
                                          <img with="10" height="10" src="assets/image/flag/us.png">
                                      </mat-option>
                                      <mat-option value="option2">+33
                                          <img with="10" height="10" src="assets/image/flag/fr.png">
                                      </mat-option>
                                      <mat-option value="option3">+221
                                          <img with="10" height="10" src="assets/image/flag/sn.png">
                                      </mat-option>
                                      <mat-option value="option4">+229
                                        <img with="10" height="10" src="assets/image/flag/bj.png">
                                      </mat-option>
                                      <mat-option value="option5">+228
                                        <img with="10" height="10" src="assets/image/flag/tg.png">
                                      </mat-option>
                                      <mat-option value="option6">+225
                                        <img with="10" height="10" src="assets/image/flag/ci.png">
                                      </mat-option>
                                  </mat-select>
                                  <div *ngIf="!cvForm.controls.userPhone.valid" style="color:red;margin-top:10px;">
                                    <div *ngIf="cvForm.controls.userPhone.hasError('required')">
                                      Le Numéro de téléphone est requis
                                    </div>
                                </div>
                              </mat-form-field>
                            </div>
                            <!-- </h2> -->

                        </div>
                        <div class="element1" fxLayout="row" fxLayoutAlign="center center" style="max-width: 670px !important; margin: auto; justify-content: space-between;">
                            <button class="default-button-style button-color-default" mat-button (click)="submitInformations()">
                                Sauvegarder et postuler
                            </button>
                        </div>
                        <!--<div style="max-width: 670px;" fxLayoutAlign="center center">
                            <div>
                                <button mat-button (click)="back()" style="color: #42465d;">
                                    <h4>Quitter </h4>
                                </button>
                            </div>
                        </div>-->
                    </mat-step>
                </mat-horizontal-stepper>
            </div>

            <div class="right_side_bottom">

            </div>

        </div>
    </div>
</ng-form>
<!--audio player-->
<div class="playerDiv nomenuaudioplayer" *ngIf="showPlayer">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="closePlayer()">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
    <app-audio-player></app-audio-player>
</div>
<!--end audio player-->
<!--<mat-icon class="audio-icon" svgIcon="audio-yellow" (click)="playAudio(audioUrl)"></mat-icon>-->
