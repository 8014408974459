<mat-toolbar class="header header-fixed animated fadeInDown" fxShow="true" fxHide.md="true" fxHide.lg="true" fxHide.xl="true" id="navbar" cdkScrollable>
    <a [routerLink]="['']" routerLinkActive="router-link-active">
        <mat-icon svgIcon="gotaf" class="logo"></mat-icon>
    </a>

    <span class="navbar-spacer"></span>

    <div [fxShow.xs]="!user" fxShow.md="true">
        <a (click)="sidenav.toggle()">
            <mat-icon class="menu-icon">menu</mat-icon>
        </a>
    </div>
</mat-toolbar>

<mat-toolbar id="header-desktop" class="animated fadeInDown" fxShow="true" fxHide.xs="true" fxHide.sm="true" cdkScrollable>
    <a href="">
        <mat-icon svgIcon="gotaf" class="logo"></mat-icon>
    </a>

    <div fxShow="true" fxHide.lt-md="true" [ngClass]="{ 'menu-items-first': isCompany }" class="menu-items">
        <!-- The following menu items will be hidden on both SM and XS screen sizes -->
        <div *ngIf="!user" class="general-menu">
            <button mat-button routerLink="/register">
        <mat-icon>supervisor_account</mat-icon> S'inscrire
      </button>
            <button mat-button routerLink="/login">
        <mat-icon svgIcon="icon_login"></mat-icon> Se connecter
      </button>
      <button mat-button (click)="publishJobs()">
        <mat-icon svgIcon="icon_publier"></mat-icon> Publier une offre
      </button>
      <button mat-button >
      <mat-select [ngClass]="selectedCountryValue" panelClass="mat-select-country" class="mat-select-country" id="country" [formControl]="selectedCountryControl" placeholder="Annonces par Pays" (selectionChange)="onCountryChange($event.value)">
        <mat-option *ngFor="let country of availableCountries" [value]="country.reference">
          <img with="20" height="20" [src]="country.imgurl"> &nbsp;{{country.name}}
        </mat-option>
      </mat-select>
        </button>
        <button class="corp-btn" mat-button onclick="window.open('https://corp-gotaf.fr', '_blank');">
          <mat-icon>domain</mat-icon> Site Corporatif
        </button>
        </div>
        <div *ngIf="user && !isCompany" class="user-menu">
            <a routerLink="/jobs">
                <button mat-button routerLink="/jobs" routerLinkActive="active">
          <mat-icon svgIcon="icon_jobs"></mat-icon> Nouvelles offres
        </button>
            </a>
            <a routerLink="/job-applied">
                <button mat-button routerLink="/job-applied" routerLinkActive="active">
          <mat-icon svgIcon="icon_applied"></mat-icon> Mes Candidatures
        </button>
            </a>
            <span class="vl"></span>
            <button mat-button routerLink="/job-favorite" routerLinkActive="active">
        <mat-icon svgIcon="icon_like"></mat-icon> Annonces sauvegardées
      </button>
            <span class="vl"></span>
            <button mat-button routerLink="/user-profile" routerLinkActive="active">
        <mat-icon svgIcon="icon_publish"></mat-icon> Mon CV
      </button>
      <button mat-button>
      <mat-select [ngClass]="selectedCountryValue" panelClass="mat-select-country" class="mat-select-country" id="country" [formControl]="selectedCountryControl" placeholder="Annonces par Pays" (selectionChange)="onCountryChange($event.value)">
        <mat-option *ngFor="let country of availableCountries" [value]="country.reference">
          <img with="20" height="20" [src]="country.imgurl"> &nbsp;{{country.name}}
        </mat-option>
      </mat-select>
      </button>
        </div>
        <div *ngIf="isCompany" class="company-menu">
            <button mat-button (click)="publishJobs()" routerLinkActive="active">
        <mat-icon svgIcon="icon_publish"></mat-icon> Publier une nouvelle offre
      </button>
            <button mat-button routerLink="/company-jobs" routerLinkActive="active">
        <mat-icon svgIcon="icon_like"></mat-icon> Mes offres publiées
      </button>
            <button mat-button routerLink="/company-job-applied" routerLinkActive="active">
        <mat-icon svgIcon="icon_candidates"></mat-icon>
        Les candidats
      </button>
      <button mat-button>
      <mat-select [ngClass]="selectedCountryValue" panelClass="mat-select-country" class="mat-select-country" id="country" [formControl]="selectedCountryControl" placeholder="Annonces par Pays" (selectionChange)="onCountryChange($event.value)">
        <mat-option *ngFor="let country of availableCountries" [value]="country.reference">
          <img with="20" height="20" [src]="country.imgurl"> &nbsp;{{country.name}}
        </mat-option>
      </mat-select>
      </button>
      <button class="corp-btn" mat-button onclick="window.open('https://corp-gotaf.fr', '_blank');">
        <mat-icon>domain</mat-icon> Site Corporatif
      </button>
        </div>
    </div>
    <span class="navbar-spacer"></span>
    <img class="profile" src="{{ image }}" *ngIf="user" [matMenuTriggerFor]="userMenu" />
    <mat-menu #userMenu="matMenu">
        <button mat-menu-item routerLink="/user-parameter">
      <mat-icon>account_box</mat-icon>
      <span>Parametre de compte</span>
    </button>
        <button mat-menu-item routerLink="/company-modify" *ngIf="user && isCompany">
      <mat-icon>settings</mat-icon>
      <span>Informations Entreprise</span>
    </button>
        <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Déconnexion</span>
    </button>
    </mat-menu>
</mat-toolbar>

<mat-sidenav-container fxFlexFill class="global-container" fxShow="true" fxShow.gt-xs="true" fxShow.gt-md="true">
    <mat-sidenav #sidenav class="responsive-menu">
        <div class="menu-items">
            <!--        <a (click)="sidenav.toggle()" href="#" mat-button>Close</a>-->
            <div *ngIf="!user" class="general-menu" fxLayout="column">
                <button mat-button routerLink="/register" routerLinkActive="active" class="sid-button">
          <mat-icon>supervisor_account</mat-icon> S'inscrire
        </button>
                <button mat-button routerLink="/login" routerLinkActive="active" class="sid-button">
          <mat-icon svgIcon="icon_login"></mat-icon> Se connecter
        </button>
                <button mat-button (click)="publishJobs()" routerLinkActive="active" class="sid-button">
          <mat-icon svgIcon="icon_publier"></mat-icon> Publier une offre
        </button>
        <button mat-button class="sid-button">
          <mat-select [ngClass]="selectedCountryValue" panelClass="mat-select-country-pad" class="mat-select-country" id="country" [formControl]="selectedCountryControl" placeholder="Annonces par Pays" (selectionChange)="onCountryChange($event.value)">
            <mat-option *ngFor="let country of availableCountries" [value]="country.reference">
              <img with="20" height="20" [src]="country.imgurl"> &nbsp;{{country.name}}
            </mat-option>
          </mat-select>
          </button>
          <button class="corp-btn sid-button" mat-button onclick="window.open('https://corp-gotaf.fr', '_blank');">
            <mat-icon>domain</mat-icon> Site Corporatif
          </button>
            </div>
            <div *ngIf="user && !isCompany" class="user-menu" fxLayout="column">
                <button mat-button routerLink="/jobs" routerLinkActive="active">
          <mat-icon svgIcon="icon_jobs"></mat-icon> Nouvelles offres
        </button>
                <button mat-button routerLink="/job-applied" routerLinkActive="active">
          <mat-icon svgIcon="icon_applied"></mat-icon> Mes Candidatures
        </button>
                <!-- <span class="vl"></span> -->
                <button mat-button routerLink="/job-favorite" routerLinkActive="active">
          <mat-icon svgIcon="icon_like"></mat-icon> Annonces sauvegardées
        </button>
                <!-- <span class="vl"></span> -->
                <button mat-button routerLink="/user-profile" routerLinkActive="active">
          <mat-icon svgIcon="icon_publish"></mat-icon> Mon CV
        </button>
          <button mat-button>
          <mat-select [ngClass]="selectedCountryValue" panelClass="mat-select-country-pad" class="mat-select-country" id="country" [formControl]="selectedCountryControl" placeholder="Annonces par Pays" (selectionChange)="onCountryChange($event.value)">
            <mat-option *ngFor="let country of availableCountries" [value]="country.reference">
              <img with="20" height="20" [src]="country.imgurl"> &nbsp;{{country.name}}
            </mat-option>
          </mat-select>
          </button>
          </div>
            <div *ngIf="isCompany" class="company-menu">
                <button mat-button (click)="publishJobs()" routerLinkActive="active">
          <mat-icon svgIcon="icon_publish"></mat-icon> Publier une nouvelle
          offre
        </button>
                <button mat-button routerLink="/company-jobs" routerLinkActive="active">
          <mat-icon svgIcon="icon_like"></mat-icon> Mes offres publiées
        </button>
                <button mat-button routerLink="/company-job-applied" routerLinkActive="active">
          <mat-icon svgIcon="icon_candidates"></mat-icon> Les candidats
        </button>
        <!--<mat-icon>map</mat-icon>-->
        <button mat-button>
        <mat-select [ngClass]="selectedCountryValue" panelClass="mat-select-country-pad" class="mat-select-country" id="country" [formControl]="selectedCountryControl" placeholder="Annonces par Pays" (selectionChange)="onCountryChange($event.value)">
          <mat-option *ngFor="let country of availableCountries" [value]="country.reference">
            <img with="20" height="20" [src]="country.imgurl"> &nbsp;{{country.name}}
          </mat-option>
        </mat-select>
        </button>
        <button class="corp-btn" mat-button onclick="window.open('https://corp-gotaf.fr', '_blank');">
          <mat-icon>domain</mat-icon> Site Corporatif
        </button>
                <button mat-mini-fab color="primary" *ngIf="user" [matMenuTriggerFor]="userMenu">
          <img class="profile" src="assets/image/0.svg" *ngIf="user" />
        </button>
                <mat-menu #userMenu="matMenu">
                    <button mat-menu-item routerLink="/user-parameter">
            <mat-icon>account_box</mat-icon>
            <span>Parametre de compte</span>
          </button>
                    <button mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Déconnexion</span>
          </button>
                </mat-menu>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill style="display: contents">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>

<app-footer *ngIf="showFooter"></app-footer>
<!-- <div fxShow="false" fxShow.xs="true" *ngIf="user"
  [ngClass]="{'user-connect-bloc': user, 'user-disconnect-bloc': !user}"></div> -->
<app-audio-player *ngIf="showPlayer"></app-audio-player>
<div class="navbar-fixed-bottom" style="padding-bottom: 20px" fxShow="false" fxShow.xs="true" *ngIf="user">
    <div fxLayout="row" fxLayoutAlign="space-evenly center" *ngIf="user && !isCompany">
        <button fxFlex="20" fxLayout="column" fxLayoutAlign="space-evenly end" mat-button routerLink="/jobs" routerLinkActive="active">
      <mat-icon svgIcon="icon_jobs" class="footer-icon-color"></mat-icon>
      <div class="footer-button-texte">Emplois</div>
    </button>
        <button fxFlex="20" fxLayout="column" fxLayoutAlign="space-evenly center" mat-button routerLink="/job-applied" routerLinkActive="active">
      <mat-icon svgIcon="icon_applied" class="footer-icon-color"></mat-icon>
      <div class="footer-button-texte">Candidatures</div>
    </button>
        <button fxFlex="20" fxLayout="column" fxLayoutAlign="space-evenly center" mat-button routerLink="/job-favorite" routerLinkActive="active">
      <mat-icon class="footer-icon-color" svgIcon="icon_like"></mat-icon>
      <div class="footer-button-texte">Mes Annonces</div>
    </button>
        <button fxFlex="20" fxLayout="column" fxLayoutAlign="space-evenly center" mat-button routerLink="/user-profile" routerLinkActive="active">
      <mat-icon class="footer-icon-color" svgIcon="icon_publish"></mat-icon>
      <div class="footer-button-texte">Mon CV</div>
    </button>
    <button mat-button>
    <mat-select [ngClass]="selectedCountryValue"  panelClass="mat-select-country-mobi" class="mat-select-country" id="country" [formControl]="selectedCountryControl" placeholder="Annonces par Pays" (selectionChange)="onCountryChange($event.value)">
      <mat-option *ngFor="let country of availableCountries" [value]="country.reference">
        <img with="20" height="20" [src]="country.imgurl"> &nbsp;{{country.name}}
      </mat-option>
    </mat-select>
    </button>
        <div fxFlex="20" fxLayoutAlign="center center" fxLayout="column">
            <img class="profile" src="{{ image }}" *ngIf="user" [matMenuTriggerFor]="userMenu" />
            <mat-menu #userMenu="matMenu" yPosition="above">
                <button mat-menu-item routerLink="/user-parameter">
          <mat-icon>account_box</mat-icon>
          <span>Parametre de compte</span>
        </button>
                <button mat-menu-item routerLink="/company-modify" *ngIf="user && isCompany">
          <mat-icon>settings</mat-icon>
          <span>Informations Entreprise</span>
        </button>
                <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Déconnexion</span>
        </button>
            </mat-menu>
            <div>.</div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-evenly center" *ngIf="isCompany">
        <!-- <button fxFlex="25" fxLayout="column" fxLayoutAlign="space-evenly center" mat-button (click)="publishJobs()" routerLinkActive="active">
      <mat-icon class="footer-icon-color" svgIcon="icon_publier"></mat-icon>
      <div class="footer-button-texte">Publier une offre</div>
    </button> -->
        <button fxFlex="25" fxLayout="column" fxLayoutAlign="space-evenly center" mat-button routerLink="/job-add" routerLinkActive="active">
      <mat-icon class="footer-icon-color" svgIcon="icon_publier"></mat-icon>
      <div class="footer-button-texte">Publier une offre</div>
    </button>
        <button fxFlex="25" fxLayout="column" fxLayoutAlign="space-evenly center" mat-button routerLink="/company-jobs" routerLinkActive="active">
      <mat-icon class="footer-icon-color" svgIcon="icon_like"></mat-icon>
      <div class="footer-button-texte">Mes offres</div>
    </button>
        <button fxFlex="25" fxLayout="column" fxLayoutAlign="space-evenly end" mat-button routerLink="/company-job-applied" routerLinkActive="active">
      <mat-icon class="footer-icon-color" svgIcon="icon_candidates"></mat-icon>
      <div class="footer-button-texte">Les candidats</div>
    </button>
    <button mat-button>
    <mat-select [ngClass]="selectedCountryValue" panelClass="mat-select-country-mobi" class="mat-select-country" id="country" [formControl]="selectedCountryControl" placeholder="Annonces par Pays" (selectionChange)="onCountryChange($event.value)" style="margin-left: 1em; max-width:150px;font-size:14px;">
      <mat-option *ngFor="let country of availableCountries" [value]="country.reference">
        <img with="20" height="20" [src]="country.imgurl"> &nbsp;{{country.name}}
      </mat-option>
    </mat-select>
  </button>
        <div fxFlex="25" fxLayoutAlign="center center" fxLayout="column">
            <img class="profile" src="{{ image }}" *ngIf="user" [matMenuTriggerFor]="userMenu" />
            <mat-menu #userMenu="matMenu" yPosition="above">
              <button mat-menu-item  onclick="window.open('https://corp-gotaf.fr', '_blank');">
                <span>Site Corporatif</span>
              </button>
                <button mat-menu-item routerLink="/user-parameter">
          <mat-icon>account_box</mat-icon>
          <span>Parametre de compte</span>
        </button>
                <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Déconnexion</span>
        </button>
            </mat-menu>
            <div>.</div>
        </div>
    </div>
</div>
