import { Component, HostListener, NgZone, OnInit, AfterViewInit } from '@angular/core';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { Role } from '../../model/role';
import { AuthenticationService } from '../../service/authentication/authentication.service';
import { User } from '../../model/user';
import { environment } from '../../../environments/environment';
import { SharedServiceService } from '../../shared/shared-service.service';
import { Platform } from '@angular/cdk/platform';
import { ContactUsComponent } from '../contact-us/contact-us.component'
import { MatDialog } from '@angular/material/dialog';
import { CompanyJobMultipleComponent } from '../company/company-job-multiple/company-job-multiple.component';
import { CompanyService } from '../../service/company/company.service';
import {AvailableCountry} from '../../model/availableCountry';
import { Router } from '@angular/router';
import { HttpClientService } from '../../service/http-client/http-client.service';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: []
})
export class HeaderComponent implements OnInit, AfterViewInit {
  isOnTop = true;
  public user: User;
  public image: string;
  public showPlayer = true;
  public showFooter = true;
  public isIOSMobileDevice = false;
  public availableCountries = Array<AvailableCountry>();
  public selectedCountryValue = "FR";
  public selectedCountryControl: FormControl;

  company: any;
  constructor(
    private scrollDispatcher: ScrollDispatcher,
    private zone: NgZone,
    private authenticationService: AuthenticationService,
    private plt: Platform,
    private sharedService: SharedServiceService,
    private matDialog: MatDialog,
    private companyService: CompanyService,
    private httpClientService: HttpClientService,
    private router: Router
  ) {
    this.authenticationService.user.subscribe(x => {
      console.log(!x);
      this.user = x;
    });
    // this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      // console.log(this.user);
      this.image = environment.imageBaseUrl + this.user.image;
    } else {
      this.image = 'assets/image/icon_like.svg';
    }
    // console.log(this.image);
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit(): void {
    this.scrollDispatcher.scrolled().subscribe((event: CdkScrollable) => {
      console.log(event);
      if (event === undefined) {
        return;
      } else {
        const scroll = event.measureScrollOffset('top');
        let newIsOnTop = this.isOnTop;

        newIsOnTop = scroll <= 0;

        if (newIsOnTop !== this.isOnTop) {
          this.zone.run(() => {
            this.isOnTop = newIsOnTop;
          });
        }
      }
    });
    this.sharedService.navigationToDetails.subscribe((result) => this.showFooter = result);
    this.sharedService.navigation.subscribe((data) => this.showPlayer = data);
    if (this.plt.IOS) {
      this.isIOSMobileDevice = true;
    }
  }

  ngOnInit(): void {
    // console.log(this.user);
    const element = document.querySelector('#header-desktop');
    element.classList.add('header-transparent');

    this.availableCountries = [
      { name :""/*"Annonces France"*/, imgurl:"assets/image/flag/fr.png", reference:"FR" },
      { name :""/*"Annonces Sénégal"*/, imgurl:"assets/image/flag/sn.png", reference:"SN" },
      { name :""/*"Annonces Usa"*/, imgurl:"assets/image/flag/us.png", reference:"US" },
      { name :""/*"Annonces Bénin"*/, imgurl:"assets/image/flag/bj.png", reference:"BJ" },
      { name :""/*"Annonces Togo"*/, imgurl:"assets/image/flag/tg.png", reference:"TG" },
      { name :""/*"Annonces Côte d'Ivoire"*/, imgurl:"assets/image/flag/ci.png", reference:"CI" }
    ];
    this.httpClientService.bheadersObservable.subscribe(headers => {
      this.selectedCountryValue = headers.get('Country');
    });
    console.log(this.selectedCountryValue);
    this.selectedCountryControl = new FormControl(this.selectedCountryValue);

  }

  public onCountryChange(value:string) {
    this.httpClientService.modifyCountry(value);
    console.log(value);
    // #TODO refresh component (for refreshing results with new value)
    /*let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
        console.log(currentUrl);
    });*/
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }


  public get isCompany(): boolean {
    return this.user && this.user.role === Role.Company;
  }

  public logout(): void {
    this.authenticationService.logout();
  }

  public publishJobs(): void {
    // this.companyService.getCompanyByUser(this.authenticationService.userValue.id).subscribe((data) => {
    //   this.company = data;
    //   if (data.jobSolde === 0 || data.jobSolde === null) {
    //     this.router.navigate([`/subscription-plan/${this.company.id}`]);
    //   } else {
    //     this.matDialog.open(CompanyJobMultipleComponent, { width: '450px', height: '300px' });
    //   }

    // }, ignore => {
    //   console.log('error when loading lastJobs');
    // });

    this.matDialog.open(ContactUsComponent, { width: '470px', height: '470px' });
  }


  @HostListener('window:scroll', ['$event'])

  public onWindowScroll(): void {
    const element = document.querySelector('#header-desktop');
    // console.log(element);
    // console.log(element.clientHeight);
    if (window.pageYOffset > element.clientHeight) {
      element.classList.remove('header-transparent');
      element.classList.add('header-fixed');
    } else {
      element.classList.add('header-transparent');
      element.classList.remove('header-fixed');
    }
  }

}

