<ng-container *ngIf="jobsService.isVoirCLick">
    <div fxLayout="row nowrap" fxHide fxFlexFill fxLayoutAlign="space-between stretch" class="homepage-header">
        <div class="navbar-title" fxFlex="1 1" fxFlexFill fxShow="false" fxShow.gt-sm="true" fxShow.gt-lg="true">
            <span style="font-family: Montserrat, 'Montserrat' , sans-serif;">Restez à l'écoute<br />des offres
                d'emploi<br />où
                que vous soyez</span>
                <br>
                <div class="gotaf-app">
                    <a href="#">
                        <mat-icon class="gotaf-app-icon" svgIcon="gotaf_apple_get"></mat-icon>
                    </a>
                    <a href="#">
                        <mat-icon class="gotaf-app-icon" svgIcon="gotaf_android_get"></mat-icon>
                    </a>
                </div>

            </div>
            <div class="header-right" fxFlex="1 1" fxFlexFill fxLayoutAlign="end">
                <img src="assets/image/gotaf-person/gotaf_nav_img.png">
            </div>
            <div class="header-right" fxFlex="1 1" fxFlexFill fxLayoutAlign="end">
                <img src="assets/image/gotaf-person/gotaf_nav_img.png">
            </div>
            <mat-icon (click)="openYoutubeDialog()" class="play-icon" svgIcon="play"></mat-icon>
        </div>


        <!-- Introduction section -->
        <div class="intro-section">
            <div class="page-container" fxLayout="row wrap" fxLayoutAlign="start">
                <div class="intro-content" fxFlex="250px" fxFlexOffset="14">
                    <h1 style="font-family: Montserrat, 'Montserrat' , sans-serif;">Restez à l'écoute<br />des offres d'emploi
                        <br />où que vous soyez</h1>
                        <a href="javascript:void(0);" class="mr-2 d-inline-block">
                            <img src="assets/image/icon_store_apple.svg" alt="Icon" class="" height="34px">
                        </a>
                        <a href="javascript:void(0);" class="d-inline-block">
                            <img src="../../../assets/image/icon_store_Google_Play.svg" alt="Icon" class="" height="34px">
                        </a>
                    </div>
                </div>
                <div class="intro-right-img">
                    <!-- <button (click)="openYoutubeDialog()" mat-fab color="primary" class="btn-play-icon btn-fab-lg"> </button>-->
                    <a mat-fab color="primary" class="btn-play-icon btn-fab-lg" href="../../../assets/video/header.mp4" target="_blank">
                        <mat-icon>play_arrow</mat-icon>
                    </a>
                    <img src="assets/image/gotaf-person/intro-img.png" alt="Image" class="desktop-img">
                </div>
            </div>
            <!-- End Introduction section -->


            <div class="container">
                <div class="content-center tab-section" ngClass.xs="content-center-xs">
                    <div class="second-bloc" *ngIf="jobNumbers !== 0">
                        <span class="jobsNumber">{{ jobNumbers }}</span>
                        <br fxShow="true" fxShow.xs="false">
                        <span class="jobsNumberText">emplois disponibles</span>
                    </div>
                    <p class="swiper-div">
                        <mat-icon class="swipe-icon"> touch_app</mat-icon>
                    </p>
                    <div class="category-row">
                        <div class="bloc-category">
                            <div fxFlex="134px" fxFlex.xs="110px" fxFlex.sm="110px" fxFlex.md="134px" class="bloc-mat-tile" *ngFor="let jobCategory of jobCategories">
                                <!-- <a routerLink="/job-category/{{jobCategory.id}}" style="text-decoration: none;"> -->
                                    <button class="mt-sm-0" mat-button routerLink='/job-category/{{jobCategory.id}}'>
                                        <p style="font-size: 15px;"> {{ jobCategory.name }} </p>
                                        <mat-icon [svgIcon]="jobCategory.image"></mat-icon>
                                    </button>
                                    <!-- </a> -->
                                    <!--      <mat-icon class="category-audio-icon" svgIcon="audio"></mat-icon>-->
                                    <img class="category-audio-icon" (click)="$event.stopPropagation();playAudio(jobCategory.name, jobCategory.audio, jobCategory)" src="assets/image/picto_audio_medium.png">
                                </div>
                            </div>
                        </div>
                        <form [formGroup]="searchForm" class="login-form" (submit)="searchWithoutCategory()">
                            <div class="search">
                                <div class="row">
                                    <div class="col-lg-4 mr-0 px-0 offset-lg-2 col-md-4 col-sm-12 form-conent" *ngFor="let data of array | slice :0:1">

                                        <mat-form-field appearance="none" class="search-form-field">
                                            <input type="search" formControlName="querry" (keydown.enter)="searchWithoutCategory()" class="search-word" [ngClass]="{'highigth-input': isRecord}" matInput placeholder="Métier, compétence, mot clé" #div>
                                        </mat-form-field>
                                        <span class="icon-before">
                                            <mat-icon svgIcon="icon_metier"></mat-icon>
                                        </span>
                                        <span class="icon-after">
                                            <mat-icon matSuffix svgIcon="icon_micro" (click)="voiceSearch(0)"></mat-icon>
                                        </span>
                                    </div>
                                    <div class="col-lg-3 col-md-4 px-0 col-sm-12   form-conent" *ngFor="let data of array | slice :1:array.length">
                                        <mat-form-field appearance="none" class="town-form-field">

                                            <input matInput class="search-town" type="search" placeholder="Saisissez une ville" (keydown.enter)="searchWithoutCategory()" aria-label="State" [matAutocomplete]="auto" [formControl]="city" #div />
                                            <mat-autocomplete class="search-form-field" #auto="matAutocomplete" [displayWith]="displayFn">
                                                <mat-option *ngIf="searchCityLoading" class="is-loading">Chargement...</mat-option>
                                                <ng-container *ngIf="!searchCityLoading">
                                                    <mat-option *ngFor="let city of cities" [value]="city.value">
                                                        <span><b>{{city.name}}</b></span>
                                                    </mat-option>
                                                </ng-container>
                                            </mat-autocomplete>
                                        </mat-form-field>


                                        <span class="icon-before">
                                            <mat-icon svgIcon="icon_localisation"></mat-icon>
                                        </span>
                                        <span class="icon-after">
                                            <mat-icon matSuffix svgIcon="icon_micro" (click)="voiceSearch(1)"></mat-icon>
                                        </span>
                                    </div>
                                    <div class="col-lg-2 col-md-2 px-0 col-sm-12 button-bloc">
                                        <!-- <div class="col-sm-1"> -->
                                            <a class="search-icon" (click)="searchWithoutCategory()" routerLinkActive="router-link-active">
                                                <mat-icon mat-button class="md-26" svgIcon="icon_search"></mat-icon>
                                            </a>
                                            <!-- </div> -->
                                            <!-- <div class="col-sm-1"> -->
                                                <a (click)="saveToSearch()" class="search-icon">
                                                    <mat-icon class="save-icon" class="md-26" svgIcon="icon_save"></mat-icon>
                                                </a>
                                                <!-- </div> -->
                                            </div>


                                        </div>
                                    </div>
                                </form>

                                <div fxShow="false" fxShow.xs="false" fxShow.sm="false" style="margin-top: 3em;">
                                    <span class="jobsNumberFound">Nous avons trouvé <br>8 annonces correspondantes à votre recherche</span>
                                </div>

                                <div fxShow="false" fxShow.xs="true" fxShow.sm="true">
                                    <span class="jobsNumberFound">Les dernières annonces publiées</span>
                                </div>

                                <app-filters fxShow="true" (jobResult)="getLastJobs()" (urgentJob)="showUrgentJob($event)" (currentLocation)="getCurrentLatLong('data')" class="filter-normal job-filter-respo">
                                </app-filters>

                                <app-loader  class="pre_loader" *ngIf="isPreLoader == true"></app-loader>
                                <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="100" (scrolled)="onScroll()">
                                    <ngx-masonry [options]="myOptions" [ordered]="true" style="margin: auto;" fxShow="false" fxShow.xs="false" fxShow="true" fxShow.md="true" [useImagesLoaded]="true">
                                        <div ngxMasonryItem class="grid-item" *ngFor="let job of paginatedJobs">
                                            <mat-card routerLink="/job/{{job.id}}" class="card-image-action" style="box-shadow: 0 5px 5px #3b3e4e45; cursor: pointer;" [style.width]="'217px'">
                                                <!-- <a routerLink="/job/{{job.id}}" style="text-decoration: none;"> -->
                                                    <span class="overlay1">
                                                        <img class="masonry-compagny-logo" src="{{ imageBaseUrl + job.logo }}">
                                                    </span>
                                                    <div class="card-image-box" [style.height]="job.cardHeight">
                                                        <span class="urgent-icon" *ngIf="job.urgent === 1">
                                                            <mat-icon svgIcon="icon_urgent"></mat-icon>
                                                        </span>
                                                        <div class="card-image-box-size">
                                                            <img mat-card-md-image class="job-image" [style.width]="'217px'" [style.height]="job.cardHeight" src="{{ imageBaseUrl + job.image }}" alt="">
                                                        </div>
                                                        <div class="card-hover-content" style="text-align: left;" fxLayout="column" fxLayoutAlign="space-between ">
                                                            <div>
                                                                <a style="text-decoration: none;" routerLink="/job/{{job.id}}">
                                                                    <h5>{{ job.companyName === '' ? 'N/A' : job.companyName | uppercase }}</h5>
                                                                </a>
                                                                <span class="card-hover-subtitle">{{ job.jobType }} - {{ job.address }}</span>
                                                                <h4>{{ isNull(job) === true ? 'N/A' : job.salary }}
                                                                    <span *ngIf="job.salary !== '0.00'">{{job.currency}}<!--/mois--></span>
                                                                </h4>
                                                                <p class="card-hover-desc" [innerHTML]="job.mission"></p>
                                                            </div>
                                                            <div>
                                                            </div>

                                                            <div class="card-hover-bottom" fxLayout="row" fxLayoutAlign="space-between end" style="    padding-bottom: 10px;">
                                                                <button fxLayout="row" *ngIf="job.jobApplied != 1" fxLayoutAlign="space-around center" mat-button class="btn-rounded btn-with-icon btn-primary-gradient" routerLink="job-application/{{job.id}}">
                                                                    <mat-icon svgIcon="icon_postuler" style="margin-right: 5px;"></mat-icon>
                                                                    <span>Postuler</span>
                                                                </button>
                                                                <button (click)="$event.stopPropagation();alreadyApplied(); " *ngIf="job.jobApplied == 1" fxLayout="row" fxLayoutAlign="space-around center" mat-button class="btn-rounded btn-with-icon btn-primary-gradient">
                                                                    <mat-icon svgIcon="icon_postuler" style="margin-right: 5px;"></mat-icon>
                                                                    <span>Déjà postulé</span>
                                                                </button>
                                                                <div class="share-like-btn">
                                                                    <button mat-icon-button (click)="$event.stopPropagation(); openShareDialog(job)" class="card-bottom-icon favorite-icon">
                                                                        <mat-icon> share</mat-icon>
                                                                    </button>
                                                                    <button mat-icon-button (click)="$event.stopPropagation(); abbJobToFavorite(job.id, job.jobFavorite)" class="card-bottom-icon favorite-icon">
                                                                        <mat-icon> {{job.jobFavorite == 1 ? 'favorite': 'favorite_border'}}</mat-icon>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- </a> -->
                                                    <mat-card-footer fxLayout="row wrap" fxLayoutAlign="space-between center">
                                                        <div class="card-footer-body">
                                                            <mat-card-title routerLink="/job/{{job.id}}">{{ job.jobName }}</mat-card-title>
                                                            <mat-card-subtitle>{{ job.jobType }} - {{ job.address }}</mat-card-subtitle>
                                                        </div>
                                                        <button mat-mini-fab color="secondary" class="btn-headphone-fab" (click)="$event.stopPropagation();playAudio(job.name, job.audio, job)">
                                                            <img class="job-audio-icons" src="assets/image/picto_audio_medium.png" width="40px">
                                                        </button>
                                                    </mat-card-footer>
                                                </mat-card>

                                            </div>
                                        </ngx-masonry>
                                    </div>
                                    <!-- <app-pagination class="mobile-pagination" [totalJobs]="jobs" [jobsPerPage]="jobPerPage"
                                        (onPageChange)="displayActivePage($event)" fxShow="true" fxShow.xs="false">
                                    </app-pagination>
                                    <div class="paginator" fxShow="true" fxShow.xs="false">
                                    </div> -->
                                    <!-- end job card -->

                                    <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="100" (scrolled)="onScroll()">
                                        <div class="content mobile-dsn" fxShow="false" fxShow.xs="true" fxLayout="row wrap" fxLayoutGap fxFlexFill fxHide.sm="true" fxLayoutAlign="center top">
                                            <div fxLayout="row wrap" fxLayoutGap fxFlexFill fxLayoutAlign="center top">
                                                <div class="jobs" fxFlex="25%" fxFlex.xs="50%" fxFlex.sm="48%" fxFlex.md="25%" *ngFor="let job of paginatedJobs; let index">
                                                    <span class="card-overlay-logos">
                                                        <img width="30px" style="width: 70% !important;" src="{{ imageBaseUrl + job.logo }}">
                                                        <!-- <img src="{{ imageBaseUrl + jobApply.logo }}" width="60px" class="" alt="icon"> -->
                                                    </span>
                                                    <!-- <img class="company-audio-icon" src="{{ imageBaseUrl + job.logo }}"> -->
                                                    <!-- <a routerLink="/job/{{job.id}}"> -->
                                                        <mat-card class="job-card">
                                                            <div class="card-image-box-size">
                                                                <img mat-card-image src="{{ imageBaseUrl + job.image }}" alt="" class="mat-card-image">
                                                            </div>
                                                            <span class="urgent-icon" *ngIf="job.urgent === 1">
                                                                <mat-icon svgIcon="icon_urgent"></mat-icon>
                                                            </span>
                                                            <mat-card-content class="job-card-content">
                                                                <div class="job-actions">
                                                                    <mat-grid-list class="title" cols="3">
                                                                        <mat-grid-tile class="title-tile" [colspan]="2">
                                                                            <div class="row" style="height: 60px">
                                                                                <a routerLink="job/{{job.id}}" class="col-12">
                                                                                    <p class="job-title">{{ job.jobName }}</p>
                                                                                </a>
                                                                                <p class="col-12 jobAdress" style="font-size: 8px;margin-left: 18px;">{{ job.jobType }} - {{ job.address }}
                                                                                </p>
                                                                            </div>
                                                                        </mat-grid-tile>
                                                                        <mat-grid-tile (click)="$event.stopPropagation()">
                                                                            <img class="job-audio-icon" src="assets/image/picto_audio_medium.png" (click)="$event.stopPropagation();playAudio(job.name, job.audio, job)">
                                                                        </mat-grid-tile>
                                                                    </mat-grid-list>
                                                                </div>
                                                                <div class="job-detail" routerLink="/job/{{job.id}}">
                                                                    <div class="title">
                                                                        <a routerLink="job/{{job.id}}">
                                                                            <p class="job-title">{{ job.companyName === '' ? 'N/A' : job.companyName | uppercase}}</p>
                                                                        </a>
                                                                        <p class="job-adress">{{ job.jobType }} - {{ job.address }}</p>
                                                                        <p style="display: flex;">{{ isNull(job) === true ? 'N/A' : job.salary }} <br>
                                                                            <span *ngIf="job.salary !== '0.00'">{{job.currency}}<!--/mois--></span>
                                                                        </p>
                                                                        <p class="job-mission" [innerHTML]="job.mission"></p>
                                                                    </div>
                                                                    <div fxLayout="row" fxLayoutAlign="space-between end" class="postuler-bloc">
                                                                        <button *ngIf="!job.jobApplied" fxLayout="row" fxLayoutAlign="space-around center" mat-button class="postuler" routerLink="job-application/{{job.id}}">
                                                                            <mat-icon svgIcon="icon_postuler"></mat-icon>
                                                                            <span class="postuler-title">Postuler</span>
                                                                        </button>
                                                                        <button (click)="$event.stopPropagation();alreadyApplied(); " *ngIf="job.jobApplied == 1" fxLayout="row" fxLayoutAlign="space-around center" mat-button class="btn-rounded btn-with-icon btn-primary-gradient">
                                                                            <mat-icon svgIcon="icon_postuler" style="margin-right: 5px;"></mat-icon>
                                                                            <span>Déjà postulé</span>
                                                                        </button>
                                                                        <div fxLayout="row" fxLayoutAlign="center center">
                                                                            <button mat-icon-button (click)="$event.stopPropagation(); openShareDialog(job, index)" class="card-bottom-icon">
                                                                                <mat-icon class="favorite-icon"> share</mat-icon>
                                                                            </button>
                                                                            <button mat-icon-button (click)="$event.stopPropagation(); abbJobToFavorite(job.id, job.jobFavorite)" class="card-bottom-icon">
                                                                                <mat-icon class="favorite-icon"> {{job.jobFavorite == 1 ? 'favorite': 'favorite_border'}}
                                                                                </mat-icon>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </mat-card-content>
                                                        </mat-card>
                                                        <!-- </a> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="appstore-section" fxShow="true" fxHide.gt-sm *ngIf="!isBrowser">
                                    <div class="page-container">
                                        <h3>Restez à l'écoute<br /> des offres d'emploi<br /> où que vous soyez</h3>
                                        <div class="" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
                                            <a href="javascript:void(0);" class="app-store-icon">
                                                <img src="assets/image/icon_store_apple.svg" alt="App Store" class="">
                                            </a>
                                            <a href="javascript:void(0);" class="app-store-icon">
                                                <img src="../../../assets/image/icon_store_Google_Play.svg" alt="App Store" class="">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!-- End app and google store -->



                                <mat-toolbar-row class="footer-toolbar-row" id="footerNav">
                                    <div class="row" fxLayout>
                                        <div class="left-navbar-bloc" style="text-align: left" fxFlex>
                                            <span> Nous croyons dans le potentiel de chacun<br>Retrouvez-nous sur les réseaux sociaux</span>
                                        </div>
                                    </div>
                                    <span class="navbar-spacer"></span>
                                    <div class="row" fxLayout>
                                        <div class="right-navbar-bloc" fxFlex fxLayoutAlign="flex-end center">
                                            <a target="_blank" href="https://www.facebook.com/gotafemploi" style="text-decoration: none;">
                                                <mat-icon svgIcon="icon_facebook"></mat-icon>
                                            </a>
                                            <a target="_blank" href="https://www.instagram.com/gotafemplois/" style="text-decoration: none;">
                                                <mat-icon svgIcon="icon_instagram"></mat-icon>
                                            </a>
                                            <a target="_blank" href="https://youtu.be/I5WrwtfknxE" style="text-decoration: none;">
                                                <mat-icon svgIcon="icon_youtube"></mat-icon>
                                            </a>
                                            <a target="_blank" href="https://www.tiktok.com/@gotaf21?lang=fr" style="text-decoration: none;">
                                                <mat-icon svgIcon="icon_tiktok"></mat-icon>
                                            </a>
                                            <a target="_blank" href="https://www.twitch.tv/gotaf21" style="text-decoration: none;">
                                                <mat-icon svgIcon="icon_twitch"></mat-icon>
                                            </a>
                                            <a target="_blank" href="https://www.linkedin.com/company/gotaf" style="text-decoration: none;">
                                                <mat-icon svgIcon="icon_linkedin"></mat-icon>
                                            </a>
                                        </div>
                                    </div>
                                </mat-toolbar-row>

                            </ng-container>

                            <ng-container *ngIf="!jobsService.isVoirCLick">
                                <div style="height: 50px; background: linear-gradient(232deg,#bbedc2,#99e6a9 15%,#73c59c 75%,#458076);"></div>
                                <!-- Job Category with Map -->
                                <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" class="bg-white map-part">
                                    <!-- Left Side -->
                                    <div class="left-content" fxHide.lt-md fxFlex="40" fxFlex.lt-lg="50" fxFlex.lt-md="100">
                                        <div class="mb-4 border-bottom">
                                            <h5 class="mb-4 text-center">{{ jobNumbers }} emplois disponibles</h5>



                                            <div class="category-row map-category-row">
                                                <div class="bloc-category">
                                                    <div fxFlex="134px" fxFlex.xs="110px" fxFlex.sm="110px" fxFlex.md="134px" class="bloc-mat-tile" *ngFor="let jobCategory of jobCategories">
                                                        <button class="" mat-button routerLink='/job-category/{{jobCategory.id}}'>
                                                            <p> {{ jobCategory.name }} </p>
                                                            <mat-icon [svgIcon]="jobCategory.image"></mat-icon>
                                                        </button>
                                                        <img class="category-audio-icon" src="assets/image/picto_audio_medium.png" (click)="playAudio(jobCategory.name, jobCategory.audio, jobCategory)">
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Search Form -->
                                            <form class="search-form mb-4" [formGroup]="searchForm" (submit)="searchWithoutCategory()">
                                                <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="16px">
                                                    <div class="" fxFlex="30" fxFlex.lt-md="100" *ngFor="let data of array | slice :0:1">
                                                        <mat-form-field appearance="none" class="search-form-field">
                                                            <input class="form-control" formControlName="querry" (keydown.enter)="searchWithoutCategory()" matInput placeholder="Métier, compétence, mot clé" #div>
                                                            <span class="icon-before">
                                                                <mat-icon svgIcon="icon_metier"></mat-icon>
                                                            </span>
                                                            <span class="icon-after">
                                                                <mat-icon matSuffix svgIcon="icon_micro" (click)="voiceSearch(0)"></mat-icon>
                                                            </span>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="" fxFlex="30" fxFlex.lt-md="100" *ngFor="let data of array | slice :1:array.length">
                                                        <mat-form-field appearance="none" class="search-form-field">
                                                            <input (keydown.enter)="searchWithoutCategory()" formControlName="city" class="form-control" matInput placeholder="Saisissez une ville" #div>
                                                            <span class="icon-before">
                                                                <mat-icon svgIcon="icon_localisation"></mat-icon>
                                                            </span>
                                                            <span class="icon-after">
                                                                <mat-icon matSuffix svgIcon="icon_micro" (click)="voiceSearch(1)"></mat-icon>
                                                            </span>
                                                        </mat-form-field>
                                                    </div>
                                                    <button (click)="searchWithoutCategory()" mat-fab color="secondary" class="search-icon btn-search">
                                                        <mat-icon>search</mat-icon>
                                                    </button>
                                                    <button class="search-icon2 btn-search2" click="saveToSearch()">
                                                        <mat-icon class="save-icon" class="md-26" svgIcon="icon_save"></mat-icon>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>


                                        <!-- App filter -->
                                        <app-filters class="filter-btn-oneline" fxShow="true" fxShow.xs="false" fxShow.sm="false" (jobResult)="getLastJobs()" (urgentJob)="showUrgentJob($event)" (currentLocation)="getCurrentLatLong('data')">
                                        </app-filters>


                                        <!-- list -->
                                        <div infiniteScroll [horizontal]="true" [infiniteScrollDistance]="1" [infiniteScrollThrottle]="100" (scrolled)="onScroll()">
                                            <div class="list" *ngFor="let job of paginatedJobs; let i = index">
                                                <!-- <a routerLink="/job/{{job.id}}"> -->
                                                    <mat-card [ngClass]="{'hover-style': job.id === currentMapJobSelectedId}" class="card-list-item p-0" fxLayout="row" fxLayoutAlign="start stretch" (click)="updateMapView(job); focusOneJobs(job.id)" style="cursor: pointer;">
                                                        <div class="card-list-image">
                                                            <img routerLink="/job/{{job.id}}" src="{{ imageBaseUrl + job.image }}" width="220px" height="100%" style="border-radius: 20px 0 0 20px; object-fit: cover;" class="" alt="icon">
                                                            <span class="card-overlay-logo">
                                                                <img src="{{ imageBaseUrl + job.logo }}" width="50px" class="" alt="icon">
                                                            </span>
                                                        </div>
                                                        <mat-card-content class="card-list-body" fxLayout="row wrap" fxLayoutAlign="space-between center">
                                                            <div class="w-100" fxLayout="row wrap" fxLayoutAlign="space-between center">
                                                                <div class="media w-100">
                                                                    <div class="media-body ml-0">
                                                                        <mat-card-title>{{ job.jobName }}</mat-card-title>
                                                                        <mat-card-subtitle>{{ job.jobType }} - {{ job.address }}
                                                                        </mat-card-subtitle>
                                                                    </div>
                                                                    <div class="media-right">
                                                                        <p class="item-price">{{ isNull(job) === true ? 'N/A' : job.salary }}<br>{{job.currency}}<!--/mois--></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button mat-mini-fab color="secondary" class="btn-headphone-fab" (click)="$event.stopPropagation();playAudio(job.name, job.audio, job)">
                                                                <mat-icon svgIcon="headphone_audio"></mat-icon>
                                                            </button>
                                                        </mat-card-content>
                                                    </mat-card>
                                                    <!-- </a> -->
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End Left Side -->

                                        <!-- Right Side -->
                                        <div fxFlex="60" fxFlex.lt-lg="50" fxFlex.lt-md="100" class="google-map-frame">
                                            <button (click)="backClick()" mat-button class="btn-close">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                            <div class="map-transport-icon" *ngIf="isAGMDirection">
                                                <div [ngClass]="{'active' : mapTransportMode=='DRIVING'}" class="map-transport map-car">
                                                    <div class="map-car-img" (click)="getVehicle('DRIVING')">
                                                        <img mat-card-md-image src="assets/image/car.png" alt="image">
                                                    </div>
                                                    <label *ngIf="mapTransportMode=='DRIVING'">{{ mapDistanceDuration }}</label>
                                                </div>
                                                <div [ngClass]="{'active' : mapTransportMode=='TRANSIT'}" class="map-transport map-train">
                                                    <div class="map-car-img" (click)="getVehicle('TRANSIT')">
                                                        <img mat-card-md-image src="assets/image/train.png" alt="image">
                                                    </div>
                                                    <label *ngIf="mapTransportMode=='TRANSIT'">{{ mapDistanceDuration }}</label>
                                                </div>
                                                <div [ngClass]="{'active' : mapTransportMode=='WALKING'}" class="map-transport map-walking">
                                                    <div class="map-car-img" (click)="getVehicle('WALKING')">
                                                        <img mat-card-md-image src="assets/image/walking.png" alt="image">
                                                    </div>
                                                    <label *ngIf="mapTransportMode=='WALKING'">{{ mapDistanceDuration }}</label>
                                                </div>
                                                <div [ngClass]="{'active' : mapTransportMode=='BICYCLING'}" class="map-transport map-bicycle">
                                                    <div class="map-car-img" (click)="getVehicle('BICYCLING')">
                                                        <img mat-card-md-image src="assets/image/bicycle.png" alt="image">
                                                    </div>
                                                    <label *ngIf="mapTransportMode=='BICYCLING'">{{ mapDistanceDuration }}</label>
                                                </div>
                                            </div>
                                            <agm-map [(latitude)]="location.lat" [(longitude)]="location.lng" [(zoom)]="zoom" [disableDefaultUI]="true" [(fitBounds)]='location.viewport'>
                                                <agm-direction *ngIf="isAGMDirection" [travelMode]="mapTransportMode" [waypoints]="waypoints" [origin]="origin" [destination]="destination" [renderOptions]="renderOptions">
                                                </agm-direction>
                                                <agm-marker (markerClick)="focusOneJobs(job.id); clickedMarker(infoWindowsw, job.id)" [iconUrl]="'assets/image/icon_metier_map.svg'" *ngFor="let job of paginatedJobs" [latitude]="job.options.lat|number:'1.0-8'" [longitude]="job.options.lng|number:'1.0-8'">
                                                    <agm-info-window [disableAutoPan]="false" #infoWindowsw>
                                                        <mat-card class="card-image-action card-image-action-info-window" style="box-shadow: 0 5px 5px #3b3e4e45; cursor: pointer;" [style.width]="'217px'">
                                                            <button class="gm-ui-hover-effects" (click)="removeItineraire()">X</button>
                                                            <span class="overlay1">
                                                                <img class="masonry-compagny-logo" src="{{ imageBaseUrl + job.logo }}">
                                                            </span>
                                                            <div class="card-image-box">
                                                                <span class="urgent-icon" *ngIf="job.urgent === 1">
                                                                    <mat-icon svgIcon="icon_urgent"></mat-icon>
                                                                </span>
                                                                <div class="card-image-box-size">
                                                                    <img mat-card-md-image class="job-image" [style.width]="'217px'" [style.height]="job.cardHeight" src="{{ imageBaseUrl + job.image }}" alt="">
                                                                </div>
                                                                <div class="card-hover-content" style="text-align: left;" fxLayout="column" fxLayoutAlign="space-between ">
                                                                    <div>
                                                                        <a style="text-decoration: none;" routerLink="/job/{{job.id}}">
                                                                            <h5>{{ job.companyName === '' ? 'N/A' : job.companyName | uppercase }}</h5>
                                                                        </a>
                                                                        <span class="card-hover-subtitle">{{ job.jobType }} - {{ job.address }}</span>
                                                                        <h4>{{ isNull(job) === true ? 'N/A' : job.salary }}
                                                                            <span *ngIf=" job.salary !== '0.00'">{{job.currency}}<!--/mois--></span>
                                                                        </h4>
                                                                        <p class="card-hover-desc" [innerHTML]="job.mission"></p>
                                                                    </div>
                                                                    <div class="card-hover-bottom" fxLayout="row" fxLayoutAlign="space-between end" style="padding-bottom: 10px;">
                                                                        <button *ngIf="!job.jobApplied" fxLayout="row" fxLayoutAlign="space-around center" mat-button class="btn-rounded btn-with-icon btn-primary-gradient" routerLink="job-application/{{job.id}}" [disabled]="job.jobApplied == 1">
                                                                            <mat-icon svgIcon="icon_postuler" style="margin-right: 5px;"></mat-icon>
                                                                            <span>Postuler</span>
                                                                        </button>
                                                                        <button (click)="$event.stopPropagation();alreadyApplied(); " *ngIf="job.jobApplied == 1" fxLayout="row" fxLayoutAlign="space-around center" mat-button class="btn-rounded btn-with-icon btn-primary-gradient">
                                                                            <mat-icon svgIcon="icon_postuler" style="margin-right: 5px;"></mat-icon>
                                                                            <span>Déjà postulé</span>
                                                                        </button>
                                                                        <div class="share-like-btn">
                                                                            <button mat-icon-button (click)="$event.stopPropagation(); openShareDialog(job)" class="card-bottom-icon favorite-icon">
                                                                                <mat-icon> share</mat-icon>
                                                                            </button>
                                                                            <button mat-icon-button (click)="$event.stopPropagation(); abbJobToFavorite(job.id, jobFavorite)" class="card-bottom-icon favorite-icon">
                                                                                <mat-icon> {{job.jobFavorite == 1 ? 'favorite': 'favorite_border'}}</mat-icon>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="info-window-footer-button">
                                                                        <div class="card-footer-body info-window-footer-body">
                                                                            <button fxLayout="row" fxLayoutAlign="space-around center" (click)="getDirection(job)" mat-button class="btn-rounded btn-with-icon map-draw-path">
                                                                                <mat-icon svgIcon="icon_itinerary"></mat-icon><span>itinéraire</span>
                                                                            </button>
                                                                            <button routerLink="/job/{{job.id}}" class="btn-rounded btn-with-icon btn-primary-gradient map-job-detail">
                                                                                Voir l'offre <mat-icon svgIcon="icon_goTo"></mat-icon>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </mat-card>
                                                    </agm-info-window>
                                                </agm-marker>
                                                <agm-marker [iconUrl]="'assets/image/icon_localisation_map.svg'" [(latitude)]="location.marker.lat" [(longitude)]="location.marker.lng">
                                                </agm-marker>
                                            </agm-map>
                                            <div class="respo-map-search">
                                                <form [formGroup]="searchForm" class="login-form" (submit)="searchWithoutCategory()">
                                                    <div class="respo-map-search-fields" *ngFor="let data of array | slice :0:1">
                                                        <mat-form-field appearance="none" class="search-form-field">
                                                            <input type="search" class="form-control" formControlName="querry" (keydown.enter)="searchWithoutCategory()" matInput placeholder="Métier, compétence, mot clé" #div>
                                                        </mat-form-field>
                                                        <span class="icon-before">
                                                            <mat-icon svgIcon="icon_metier"></mat-icon>
                                                        </span>
                                                        <span class="icon-after">
                                                            <mat-icon matSuffix svgIcon="icon_micro" (click)="voiceSearch(0)"></mat-icon>
                                                        </span>
                                                    </div>
                                                </form>
                                            </div>

                                            <app-filters fxShow="true" fxShow.xs="true" fxShow.sm="true" class="filter-btn-oneline-mobile" (jobResult)="getLastJobs()" (currentLocation)="getCurrentLatLong('data')">
                                            </app-filters>

                                            <!-- list -->
                                            <div class="map-mobile-list-main" [ngClass]="{'is-conncet-fixed-joblist': userConnected}">
                                                <div class="list map-mobile-list" fxHide.gt-md *ngFor="let job of paginatedJobs; let i = index">
                                                    <mat-card class="card-list-item p-0" fxLayout="row" fxLayoutAlign="start stretch">
                                                        <div class="card-list-image">
                                                            <img routerLink="/job/{{job.id}}" src="{{ imageBaseUrl + job.image }}" width="220px" height="100%" style="border-radius: 20px 0 0 20px; object-fit: cover;" class="" alt="icon">
                                                            <span class="card-overlay-logo">
                                                                <img src="{{ imageBaseUrl + job.logo }}" width="50px" class="" alt="icon">
                                                            </span>
                                                        </div>
                                                        <mat-card-content class="card-list-body" fxLayout="row wrap" fxLayoutAlign="space-between center">
                                                            <div class="w-100" fxLayout="row wrap" fxLayoutAlign="space-between center">
                                                                <div class="media w-100">
                                                                    <div class="media-body ml-0">
                                                                        <mat-card-title routerLink="/job/{{job.id}}">{{ job.jobName }}</mat-card-title>
                                                                        <mat-card-subtitle (touchmove)="updateMapView(job); focusOneJobs(job.id);" (click)="updateMapView(job); focusOneJobs(job.id);">
                                                                            {{ job.jobType }} - {{ job.address }}
                                                                        </mat-card-subtitle>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button mat-mini-fab color="secondary" class="btn-headphone-fab" (click)="$event.stopPropagation();playAudio(job.name, job.audio, job)">
                                                                <mat-icon svgIcon="headphone_audio"></mat-icon>
                                                            </button>
                                                        </mat-card-content>
                                                    </mat-card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
