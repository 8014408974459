import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { User } from '../../../model/user';
import { JobCategory } from '../../../model/jobCategory';
import { UserService } from '../../../service/user/user.service';
import { AuthenticationService } from '../../../service/authentication/authentication.service';
import { Router } from '@angular/router';
import { SnackService } from '../../../service/snack/snack.service';
import { JobCategoriesService } from '../../../service/jobCategories/job-categories.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { environment } from '../../../../environments/environment.prod';
import { JobsService } from '../../../service/jobs/jobs.service';
import { AudioService } from '../../../service/audio.service';
import { CompanyService } from '../../../service/company/company.service';
import { GoogleMap } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { JobQuestionsComponent } from '../job-questions/job-questions.component';
import { JobImageSuggestionComponent } from '../job-image-suggestion/job-image-suggestion.component';
import { SharedServiceService } from '../../../shared/shared-service.service';
import { Platform } from '@angular/cdk/platform';
import { JobTemplateAudioComponent } from '../job-template-audio-component/job-template-audio.component';
import { ReccordAudioService } from '../../../service/reccord-audio/reccord-audio.service';

@Component({
  selector: 'app-job-add',
  templateUrl: './job-add.component.html',
  styleUrls: ['./job-add.component.css', '../../../../app/shared/css/single-job.style.css']
})
export class JobAddComponent implements OnInit {
  donnee = 'title on job';
  url = '';
  isOrderedJob = false;
  suggestAudio = '';
  defaultImage;
  public company: any;
  public imageBaseUrl: string = environment.imageBaseUrl;
  public jobForm: FormGroup;
  public questionList: any[] = ['Informations personelles', 'Informations générales', 'Qualifications', 'Expériences'];
  audioFile: File;
  jobImage: File;
  public user: User;
  private selectedFile: File;
  choix: any[] = [];
  categorieChoix: any[] = [];
  public jobCategories: JobCategory[] = [];
  public experienceLevels: any[] = ['oui', 'non'];
  private categorieSelected: any[] = [];
  userStored = JSON.parse(localStorage.getItem('user'));
  public showPlayer = false;
  public selectType;
  public selectCategory;
  private isSuggestion: boolean = false;
  private suggestionValue: string;
  @ViewChild('GoogleMap', { static: false }) map: GoogleMap;
  zoom = 12;
  center = {};
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };
  markerOptions = { icon: '../../../../assets/image/icon_metier_map.svg' };


  public types = [];
  constructor(private userService: UserService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private snack: SnackService,
    private jobCategoriesService: JobCategoriesService,
    private jobService: JobsService,
    private audioService: AudioService,
    private companyService: CompanyService,
    public dialog: MatDialog,
    private router: Router,
    private sharedService: SharedServiceService,
    private plt: Platform,
    public reccordAudioService:ReccordAudioService
  ) {
    this.authenticationService.user.subscribe(x => this.user = x);
  }

  ngOnInit(): void {
    this.jobForm = this.formBuilder.group({
      name: ['job title', [Validators.required]],
      urgent: [0, [Validators.required]],
      typeJob: ['select Type de Job'],
      mission: ['Mission'],
      profile: ['Profile'],
      salary: ['Salaire'],
      JobCategoryId: ['select Job Category'],
      dueDate: [''],
      address: ['Define address'],
    });
    this.getCategories();
    this.getJobsTypes();
    this.getCompany();
    if (this.plt.ANDROID || this.plt.IOS) {
      this.sharedService.navigation.next(true);
      this.sharedService.navigationToDetails.next(false);
    } else {
      this.sharedService.navigation.next(false);
      this.sharedService.navigationToDetails.next(true);
    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(JobQuestionsComponent, {
      data: ''
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      this.questionList = result;
    });
  }

  openTemplateAudioDialog(): void {
    const dialogRef = this.dialog.open(JobTemplateAudioComponent, {
      data: {
        job: {}
      },
      width: '600px'
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      console.log({result});
      if (result !== undefined) {
       this.suggestAudio = result;
       this.isOrderedJob = false;
      }
    });
  }

  openSuggestionDialog(): void {
    const dialogRef = this.dialog.open(JobImageSuggestionComponent, {
      data: '',
      width: '600px'
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      console.log(result);
      if (result !== undefined) {
        this.url = result;
        this.isSuggestion = true;
        const splitResult = result.split('/');
        this.suggestionValue = splitResult[splitResult.length - 1];
      }
    });
  }


  public getCompany(): void {
    // this.companyService.getCompanyByUser(this.authenticationService.userValue.id).subscribe((data) => {      
      this.companyService.getCompanyByUser(this.user.id).subscribe((data) => {
      this.company = data;
    }, ignore => {
      // this.snack.error('error when getting Company infos');
    });
  }

  private getJobsTypes(): void {
    this.jobService.getJobTypes().subscribe((data) => {
      this.types = data;
    });
  }

  public submit(): void {  
    const sendData = new FormData();
    sendData.append('name', this.jobForm.controls.name.value);
    sendData.append('urgent', this.jobForm.controls.urgent.value);
    sendData.append('salary', this.jobForm.controls.salary.value);
    // sendData.append('jobTypeId', this.jobForm.controls.typeJob.value);
    sendData.append('mission', this.jobForm.controls.mission.value);
    sendData.append('profile', this.jobForm.controls.profile.value);
    sendData.append('companyId', this.company.id);
    sendData.append('audio', this.audioFile);
    sendData.append('orderedAudio', this.isOrderedJob ? '1' : '0');
    sendData.append('suggestAudio', this.suggestAudio);
    sendData.append(this.isSuggestion ? 'selectedImage' : 'image', this.isSuggestion ? this.suggestionValue : this.jobImage);
    // sendData.append('jobCategoryId', this.jobForm.controls.JobCategoryId.value);
    if (this.selectType) {
      sendData.append('jobTypeId', this.selectType);
    }
    if (this.selectCategory) {
      sendData.append('jobCategoryId', this.selectCategory);
    }
    sendData.append('address', this.jobForm.controls.address.value);  
    this.jobService.addJob(sendData).subscribe((data:any) => {
      // this.reccordAudioService.stopCapturing('job',data.job.id);
      this.snack.info('Offres bien ajouté !');
      this.router.navigateByUrl('/');
      // this.back();
    }, (err) => {
      // console.log(err);
    });
  }

  // public onFileSelect(event: any): void {
  //   this.selectedFile = event.target.files[0];
  //   this.audioFile = this.selectedFile;
  //   // console.log(event);
  // }

  public getCategories(): void {
    this.jobCategoriesService.getJobCategories().subscribe(data => {
      this.jobCategories = data;
      // console.log(this.jobCategories);
    }, ignore => {
      this.jobCategories = [];
      // this.snack.error('error when loading jobCategories');
    });
  }

  public onCheckboxChange(e: MatCheckboxChange): void {
    const checkArray: FormArray = this.jobForm.get('categorie') as FormArray;
    if (e.checked) {
      if (checkArray.controls.length < 2) {
        checkArray.push(new FormControl(e.source.value));
      } else {
        e.source.checked = false;
      }
    } else {
      let i = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value === e.source.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }

    this.categorieSelected = checkArray.value;
  }

  public onSelectAudioFile(event): void {
    if (event.target.files && event.target.files[0]) {
      this.audioFile = event.target.files[0];
    }
  }

  public onSelectFile(event): void {
    if (event.target.files && event.target.files[0]) {
      this.url = event.target.files[0];
      this.jobImage = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.url = e.target.result;
      };
    }
  }
  public delete(): void {
    this.url = null;
  }

  private getCompanyDetails(): void {

  }

  public assignValue(e, libele): void {
    switch (libele) {
      case 'JobCategoryId':
        this.selectCategory = Number(e);
        this.jobForm.get(libele).setValue(this.jobCategories[e - 1].name);
        break;
      case 'typeJob':
        this.selectType = Number(e);
        this.jobForm.get(libele).setValue(this.types[e - 1].name);
        break;
      case 'address':
        this.jobForm.get(libele).setValue(e);
        this.getLatLong(this.jobForm.controls.address.value);
        break;
      default:
        this.jobForm.get(libele).setValue(e);
        console.log(this.jobForm.value);
    }
  }

  public buildAudioFile(value: File): void {
    console.log(value);
    this.audioFile = value;
  }

  public playRecord(file: any): void {
    this.playAudio(file.changingThisBreaksApplicationSecurity);
  }

  public playAudio(url): void {
    this.showPlayer = true;
    this.audioService.playStream(url).subscribe(events => {
    });
  }

  public stop(show: boolean): void {
    this.audioService.stop();
    this.showPlayer = false;
  }

  public closePlayer(): void {
    this.audioService.stop();
    this.showPlayer = false;
  }

  public getLatLong(address: string): void {
    if (navigator.geolocation) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {

        if (status === google.maps.GeocoderStatus.OK) {
          const result = results[0];
          const lat = result.geometry.location.lat();
          const lng = result.geometry.location.lng();
          // alert(lat);
          // alert(lng);
          this.center = {
            lat,
            lng
          };

        }
      });
    }
  }
}
