<div *ngIf="(isCompany() || !isCompany()) && !isCompanyJob">
  <div fxLayout="row nowrap" fxFlexFill fxLayoutAlign="space-between stretch" class="homepage-header" fxShow.xs="false">
    <div class="navbar-title" fxFlex="1 1" fxFlexFill fxShow.xs="false">
      <h1> {{ job?.companyName === '' ? 'N/A' : job?.companyName | uppercase}} recherche {{job?.jobName | uppercase}}
      </h1>
    </div>
  </div>
  <div fxShow="false" fxShow.xs="true" style="height: 50px;">
  </div>
  <div class="route-history">
    <ul class="list-inline breadcrumbs mb-4">
      <li class="list-inline-item">
        <a [routerLink]="[ '/' ]" routerLinkActive="active" class="beadcrums-child">Accueil</a>
      </li>
      <li class="list-inline-item">
        <a [routerLink]="[ '/job-category', job?.jobCategoryId ]" class="beadcrums-child"
          routerLinkActive="active">{{job?.jobCategoryName}}</a>
      </li>
      <li class="list-inline-item">
        <span class="beadcrums-child">{{job?.companyName === '' ? 'N/A' : job?.companyName | uppercase}}</span>
      </li>
      <li class="list-inline-item">
        <span class="beadcrums-child">{{job?.jobName}}</span>
      </li>
    </ul>
  </div>
  <div class="jd-bg" style="margin-bottom: 5rem;">
    <div class="bounds">
      <div class="content" fxLayout="row wrap" fxLayout.xs="column" fxFlexFill fxLayoutAlign="space-between">
        <!-- <div class="jd-acco" fxFlex="4" fxShow="false" fxShow.xs="true">
          <div style="margin-top: 15px; font-size: 12px; color: #03334F;">
            <span>Offres d'emploi ></span>
            <span>{{job?.jobCategoryName}} > </span>
            <span> {{job?.companyName}} </span>
          </div>
        </div> -->
        <div class="jd-ttl" fxFlex="6" fxShow="false" fxShow.xs="true" style="font-size: 23px; color: #03334F;">
          <span>{{ job?.companyName === '' ? 'N/A' : job?.companyName | uppercase}} recherche </span>
          <span style="font-weight: bold;"> {{job?.jobName}}</span>
        </div>
        <!-- premiere column -->
        <div fxFlex="15" fxFlex.md="15" fxFlex.sm="35" class="sec1 jd-img" fxFlex.xs="30"
          style="background-image: url({{ imageBaseUrl + job?.image }})">
          <div class="jd-on-img" fxShow="false" fxShow.xs="true">
            <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="100">
              <button class="button" fxFlex="40">
                <strong style="font-weight: bold; display: inline-flex;">
                  <mat-icon class="btp-icon" [svgIcon]="job?.jobCategoryImage"></mat-icon>
                  <span style="font-size: 11; color: #42475E;">{{ job?.jobCategoryName }}</span>
                </strong>
              </button>

              <div fxLayout="row" fxFlex="30" fxLayoutAlign="end">
                <button mat-icon-button (click)="$event.stopPropagation(); openShareDialog(job)"
                  class="card-bottom-icon favorite-icon">
                  <mat-icon> share</mat-icon>
                </button>
                <button mat-icon-button (click)="$event.stopPropagation(); abbJobToFavorite(job.id)"
                  class="card-bottom-icon favorite-icon">
                  <mat-icon> {{job?.jobFavorite == 1 ? 'favorite': 'favorite_border'}}</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="jd-job-ttl" fxLayout="row" fxLayoutAlign="space-between" fxFlex="10" fxShow="false"
          fxShow.xs="true">
          <div fxFlex="20" fxLayoutAlign="center">
            <img class="job-audio-icon companyIcon" src="{{ imageBaseUrl + job?.logo }}"
              (click)="getCompanyJobsOnJobs()">
          </div>
          <div fxFlex="60" fxLayoutAlign="start" fxLayout="column">
            <div fxFlex="40" fxLayoutAlign="start">
              <div class="text-truncate" style="font-weight: bold; font-size: 20px; color: #42475E; height: 22px">
                {{ job?.jobName }}
              </div>
            </div>
            <div fxFlex="40">
              <div style="font-size: 11px; color: #42475E; text-transform: capitalize;" class="text-truncate">
                {{ job?.jobType }} - {{ job?.address }}
              </div>
            </div>
          </div>
          <div fxFlex="20" fxLayoutAlign="center">
            <img class="job-audio-icon" src="assets/image/picto_audio_medium.png"
              (click)="playAudio(job?.jobName, job?.audio, job);">
          </div>
        </div>
        <!-- second column -->
        <div fxFlex="26" fxFlex.md="26" class="sec2" fxFlex.sm="60" fxShow="true" fxShow.xs="false" fxShow.sm="true"
          fxShow.lg="true" fxShow.md="true">
          <!-- column to contain third part into second column  -->
          <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="100">
            <!-- first row imagee/facebook/linkedin with space between -->
            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="22">
              <div fxFlex="30" fxLayoutAlign="start">
                <img class="companyIcon" style="cursor: pointer; height: 44px; width: 44px;"
                  src="{{ imageBaseUrl + job?.logo }}" width="130px" (click)="getCompanyJobsOnJobs()">
              </div>
              <div fxFlex="30" fxLayoutAlign="end">
                <button mat-icon-button *ngIf="job && job.facebook">
                  <a href="http://www.google.com" target="_blank" rel="noopener noreferrer">
                    <mat-icon svgIcon="facebook"></mat-icon>
                  </a>
                </button>
                <button mat-icon-button *ngIf="job && job.linkedIn">

                  <a href="{{job.linkedIn}}" target="_blank" rel="noopener noreferrer">
                    <mat-icon svgIcon="icon_linkedin"></mat-icon>
                  </a>
                </button>
              </div>
            </div>
            <!-- second row but he must contain two different bloc -->
            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="25">
              <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="100">
                <div fxFlex="65" fxLayoutAlign="start">
                  <div class="text-truncate" style="font-weight: bold; font-size: 20px; color: #42475E">
                    {{ job?.jobName }}
                    <!-- <span style="font-weight: bold; font-size: 12px !important;">
                      {{job.jobType}}
                    </span> -->
                  </div>
                </div>
                <div fxFlex="30" fxLayoutAlign="end">
                  <div style="font-weight: bold; font-size: 16px; color: #42475E; word-wrap: break-word;">
                    {{ job?.salary === '0.00' ? 'N/A' : job?.salary }} {{job?.currency}}</div>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="100">
                <div fxFlex="65" fxLayoutAlign="start">
                  <div style="font-size: 11px; color: #42475E; text-transform: capitalize;" class="text-truncate">
                    {{ job?.jobType }} - {{ job?.address }}
                  </div>
                </div>
                <div fxFlex="25" fxLayoutAlign="end">
                  <span style="font-size: 16px; color: #42475E;">net<!--/mois--></span>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="100">
                <button class="button" fxFlex="65">
                  <strong style="font-weight: bold; display: inline-flex">
                    <mat-icon [svgIcon]="job?.jobCategoryImage"></mat-icon>
                    <span style="font-size: 11; color: #42475E;">{{ job?.jobCategoryName }}</span>
                  </strong>
                </button>

                <div fxLayout="row" fxFlex="30" fxLayoutAlign="end">
                  <button mat-icon-button (click)="$event.stopPropagation(); openShareDialog(job)"
                    class="card-bottom-icon favorite-icon">
                    <mat-icon class="icon-link"> share</mat-icon>
                  </button>
                  <button mat-icon-button (click)="$event.stopPropagation(); abbJobToFavorite(job.id)"
                    class="card-bottom-icon favorite-icon">
                    <mat-icon class="icon-link"> {{job?.jobFavorite == 1 ? 'favorite': 'favorite_border'}}</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="45">
              <!--<audio controls style="width: inherit;" fxFlex="45" src="{{ imageBaseUrl + job?.audio }} ">
              </audio>-->
              <!--job-single-player-->
              <div class="job-single-player" fxLayout="row" fxLayoutAlign="stretch center" fxFlex="45">
                <div fxFlex="15">
                  <img src="assets/image/picto_audio_seul.svg" />
                </div>
                <div fxFlex="10">
                  <button mat-icon-button fxFlex="100" *ngIf="state?.playing" (click)="pause()">
                    <mat-icon class="icoWhyte">pause</mat-icon>
                  </button>
                  <button mat-icon-button fxFlex="100" (click)="play()" [disabled]="state?.error"
                    *ngIf="!state?.playing">
                    <mat-icon class="icoWhyte">play_circle_filled </mat-icon>
                  </button>
                </div>
                <div fxFlex="15">
                  <span class="time">{{ state?.readableCurrentTime }}</span>
                </div>
                <div fxFlex="50">
                  <mat-slider class="time-slider w-100 text-white" min="0" [max]="state?.duration" step="1"
                    [value]="state?.currentTime" (input)="onSliderChangeEnd($event)" [disabled]="state?.error">
                  </mat-slider>
                </div>
                <div fxFlex="15">
                  <span class="time">{{ state?.readableDuration }}</span>
                </div>
                <div fxFlex="10" *ngIf="!show">
                  <button mat-icon-button fxFlex="100" (click)="show=true">
                    <mat-icon class="icoWhyte" *ngIf="val">volume_up</mat-icon>
                    <mat-icon class="text-white" *ngIf="!val" mat-list-icon> volume_off</mat-icon>
                  </button>
                </div>
                <div fxFlex="10" *ngIf="show" fxLayout="column">
                  <mat-slider vertical class="volume-slider w-50" min="0" fxFlex="100" [max]="1" step=".1"
                    [value]="state?.volume" (input)="onVolumeChangeEnd($event)"></mat-slider>
                  <button mat-icon-button fxFlex="100" (click)="mute()">
                    <mat-icon class="icoWhyte" *ngIf="val">volume_up</mat-icon>
                    <mat-icon class="text-white" *ngIf="!val" mat-list-icon> volume_off</mat-icon>
                  </button>
                </div>
                <div fxFlex="10">
                  <button mat-icon-button fxFlex="100">
                    <mat-icon class="icoWhyte"> more_vert</mat-icon>
                  </button>
                </div>
              </div>
              <!--job-single-player-->

              <button routerLink="/job-application/{{ job.id }}" *ngIf="job" class="btn mbtn blue btn-oval" fxFlex="25">
                <mat-icon class="tab-icon-space" svgIcon="icon_applied"></mat-icon>
                <span class="botton-text">
                  Candidature Simplifiée
                </span>

              </button>
              <!--<button class="btn mbtne blue btn-ova" fxFlex="25">
                <mat-icon class="tab-icon-space" svgIcon="icon_contact"></mat-icon>
                <span style="color: #68827e;" class="botton-text">Contacter l'employeur</span>
              </button>-->
            </div>
          </div>
        </div>
        <!-- thrird column -->
        <div fxLayout="column" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="100" class="sec3" fxLayoutAlign="center">
          <mat-tab-group class="remove-border-bottom">
            <mat-tab label="Mission">
              <ng-template class="mat-design" mat-tab-label>
                <mat-icon class="tab-icon-space" svgIcon="icon_mission"></mat-icon>
                <div style="text-align: start;">Missions</div>
              </ng-template>
              <div style="text-align: start;" [innerHTML]="job?.mission"></div>
            </mat-tab>
            <mat-tab label="Localisation">
              <ng-template class="mat-design" mat-tab-label>
                <mat-icon class="tab-icon-space" svgIcon="icon_map"></mat-icon>
                <div style="text-align: start;">Localisation</div>
              </ng-template>
              <div style="text-align: start;">
                <google-map height="185px" width="100%" [zoom]="zoom" [center]="center" [options]="options">
                  <map-marker [position]="center" [label]="job?.name" [options]="markerOptions"></map-marker>
                  <!-- <map-marker [position]="position" [label]="marker.label" -->

                </google-map>
              </div>
            </mat-tab>
            <mat-tab label="Profil Recherché">
              <ng-template mat-tab-label>
                <mat-icon class="tab-icon-space" svgIcon="icon_profile"></mat-icon>
                Profil Recherché
              </ng-template>

              <div style="text-align: start;" [innerHTML]="job?.profile">
              </div>
            </mat-tab>
            <mat-tab label="L'entreprise">
              <ng-template mat-tab-label>
                <div>
                  <mat-icon class="tab-icon-space" svgIcon="icon_company"></mat-icon>
                  <span class="my-custom-class">L'entreprise</span>
                </div>
              </ng-template>
              <div style="text-align: start;" [innerHTML]="job?.companySummary"></div>
            </mat-tab>
          </mat-tab-group>
          <div class="mattabdiv no-mobile">
            <button class="btn mbtne blue btn-ova mattabbutton">
              <mat-icon>flag</mat-icon>
              <span class="botton-text">Signaler</span>
            </button>
          </div>
        </div>

        <!--        <div fxLayout="row" fxFlexFill fxShow="true" fxShow.lg="true" fxShow.md="true">-->
        <!--          <div fxFlex="65"></div>-->
        <!--          <div fxFlex="35">-->
        <!--            <div (click)="getCompanyJobsOnJobs()"-->
        <!--              style="color: #42475E; text-decoration: underline; font-size: 14px; font-weight: 500; cursor: pointer;">-->
        <!--              voir les toutes les offres proposées par {{ job?.companyName }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="jd-btn" fxFlex="20" fxShow="false" fxShow.xs="true" fxLayout.xs="column" fxLayout.sm="row"
          fxLayoutAlign="space-between">
          <button routerLink="/job-application/{{ job?.id }}" class="btn mbtn blue" fxFlex="45" fxFlex.sm="45">
            <mat-icon class="tab-icon-space" svgIcon="icon_applied"></mat-icon>
            <span class="botton-text">
              Candidature Simplifiée
            </span>
          </button>
          <!--<button class="btn mbtne blue btn-ova" fxFlex="45" fxFlex.sm="45">
            <mat-icon class="tab-icon-space" svgIcon="icon_contact"></mat-icon>
            <span style="color: #68827e;" class="botton-text">Contacter l'employeur</span>
          </button>-->
          <!--          <div (click)="getCompanyJobsOnJobs()" fxFlex="45" fxFlex.sm="45"-->
          <!--            style="color: #42475E; text-decoration: underline; font-size: 14px; font-weight: 500; cursor: pointer; text-align: center;">-->
          <!--            Voir les toutes les offres proposées par {{ job?.companyName }}-->
          <!--          </div>-->
        </div>

      </div>

    </div>

    <!-- <hr> -->
    <div class="tridh" fxShow="true" fxShow.lg="true" fxShow.md="true">
      <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between center">
        <div fxFlex="0" fxFlex.lg="30"  fxFlex.md="30">
        </div>
        <div fxFlex="100" fxFlex.lg="70"  fxFlex.md="70" style="border-top: solid 1px #707070; margin-top: 20px;">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="30" fxLayoutAlign="space-around center">
              <div>publié le {{ job?.created_at | date: 'd/M/y'}}</div>
              <!--              <div>vue 231 fois</div>-->
            </div>
            <div fxFlex="70">
              <div class="mattabdiv no-web">
                <button class="btn mbtne blue btn-ova mattabbutton">
                  <mat-icon>flag</mat-icon>
                  <span class="botton-text">Signaler</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="similar-content-container" *ngIf="jobs.length !== 0" [ngClass]="{'disabled-button': jobs.length === 0}">
    <div class="similar">
      <span class="jobsNumberFound">
        <span style="font-weight: 600; font-size: 20px;">Voir les offres similaires</span>
        <br>
        <!--        <u style="font-size: 14px;">-->
        <!--          <span style="font-size: 14px; font-weight: 500;">-->
        <!--            Voir tout les offres pour le techniciens à Bordeaux-->
        <!--          </span>-->
        <!--        </u>-->
      </span>
    </div><br>
    <div class="category-row">
      <div class="bloc-category">
        <div fxLayoutGap="13px" fxFlexFill fxLayoutAlign="center center">
          <div *ngFor="let job of jobs">
            <mat-card routerLink="/job/{{job.id}}" class="card-image-action"
              style="box-shadow: 0 5px 5px #3b3e4e45; cursor: pointer;" [style.width]="'217px'">
              <span class="overlay1">
                <img class="masonry-compagny-logo" style="width: 85% !important;" src="{{ imageBaseUrl + job.logo }}">
              </span>
              <div class="card-image-box" [style.height]="'240px'">
                <span class="urgent-icon" *ngIf="job.urgent === 1">
                  <mat-icon svgIcon="icon_urgent"></mat-icon>
                </span>
                <div class="card-image-box-size">
                  <img mat-card-md-image class="job-image" [style.width]="'217px'" [style.height]="'240px'"
                    src="{{ imageBaseUrl + job.image }}" alt="">
                </div>
                <div class="card-hover-content" style="text-align: left;" fxLayout="column"
                  fxLayoutAlign="space-between ">
                  <div>
                    <a style="text-decoration: none;" routerLink="/job/{{job.id}}">
                      <h5>{{ job.companyName === '' ? 'N/A' : job.companyName | uppercase }}</h5>
                    </a>
                    <span class="card-hover-subtitle">{{ job.jobType }} - {{ job.address
                      }}</span>
                    <h4>{{ job?.salary === '0.00' ? 'N/A' : job?.salary}}
                      <span *ngIf="job?.salary !== '0.00'">{{job?.currency}}<!--/mois--></span>
                    </h4>
                    <p class="card-hover-desc" [innerHTML]="job.mission"></p>
                  </div>
                  <div>
                  </div>

                  <div class="card-hover-bottom" fxLayout="row" fxLayoutAlign="space-between end"
                    style="padding-bottom: 10px;">
                    <button fxLayout="row" fxLayoutAlign="space-around center" mat-button
                      class="btn-rounded btn-with-icon btn-primary-gradient" routerLink="job-application/{{job.id}}"
                      [disabled]="job.jobApplied == 1">
                      <mat-icon svgIcon="icon_postuler" style="margin-right: 5px;"></mat-icon>
                      <span>Postuler</span>
                    </button>
                    <div class="share-like-btn">
                      <button mat-icon-button (click)="$event.stopPropagation(); openShareDialog(job)"
                        class="card-bottom-icon favorite-icon">
                        <mat-icon> share</mat-icon>
                      </button>
                      <button mat-icon-button (click)="$event.stopPropagation(); abbJobToFavorite(job.id)"
                        class="card-bottom-icon favorite-icon">
                        <mat-icon> {{job.jobFavorite == 1 ? 'favorite': 'favorite_border'}}
                        </mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <mat-card-footer fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div class="card-footer-body">
                  <mat-card-title routerLink="/job/{{job.id}}">{{ job.jobName }}</mat-card-title>
                  <mat-card-subtitle>{{ job.jobType }} - {{ job.address }}</mat-card-subtitle>
                </div>
                <button mat-mini-fab color="secondary" class="btn-headphone-fab"
                  (click)="$event.stopPropagation(); playAudio(job.name, job.audio, job)">
                  <img class="job-audio-icons" src="assets/image/picto_audio_medium.png" width="40px">
                </button>
              </mat-card-footer>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isCompany() && isCompanyJob">
  <div fxLayout="row nowrap" fxFlexFill fxLayoutAlign="space-between stretch" class="homepage-header" fxShow.xs="false">
    <div class="navbar-title" fxFlex="1 1" fxFlexFill fxShow.xs="false">
      <h1> {{ job?.companyName=== '' ? 'N/A' : job.companyName | uppercase }} recherche {{jobForm.controls.name.value |
        uppercase}}</h1>
    </div>
  </div>
  <div class="route-history">
    <span>Offres d'emploi ></span>
    <span>{{job?.jobCategoryName}} > </span>
    <span> {{job?.jobCategoryName}} </span>
  </div>
  <div class="jd-bg" style="margin-bottom: 5rem;">
    <div class="bounds">
      <div class="content" fxLayout="row wrap" fxLayout.xs="column" fxFlexFill fxLayoutAlign="space-between">
        <div class="jd-acco" fxFlex="4" fxShow="false" fxShow.xs="true">
          <div style="margin-top: 15px; font-size: 12px; color: #03334F;">
          </div>
        </div>
        <div class="jd-ttl" fxFlex="6" fxShow="false" fxShow.xs="true" style="font-size: 23px; color: #03334F;">
          <span>{{ job?.companyName === '' ? 'N/A' : job.companyName | uppercase }} recherche </span>
          <span style="font-weight: bold;"> {{ jobForm.controls.name.value }}</span>
          <!-- TODO: actualisation du champ suivant valeur renseigné -->
        </div>
        <!-- premiere column -->
        <div fxFlex="15" fxFlex.md="15" fxFlex.sm="35" class="sec1 jd-img" fxFlex.xs="30"
          style="background-image: url({{ url ? url :imageBaseUrl + job?.image }})">
          <button class="btn mbtn blue btn-oval job-modal-button" (click)="openSuggestionDialog();">
            <span class="botton-text">
              Suggestions d'image
            </span>
          </button>
          <div class="apply-emp-profile">
            <label class="hoverable" for="fileInput">
              <!-- <img style="max-width: 15%" [src]="url ? url :imageBaseUrl + job?.image ">-->
              <div class="hover-text">Choisir une image</div>
              <div class="background"></div>
            </label>
            <br />
            <input id="fileInput" type='file' (change)="onSelectFile($event)" accept="image/*">
            <button *ngIf="url" mat-raised-button (click)="delete()" class="action-button">Supprimer</button>
          </div>
          <div class="jd-on-img" fxShow="false" fxShow.xs="true">
            <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="100">
              <button class="button" fxFlex="35">
                <!--<strong style="font-weight: bold;">
                  <mat-icon class="btp-icon" [svgIcon]="job?.jobCategoryImage"></mat-icon>
                  <span style="font-size: 11; color: #42475E;">{{ job?.jobCategoryName }}</span>
                </strong>-->
                <strong style="font-weight: bold;">
                  <app-edit-field [data]="jobForm.controls.JobCategoryId.value" [type]="'select'"
                    [datas]="jobCategories" (finalValue)="assignValue($event, 'JobCategoryId')">
                  </app-edit-field>
                </strong>
              </button>

              <div fxLayout="row" fxFlex="30" fxLayoutAlign="end">
                <!--<button mat-icon-button class="card-bottom-icon favorite-icon">
                  <mat-icon> share</mat-icon>
                </button>
                <button mat-icon-button class="card-bottom-icon favorite-icon">
                  <mat-icon>favorite</mat-icon>
                </button>-->
                <mat-icon svgIcon="icon_urgent" class="urgent0" (click)="urgent0()"
                  *ngIf="jobForm.controls.urgent.value==0"></mat-icon>
                <mat-icon svgIcon="icon_urgent" class="urgent1" (click)="urgent1()"
                  *ngIf="jobForm.controls.urgent.value==1"></mat-icon>
              </div>
            </div>

          </div>
        </div>
        <div class="jd-job-ttl" fxLayout="row" fxLayoutAlign="space-between" fxFlex="10" fxShow="false"
          fxShow.xs="true">
          <div fxFlex="20" fxLayoutAlign="center">
            <img class="job-audio-icon companyIcon" src="{{ imageBaseUrl + job?.logo }}">
          </div>
          <div fxFlex="60" fxLayoutAlign="start" fxLayout="column">
            <div fxFlex="40" fxLayoutAlign="start">
              <div class="text-truncate" style="font-weight: bold; font-size: 20px; color: #42475E">
                <app-edit-field [data]="jobForm.controls.name.value" [type]="'text'"
                  (finalValue)="assignValue($event, 'name')">
                </app-edit-field>
                <!--TODO: potentiellement prévoir une actualisation réactive en fonction de jobName renseigné-->
              </div>
            </div>
            <div fxFlex="40">
              <div style="font-size: 11px; color: #42475E; text-transform: capitalize;" class="text-truncate">
                <!-- <app-edit-field #jobType value="select Type de Job">{{ jobType.value }}</app-edit-field>
               <app-edit-field #address value="Define address and update marker in mat tab automatically">{{
                  address.value }}</app-edit-field>
               -->
                <app-edit-field [data]="jobForm.controls.typeJob.value" [type]="'select'" [datas]="types"
                  (finalValue)="assignValue($event, 'typeJob')">
                </app-edit-field>

                <app-edit-field [data]="jobForm.controls.address.value" [type]="'text'"
                  (finalValue)="assignValue($event, 'address')">
                </app-edit-field>
              </div>
            </div>
          </div>
          <div fxFlex="20" fxLayoutAlign="center" *ngIf="changeAudio">
            <app-reccord-audio (audioBlob)="buildAudioFile($event)" (recordFile)="playRecord($event)"
              (show)="stopRec($event)"></app-reccord-audio>
              <button mat-mini-fab color="secondary" class="btn-headphone-fab" style="width: 5em;
              height: 5em;">
                <mat-icon style="color: red;transform: scale(1.5);">add_shopping_cart</mat-icon>
              </button>
            <!--         TODO: replace by the recorder   <img class="job-audio-icon" src="assets/image/picto_audio_medium.png"-->
            <!--                 (click)="playAudio(job?.jobName, job?.audio, job);">-->
            <button *ngIf="audioFile" mat-raised-button (click)="deleteRecord()"
              class="action-button">Supprimer</button>
          </div>
          <div fxFlex="20" fxLayoutAlign="center" *ngIf="!changeAudio">
            <img class="job-audio-icon" src="assets/image/picto_audio_medium.png"
              (click)="playAudio(job?.jobName, job?.audio, job);">
          </div>
        </div>
        <button fxShow="false" fxShow.xs="true" mat-icon-button (click)="$event.stopPropagation(); changeAudioStatut()">
          <mat-icon [ngClass]="{'icon-link': changeAudio}"> edit</mat-icon>
        </button>
        <!-- <mat-slide-toggle [(ngModel)]="changeAudio">Change job Audio
        </mat-slide-toggle> -->
        <!-- second column -->
        <div fxFlex="26" fxFlex.md="26" class="sec2" fxFlex.sm="60" fxShow="true" fxShow.xs="false" fxShow.sm="true"
          fxShow.lg="true" fxShow.md="true">
          <!-- column to contain third part into second column  -->
          <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="100">
            <!-- first row imagee/facebook/linkedin with space between -->
            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="22">
              <div fxFlex="30" fxLayoutAlign="start">
                <img class="companyIcon" style="cursor: pointer; height: 44px; width: 44px;"
                  src="{{ imageBaseUrl + job?.logo }}" width="130px">
              </div>
              <div fxFlex="30" fxLayoutAlign="end">
                <mat-icon svgIcon="icon_urgent" class="urgent0" (click)="urgent0()"
                  *ngIf="jobForm.controls.urgent.value==0"></mat-icon>
                <mat-icon svgIcon="icon_urgent" class="urgent1" (click)="urgent1()"
                  *ngIf="jobForm.controls.urgent.value==1"></mat-icon>
              </div>
            </div>
            <!-- second row but he must contain two different bloc -->
            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="25">
              <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="100">
                <div fxFlex="65" fxLayoutAlign="start">
                  <div class="text-truncate" style="font-weight: bold; font-size: 20px; color: #42475E">
                    <app-edit-field [data]="jobForm.controls.name.value" [type]="'text'"
                      (finalValue)="assignValue($event, 'name')">
                    </app-edit-field>
                  </div>
                </div>
                <div fxFlex="30" fxLayoutAlign="end">
                  <div style="font-weight: bold; font-size: 16px; color: #42475E; word-wrap: break-word;">
                    <app-edit-field [data]="jobForm.controls.salary.value" [type]="'number'"
                      (finalValue)="assignValue($event, 'salary')">
                    </app-edit-field>
                  </div>

                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="100">
                <div fxFlex="65" fxLayoutAlign="start">
                  <div style="font-size: 11px; color: #42475E; text-transform: capitalize;" class="text-truncate">
                    <app-edit-field [data]="jobForm.controls.typeJob.value" [type]="'select'" [datas]="types"
                      (finalValue)="assignValue($event, 'typeJob')">
                    </app-edit-field>
                    <app-edit-field [data]="jobForm.controls.address.value" [type]="'text'"
                      (finalValue)="assignValue($event, 'address')">
                    </app-edit-field>
                  </div>
                </div>
                <div fxFlex="25" fxLayoutAlign="end">
                  <span style="font-size: 16px; color: #42475E;"> net<!--/mois--></span>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="100">

                <strong style="font-weight: bold;">
                  <app-edit-field [data]="jobForm.controls.JobCategoryId.value" [type]="'select'"
                    [datas]="jobCategories" (finalValue)="assignValue($event, 'JobCategoryId')">
                  </app-edit-field>
                </strong>

                <div fxLayout="row" fxFlex="30" fxLayoutAlign="end">
                  <button mat-icon-button class="card-bottom-icon favorite-icon">
                    <mat-icon class="icon-link"> share</mat-icon>
                  </button>
                  <button mat-icon-button class="card-bottom-icon favorite-icon">
                    <mat-icon class="icon-link"> favorite </mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="45">
              <!--<audio controls style="width: inherit;" fxFlex="45" src="{{ imageBaseUrl + job?.audio }} ">
              </audio>-->
              <button mat-icon-button (click)="$event.stopPropagation(); changeAudioStatut()">
                <mat-icon [ngClass]="{'icon-link': changeAudio}"> edit</mat-icon>
                Change job Audio
              </button>
              <!-- <mat-slide-toggle [(ngModel)]="changeAudio">Change job Audio</mat-slide-toggle> -->
              <!--job-single-player-->
              <div class="job-single-player" *ngIf="!changeAudio" fxLayout="row" fxLayoutAlign="stretch center"
                fxFlex="45">
                <div fxFlex="15">
                  <img src="assets/image/picto_audio_seul.svg" />
                </div>
                <div fxFlex="10">
                  <button mat-icon-button fxFlex="100" *ngIf="state?.playing" (click)="pause()">
                    <mat-icon class="icoWhyte">pause</mat-icon>
                  </button>
                  <button mat-icon-button fxFlex="100" (click)="play()" [disabled]="state?.error"
                    *ngIf="!state?.playing">
                    <mat-icon class="icoWhyte">play_circle_filled </mat-icon>
                  </button>
                </div>
                <div fxFlex="15">
                  <span class="time">{{ state?.readableCurrentTime}}</span>
                </div>
                <div fxFlex="50">
                  <mat-slider class="time-slider w-100 text-white" min="0" [max]="state?.duration" step="1"
                    [value]="state?.currentTime" (input)="onSliderChangeEnd($event)" [disabled]="state?.error">
                  </mat-slider>
                </div>
                <div fxFlex="15">
                  <span class="time">{{ state?.readableDuration }}</span>
                </div>
                <div fxFlex="10" *ngIf="!show">
                  <button mat-icon-button fxFlex="10" (click)="show=true">
                    <mat-icon class="icoWhyte" *ngIf="val">volume_up</mat-icon>
                    <mat-icon class="text-white" *ngIf="!val" mat-list-icon> volume_off</mat-icon>
                  </button>
                </div>
                <div fxFlex="10" *ngIf="show" fxLayout="column">
                  <mat-slider vertical class="volume-slider w-50" min="0" fxFlex="10" [max]="1" step=".1"
                    [value]="state?.volume" (input)="onVolumeChangeEnd($event)"></mat-slider>
                  <button mat-icon-button fxFlex="10" (click)="mute()">
                    <mat-icon class="icoWhyte" *ngIf="val">volume_up</mat-icon>
                    <mat-icon class="text-white" *ngIf="!val" mat-list-icon> volume_off</mat-icon>
                  </button>
                </div>
                <div fxFlex="10">
                  <button mat-icon-button fxFlex="10">
                    <mat-icon class="icoWhyte"> more_vert</mat-icon>
                  </button>
                </div>
              </div>
              <!--job-single-player TODO: add a toggle "Change job Audio => toggled disable previous player and enable recorder below"-->

              <div fxLayout="row" fxLayoutAlign="space-evenly center" class="mb-2" fxFlex="40" *ngIf="changeAudio">
                <app-reccord-audio (audioBlob)="buildAudioFile($event)" (recordFile)="playRecordFile($event)"
                  (show)="stopRec($event)"></app-reccord-audio>
                <button *ngIf="audioFile" mat-raised-button (click)="deleteRecordFile()"
                  class="action-button">Supprimer</button>
                  <div>
                    <button (click)="isOrderedJob = true" [ngClass]="{'ordered_audio': isOrderedJob}"  title="Commander un audio" mat-mini-fab color="secondary" class="btn-headphone-fab" style="width: 5em;
                    height: 5em; border-radius: 50%;">
                      <mat-icon >add_shopping_cart</mat-icon>
                    </button>
                  </div>
                  <div>
                    <button (click)="openTemplateAudioDialog()" title="Template audio" mat-mini-fab color="secondary" class="btn-headphone-fab" style="width: 5em;
                      height: 5em;border-radius: 50%;">
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 21c0 .5.4 1 1 1h4c.6 0 1-.5 1-1v-1H9v1zm3-19C8.1 2 5 5.1 5 9c0 2.4 1.2 4.5 3 5.7V17c0 .5.4 1 1 1h6c.6 0 1-.5 1-1v-2.3c1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7z"/></svg>                    </button>

                  </div>


              </div>

              <!--<button class="btn mbtne blue btn-ova" fxFlex="25" *ngIf="showPlayer && !audioFile" (click)="record()">
                <mat-icon class="tab-icon-space" svgIcon="icon_contact"></mat-icon>
                <span style="color: #68827e;" class="botton-text">Changer audio</span>
              </button>-->
              <button class="btn mbtn blue btn-oval" fxFlex="25" (click)="submit()">
                <span class="botton-text">
                  Publier l'offre
                </span>
              </button>

            </div>
          </div>
        </div>
        <!-- thrird column -->
        <div fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="100" class="sec3" fxLayoutAlign="center">
          <mat-tab-group class="remove-border-bottom">
            <mat-tab label="Mission">
              <ng-template class="mat-design" mat-tab-label>
                <mat-icon class="tab-icon-space" svgIcon="icon_mission"></mat-icon>
                <div style="text-align: start;">Missions</div>
              </ng-template>
              <div style="text-align: start;">
                <app-edit-field [data]="jobForm.controls.mission.value" [type]="'textarea'"
                  (finalValue)="assignValue($event, 'mission')">
                </app-edit-field>
              </div>
            </mat-tab>
            <mat-tab label="Localisation">
              <ng-template class="mat-design" mat-tab-label>
                <mat-icon class="tab-icon-space" svgIcon="icon_map"></mat-icon>
                <div style="text-align: start;">Localisation</div>
              </ng-template>
              <div style="text-align: start;">
                <google-map height="185px" width="100%" [zoom]="zoom" [center]="center" [options]="options">
                  <map-marker [position]="center" [label]="job?.name" [options]="markerOptions"></map-marker>
                  <!-- <map-marker [position]="position" [label]="marker.label" -->

                </google-map>
              </div>
            </mat-tab>
            <mat-tab label="Profil Recherché">
              <ng-template mat-tab-label>
                <mat-icon class="tab-icon-space" svgIcon="icon_profile"></mat-icon>
                Profil Recherché
              </ng-template>

              <div style="text-align: start;">
                <app-edit-field [data]="jobForm.controls.profile.value" [type]="'textarea'"
                  (finalValue)="assignValue($event, 'profile')">
                </app-edit-field>
              </div>
            </mat-tab>
            <mat-tab label="L'entreprise">
              <ng-template mat-tab-label>
                <div>
                  <mat-icon class="tab-icon-space" svgIcon="icon_company"></mat-icon>
                  <span class="my-custom-class">L'entreprise</span>
                </div>
              </ng-template>
              <div style="text-align: start;" [innerHTML]="job?.companySummary" disabled></div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <!--        <div fxLayout="row" fxFlexFill fxShow="true" fxShow.lg="true" fxShow.md="true">-->
        <!--          <div fxFlex="65"></div>-->
        <!--          <div fxFlex="35">-->
        <!--            <div (click)="getCompanyJobsOnJobs()"-->
        <!--              style="color: #42475E; text-decoration: underline; font-size: 14px; font-weight: 500; cursor: pointer;">-->
        <!--              voir les toutes les offres proposées par {{ job?.companyName }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="jd-btn" fxFlex="20" fxShow="false" fxShow.xs="true" fxLayout.xs="column" fxLayout.sm="row"
          fxLayoutAlign="space-between">
          <!--<button class="btn mbtn blue" fxFlex="45" fxFlex.sm="45">
            <mat-icon class="tab-icon-space" svgIcon="icon_applied"></mat-icon>
            <span class="botton-text">
              Candidature Simplifiée
            </span>
          </button>
          <button class="btn mbtne blue btn-ova" fxFlex="45" fxFlex.sm="45">
            <mat-icon class="tab-icon-space" svgIcon="icon_contact"></mat-icon>
            <span style="color: #68827e;" class="botton-text">Contacter l'employeur</span>
          </button>-->

          <!--<button class="btn mbtne blue btn-ova" fxFlex="25" *ngIf="showPlayer && !audioFile" (click)="record()">
            <mat-icon class="tab-icon-space" svgIcon="icon_contact"></mat-icon>
            <span style="color: #68827e;" class="botton-text">Changer audio</span>
          </button>-->
          <button class="btn mbtn blue btn-oval" fxFlex="25" (click)="submit()">
            <span class="botton-text">
              Publier l'offre
            </span>
          </button>
          <!--          <div (click)="getCompanyJobsOnJobs()" fxFlex="45" fxFlex.sm="45"-->
          <!--            style="color: #42475E; text-decoration: underline; font-size: 14px; font-weight: 500; cursor: pointer; text-align: center;">-->
          <!--            Voir les toutes les offres proposées par {{ job?.companyName }}-->
          <!--          </div>-->
        </div>

      </div>

    </div>

    <!-- <hr> -->
    <div class="tridh" fxShow="true" fxShow.lg="true" fxShow.md="true">
      <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between center">
        <div fxFlex="0" fxFlex.lg="30"  fxFlex.md="30">
        </div>
        <div fxFlex="100" fxFlex.lg="70"  fxFlex.md="70" style="border-top: solid 1px #707070; margin-top: 20px;">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="30" fxLayoutAlign="space-around center">
              <div>publié le {{ job?.created_at | date: 'd/M/y'}}</div>
              <!--              <div>vue 231 fois</div>-->
            </div>
            <div fxFlex="70">
            </div>
          </div>
          <div fxFlex="70">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
