export const environment = {
  production: false,
  backendApiUrl: 'https://api.test.gotaf.fr/api/',
  backendUrl: 'https://api.test.gotaf.fr/',
  imageBaseUrl: 'https://api.test.gotaf.fr/storage/',
  /*backendApiUrl: 'http://gotaf.test:8081/api/',
  backendUrl: 'http://gotaf.test:8081/',
  imageBaseUrl: 'https://api.gotaf.fr/storage/'**/
};

/*  export const environment = {
    production: true,
    backendApiUrl: 'https://api.gotaf.fr/api/',
    backendUrl: 'https://api.gotaf.fr/',
    imageBaseUrl: 'https://api.gotaf.fr/storage/',
};*/
