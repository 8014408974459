import { UserJobAppliedComponent } from './module/user/user-job-applied/user-job-applied.component';
import { UserJobFavoriteComponent } from './module/user/user-job-favorite/user-job-favorite.component';
import { AudioPlayerComponent } from './module/audio-player/audio-player.component';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AgmDirectionModule } from 'agm-direction';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomepageComponent } from './module/homepage/homepage.component';
import { LoginComponent } from './module/user/login/login.component';
import { JobsComponent } from './module/jobs/jobs.component';
import { JobDetailComponent } from './module/jobs/job-detail/job-detail.component';
import { JobApplicationComponent } from './module/jobs/job-application/job-application.component';
import { UserCvCreateComponent } from './module/user/user-cv-create/user-cv-create.component';
import { JobAddComponent } from './module/jobs/job-add/job-add.component';
import { JobCategoryComponent } from './module/jobs/job-category/job-category.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HeaderComponent } from './module/header/header.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FooterComponent } from './module/footer/footer.component';
import { ScrollDispatcher, ScrollingModule } from '@angular/cdk/scrolling';
import { MatTabsModule } from '@angular/material/tabs';
import { SignupComponent } from './module/user/signup/signup.component';
import { PasswordResetComponent } from './module/user/password-reset/password-reset.component';
import { ErrorInterceptor, JwtInterceptor } from './_helpers';
import { CompanyJobsComponent } from './module/company/company-jobs/company-jobs/company-jobs.component';
import { CompanyJobAppliedComponent } from './module/company/company-job-applied/company-job-applied.component';
import { _MatMenuDirectivesModule, MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { MatExpansionModule } from '@angular/material/expansion';
import { CompanyDashboardComponent } from './module/company/dashboard/dashboard.component';
import { SharedModule } from './shared/shared.module';
import { NgxMasonryModule } from 'ngx-masonry';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatSliderModule } from '@angular/material/slider';
import { MatListItem, MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CompanyUserCompareComponent } from './module/company/company-user-compare/company-user-compare.component';
import { CompanyProfileCandidateComponent } from './module/company/company-profile-candidate/company-profile-candidate.component';
import { MatSelectModule } from '@angular/material/select';
import { SnackBarComponent } from './shared/components/snack-bar/snack-bar.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
registerLocaleData(local);
import local from '@angular/common/locales/fr';
import { UserProfileComponent } from './module/user/user-profile/user-profile.component';
import { MatTableModule } from '@angular/material/table';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { FilterPipe } from './shared/pipe/filter.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { UserDashboardComponent } from './module/user/dashboard/dashboard.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CompanySubscriptionsComponent } from './module/company/company-subscriptions/company-subscriptions.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CompanyModifyComponent } from './module/company/company-modify/company-modify.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { JobQuestionsComponent } from './module/jobs/job-questions/job-questions.component';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './app-routting-cache.strategy';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfirmCodeComponent } from './module/user/confirm-code/confirm-code.component';
import { NgxLoadingModule } from 'ngx-loading';
import { JobImageSuggestionComponent } from './module/jobs/job-image-suggestion/job-image-suggestion.component';
import { JobImageSuggessionComponentComponent } from './module/jobs/job-image-suggession-component/job-image-suggession-component.component';
import { NewsletterComponent } from './module/newsletter/newsletter.component';
import { PresseComponent } from './module/presse/presse.component';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CgvComponent } from './module/cgu/cgv/cgv.component';
import { CookiesComponent } from './module/cgu/cookies/cookies.component';
import { PersonalDataManagementComponent } from './module/cgu/personal-data-management/personal-data-management.component';
import { CopyrightPolicyComponent } from './module/cgu/copyright-policy/copyright-policy.component';
import { CompanyJobMultipleComponent } from './module/company/company-job-multiple/company-job-multiple.component';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { JobTemplateAudioComponent } from './module/jobs/job-template-audio-component/job-template-audio.component';
import { ContactUsComponent } from './module/contact-us/contact-us.component';
import { LoaderComponent } from './module/loader/loader.component';
import { HttpClientService } from './service/http-client/http-client.service';




const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'gotaf.fr' // or 'your.domain.com' // it is mandatory to set a domain,for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: 'bottom-right',
  theme: 'classic',
  palette: {
    popup: {
      background: '#42475e',
      text: '#ffffff',
      link: '#ffffff'
    },
    button: {
      background: '#458076',
      text: '#ffffff',
      border: 'transparent'
    }
  },
  type: 'opt-out',
  content: {
    message: 'gOtaf utilise des cookies afin d\'optimiser l\'experience de l\'utilisateur.',
    dismiss: 'Ok!',
    allow: 'Accepter les cookies',
    deny: 'Refuser les cookies',
    link: 'En savoir plus',
    href: 'https://gotaf.fr/cookies',
    policy: 'Cookie Policy'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    LoginComponent,
    JobsComponent,
    JobDetailComponent,
    JobApplicationComponent,
    UserCvCreateComponent,
    JobAddComponent,
    JobCategoryComponent,
    HeaderComponent,
    FooterComponent,
    SignupComponent,
    PasswordResetComponent,
    CompanyJobsComponent,
    CompanyJobAppliedComponent,
    CompanyDashboardComponent,
    AudioPlayerComponent,
    CompanyUserCompareComponent,
    CompanyProfileCandidateComponent,
    SnackBarComponent,
    UserJobFavoriteComponent,
    UserJobAppliedComponent,
    UserProfileComponent,
    UserDashboardComponent,
    FilterPipe,
    CompanySubscriptionsComponent,
    CompanyModifyComponent,
    JobQuestionsComponent,
    ConfirmCodeComponent,
    JobImageSuggestionComponent,
    JobTemplateAudioComponent,
    JobImageSuggessionComponentComponent,
    NewsletterComponent,
    PresseComponent,
    CgvComponent,
    CookiesComponent,
    PersonalDataManagementComponent,
    CopyrightPolicyComponent,
    CompanyJobMultipleComponent,
    ContactUsComponent,
    LoaderComponent,
    // SpinnerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatRadioModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    HttpClientModule,
    MatToolbarModule,
    MatButtonToggleModule,
    FlexLayoutModule,
    MatSidenavModule,
    ScrollingModule,
    MatTabsModule,
    _MatMenuDirectivesModule,
    MatMenuModule,
    MatStepperModule,
    MatCarouselModule.forRoot(),
    MatExpansionModule,
    SharedModule,
    GoogleMapsModule,
    NgxMasonryModule,
    MatSliderModule,
    MatListModule,
    SwiperModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTableModule,
    MatProgressBarModule,
    SocialLoginModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    MatAutocompleteModule,
    NgPipesModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyDXb_Mq0lK0KfNT-1l4NxdUEHDNmIcPmFE', libraries: ['geometry'] }),
    MatNativeDateModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    AgmDirectionModule,
    InfiniteScrollModule,
    MatDialogModule,
    InfiniteScrollModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    NgxLoadingModule.forRoot({}),
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgxMatFileInputModule,
  ],
  providers: [
    // { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    TextToSpeech,
    Meta,
    GoogleMapsAPIWrapper,
    ScrollDispatcher,
    HttpClientService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '546137036105-508f0ej6402tbdmj7founuts6sgg23r0.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('3692300550834815')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export class FileReaderA extends window.FileReader {
	constructor() {
		super();
		const zoneOriginalInstance = (this as any)['__zone_symbol__originalInstance'];
		return zoneOriginalInstance || this;
	}
}
window.FileReader = FileReaderA;
// platformBrowserDynamic().bootstrapModule(AppModule);
