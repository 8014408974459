import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../service/authentication/authentication.service';
import { first } from 'rxjs/operators';
import { SnackService } from '../../../service/snack/snack.service';
import { TextToSpeechService } from 'src/app/service/text-to-speech/text-to-speech.service';
import { AudioService } from '../../../service/audio.service';
import { MatDialog } from '@angular/material/dialog';
import { Platform } from '@angular/cdk/platform';
import { PasswordResetComponent } from '../password-reset/password-reset.component';
declare var FCMPlugin: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css', '../../../../../node_modules/bootstrap/dist/css/bootstrap.css']
})
export class LoginComponent implements OnInit, AfterViewChecked {
  public loginForm: FormGroup;

  public disabled: boolean;
  private request: boolean;
  loading = false;
  submitted = false;
  error = '';
  public isIOSDevice = false;
  public isMobileDevice = false;
  isEntreprise = false;
  headerTitle = 'Vous êtes à 2 clics du job  de vos rêves';
  private audioUrl = 'assets/audio/login-user.wav';
  public showPlayer = false;
  public deviceToken = "";
  public deviceType: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private snack: SnackService,
    private audioService: AudioService,
    private speech: TextToSpeechService,
    private plt: Platform,
    private dialog: MatDialog
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.userValue) {
      this.router.navigate(['/']);
    }
    if ((this.plt.IOS) && (this.plt.BLINK == false && this.plt.EDGE == false && this.plt.FIREFOX == false && this.plt.SAFARI == false && this.plt.TRIDENT == false)) {

      this.isIOSDevice = true;
    }
    if ((this.plt.ANDROID || this.plt.IOS) && (this.plt.BLINK == false && this.plt.EDGE == false && this.plt.FIREFOX == false && this.plt.SAFARI == false && this.plt.TRIDENT == false)) {
      this.isMobileDevice = true;
      this.initializeApp();
      this.deviceType = this.plt.ANDROID ? 2 : 1;
    }
  }
  private initializeApp(): void {
    if ((this.plt.ANDROID || this.plt.IOS) && (this.plt.BLINK == false && this.plt.EDGE == false && this.plt.FIREFOX == false && this.plt.SAFARI == false && this.plt.TRIDENT == false)) {
      FCMPlugin.getToken(
        (pushRegistrationId: any) => {
          this.deviceToken = pushRegistrationId;
        },
        (err: any) => {
          console.log('error retrieving push registration id: ' + err);
        }
      );
    }
  }
  public setStateUi(state: boolean): void {
    this.isEntreprise = state;
    if (this.isEntreprise) {
      this.headerTitle = 'Trouvez le candidat idéal';
    } else {
      this.headerTitle = 'Vous êtes à 2 clics du job  de vos rêves';
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      deviceToken: [''],
      deviceType: [''],
    });
  }

  ngAfterViewChecked(): void {
    this.disabled = !(this.loginForm.valid && !this.request);
    this.cdRef.detectChanges();
  }

  // convenience getter for easy access to form fields
  public get f(): { [p: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  public onSubmit(): void {
    this.submitted = true;
    this.loginForm.controls['deviceToken'].setValue(this.deviceToken);
    this.loginForm.controls['deviceType'].setValue(this.deviceType);

    // stop here if form is invalid
    // if (this.loginForm.invalid) {
    //   return;
    // }
    this.request = true;
    this.disabled = true;
    this.authenticationService.login(this.f.username.value, this.f.username.value, this.f.password.value, this.f.deviceToken.value, this.f.deviceType.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          // console.log(data);
          // get return url from query parameters or default to home page
          // const returnUrl = this.route.snapshot.params.returnUrl || '/';
          if (data.role === 'company') {
            const returnUrl = this.route.snapshot.queryParams.returnUrl || '/company-job-applied';
            this.router.navigateByUrl(returnUrl);
            console.log(returnUrl);
          } else {
            const returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
            console.log(returnUrl);
            this.router.navigateByUrl(returnUrl);
            this.snack.valid('Vous êtes connecté');
          }

        },
        error: error => {
          this.snack.error('Informations de connexions incorrects');
        }
      });
  }

  public register(): void {
    if (this.route.snapshot.queryParams.returnUrl) {
      const returnUrl = '/register?returnUrl=' + this.route.snapshot.queryParams.returnUrl || '/register';
      this.router.navigateByUrl(returnUrl);
    } else {
      this.router.navigate(['/register']);
    }
  }

  public playAudio(): void {
    // tslint:disable-next-line: no-unused-expression
    this.showPlayer = true;
    this.audioService.playStream(this.audioUrl).subscribe(events => {
    });
  }

  public playStream(url): void {
    this.audioService.playStream(url).subscribe(events => {
    });
  }

  public closePlayer(): void {
    this.audioService.stop();
    this.showPlayer = false;
  }

  public openForgetModal(): void {
    this.dialog.open(PasswordResetComponent, { disableClose: true })
      .afterClosed().subscribe((data) => {
        console.log('i am close');
      });
  }
}
