<div class="content-component">
  <ng-form [formGroup]="loginForm" class="login-form">
    <div class="main_row">
      <div class="left_side_container" [ngClass]="{'entreprise-image ': isEntreprise, 'default-image': !isEntreprise}">
      </div>
      <div class="right_side_container">
        <div class="right_top_side_header">
          <div class="back-to">
            <a routerLink="" mat-button>
              <mat-icon style="color: green">keyboard_backspace</mat-icon>
              <span> Accueil </span>
            </a>
          </div>
          <mat-icon [ngClass]="{'svg-entreprise ': isEntreprise, 'null': !isEntreprise}" svgIcon="gotaf_color"
            class="login-logo"></mat-icon>
          <p style="color: #c3c4c6; font-size: 18px;">{{headerTitle}}</p>
        </div>
        <div class="right_side_center">
          <div fxLayout="row" fxLayoutAlign="center center" fxFlex="15">
            <button fxFlex="50" (click)="setStateUi(false)" [ngClass]="{'default-button-color select': !isEntreprise}"
              class="default-button-style button-type" mat-stroked-button>
              <span class="button-text">Je recherche un emploi</span>
            </button>

            <button fxFlex="50" (click)="setStateUi(true)" [ngClass]="{'entreprise-button-color select': isEntreprise}"
              class="default-button-style button-type" mat-stroked-button>
              <span class="button-text">Je suis une entreprise</span>
            </button>
          </div>

          <div fxLayout="column" fxLayoutAlign="center center"
            style="margin-left: 10px; margin-right: 10px; margin-top: 20px;">
            <mat-form-field [ngClass]="{'entreprise-color': isEntreprise, 'default-color': !isEntreprise}"
              appearance="none" style="width: 100%;">
              <input class="login-input" matInput (keyup.enter)="onSubmit()" placeholder="Login"
                formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
              <mat-error *ngIf="submitted && f.username.errors"> This field is required </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="100%" [ngClass]="{'entreprise-color': isEntreprise, 'default-color': !isEntreprise}"
              appearance="none" style="width: 100%;">
              <input class="login-input" matInput (keyup.enter)="onSubmit()" placeholder="Mot de passe" type="password"
                formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
              <!-- <mat-error *ngIf="submitted && f.password.errors.required"> This field is required </mat-error> -->
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-right: 12px; margin-left: 12px;"
            class="remember">
            <mat-checkbox [ngClass]="{'entreprise-color': isEntreprise, 'default-color': !isEntreprise}">
              Se souvenir de moi</mat-checkbox>
            <button mat-button (click)="openForgetModal()"
              [ngClass]="{'entreprise-color': isEntreprise, 'default-color': !isEntreprise}">
              Mot de passe oublié</button>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <button fxFlex="45" class="default-button-style login-register" (click)="register()"
              [ngClass]="{'entreprise-button-color': isEntreprise, 'default-button-color': !isEntreprise}" mat-button>
              <span class="button-text">M'inscrire chez gOtaf</span>
            </button>
            <span>OU</span>
            <button fxFlex="45" [ngClass]="{'entreprise-login-connect': isEntreprise, 'login-connect ': !isEntreprise}"
              class="default-button-style login-connect" (click)="onSubmit()" mat-button>
              <span class="button-text">Je me connecte</span>
            </button>
          </div>
        </div>
        <div class="right_side_bottom">

          <div class="center">
            <a href="#" [ngClass]="{'entreprise-color': isEntreprise, 'default-color': !isEntreprise}">Conditions
              d'utilisation</a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
  </ng-form>
</div>
<!--audio player-->
<div class="playerDiv" *ngIf="showPlayer">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="closePlayer()">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <app-audio-player></app-audio-player>
</div>
<!--end audio player-->

<mat-icon class="audio-icon" svgIcon="audio-yellow" (click)="playAudio()"></mat-icon>
