<ng-container *ngIf="jobsService.isVoirCLick">
  <div class="header-grad-bg"
    style="height: 64px; background: linear-gradient(232deg,#bbedc2,#99e6a9 15%,#73c59c 75%,#458076);;"></div>
  <!-- Search Form -->
  <form class="search-form py-3" [formGroup]="searchForm">
    <div class="container">
      <div fxLayout="row wrap" fxLayout.lt-md="row" fxLayout.lt-sm="column" fxLayoutAlign="center center"
        fxLayoutGap="10px">
        <div class="searchjob-feald" fxFlex="25" fxFlex.lt-md="100">
          <button mat-button class="search-form-field btn-service-la">
            <span class="icon-before">
              <mat-icon [svgIcon]="category?.image"></mat-icon>
            </span>
            {{category?.name}}
            <span class="icon-after">
              <mat-icon matSuffix svgIcon="audio" (click)="playAudio(category.name, category.audio, category)">
              </mat-icon>
            </span>
          </button>
        </div>
        <div class="searchjob-feald" fxFlex="31" fxFlex.lt-md="100" *ngFor="let data of array | slice :0:1">
          <mat-form-field appearance="none" class="search-form-field">
            <input (keyup)="searchFunction()" type="search" formControlName="querry" class="form-control" matInput
              placeholder="Métier, compétence, mot clé" #div>
            <span class="icon-before">
              <mat-icon svgIcon="icon_metier"></mat-icon>
            </span>
            <span class="icon-after">
              <mat-icon matSuffix svgIcon="icon_micro" (click)="voiceSearch(0)"></mat-icon>
            </span>
          </mat-form-field>
        </div>
        <div class="searchjob-feald" fxFlex="30" fxFlex.lt-md="100" *ngFor="let data of array | slice :1">
          <mat-form-field appearance="none" class="search-form-field">
            <input (keyup)="searchFunction()" type="search" formControlName="city" class="form-control" matInput
              placeholder="Saisissez une ville" #div>
            <span class="icon-before">
              <mat-icon svgIcon="icon_localisation"></mat-icon>
            </span>
            <span class="icon-after">
              <mat-icon matSuffix svgIcon="icon_micro" (click)="voiceSearch(1)"></mat-icon>
            </span>
          </mat-form-field>
        </div>
        <button mat-fab color="secondary" class="search-icon btn-search" (click)="getAllJobsByCategorie(true)">
          <mat-icon>search</mat-icon>
        </button>
        <a (click)="saveToSearch()" *ngIf="userConnected != null">
          <mat-icon class="save-icon" svgIcon="icon_save"></mat-icon>
        </a>
      </div>
    </div>
  </form>
  <!-- <form class="search-form py-3" [formGroup]="searchForm">
    <div class="container">
      <div fxLayout="row wrap" fxLayout.lt-md="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div class="" fxFlex="31" fxFlex.lt-md="100">
          <button mat-button class="search-form-field btn-service-la">
            <span class="icon-before">
              <mat-icon [svgIcon]="category?.image"></mat-icon>
            </span>
            {{category?.name}}
            <span class="icon-after">
              <mat-icon matSuffix svgIcon="audio"></mat-icon>
            </span>
          </button>
        </div>
        <div class="" fxFlex="32" fxFlex.lt-md="100">
          <mat-form-field appearance="none" class="search-form-field">
            <input class="form-control" formControlName="querry" (keyup)="searchFunction()" matInput
              placeholder="Métier, compétence, mot clé">
            <span class="icon-before">
              <mat-icon svgIcon="icon_metier"></mat-icon>
            </span>
            <span class="icon-after">
              <mat-icon matSuffix svgIcon="icon_micro"></mat-icon>
            </span>
          </mat-form-field>
        </div>
        <div class="" fxFlex="23" fxFlex.lt-md="100">
          <mat-form-field appearance="none" class="search-form-field">
            <input class="form-control" formControlName="querry" (keyup)="searchFunction()" matInput
              placeholder="Saisissez une ville">
            <span class="icon-before">
              <mat-icon svgIcon="icon_localisation"></mat-icon>
            </span>
            <span class="icon-after">
              <mat-icon matSuffix svgIcon="icon_micro"></mat-icon>
            </span>
          </mat-form-field>
        </div>
        <button mat-fab color="secondary" (click)="searchFunction()" class="search-icon btn-search">
          <mat-icon>search</mat-icon>
        </button>

        <a (click)="saveToSearch()">
          <mat-icon class="save-icon" svgIcon="icon_save"></mat-icon>
        </a>
      </div>
    </div>
  </form> -->
  <!-- End Search Form -->
  <div class="container job-category-section pt-4 pb-4">
    <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch">

      <!-- Left side -->
      <div class="jobs-left-main" fxFlex="70" fxFlex.lt-lg="70" fxFlex.lt-md="100">
        <!-- Breadcrumbs -->
        <ul class="list-inline breadcrumbs mb-4">
          <li class="list-inline-item">
            <a [routerLink]="[ '/' ]" routerLinkActive="active" class="beadcrums-child">Accueil</a>
          </li>
          <li class="list-inline-item">
            <!-- <a [routerLink]="[ '/' ]" routerLinkActive="active">Offres d'emploi</a> -->
            <span>{{ category?.name }}</span>
            <!-- <a>{{ category?.name }}, à Bordeaux et ses alentours</a> -->
          </li>
        </ul>

        <!-- Section title -->
        <div class="mb-4" fxLayout="row" fxLayoutAlign="space-between center">
          <h3 class="mr-2" style="color: #03334F;">{{ categoryJobNumber }} offre(s) trouvée(s)</h3>
          <div class="grid-list-icons" fxLayout="row">
            <a [class.active]="gridSection" class="mr-3 d-inline-block" (click)="gridFunction()">
              <mat-icon matSuffix svgIcon="icon_grid"></mat-icon>
            </a>
            <a [class.active]="!gridSection" class="d-inline-block" (click)="listFunction()">
              <mat-icon matSuffix svgIcon="icon_list"></mat-icon>
            </a>
          </div>
        </div>
        <app-filters fxShow="true" class="filter-btn-oneline" (jobResult)="getAllJobsByCategorie($event)"
          (urgentJob)="showUrgentJob($event)" class="filter-normal job-filter-respo">
        </app-filters>
        <app-loader  class="pre_loader" *ngIf="isPreLoader == true"></app-loader>
        <!-- Mosaic -->
        <!-- <div class="mosaic" *ngIf="gridSection">
          <div class="masonry-with-columns" #shuffleContainer fxLayout="row wrap" fxLayoutAlign="start start"
            fxLayoutAlign.lt-md="center stretch" fxLayoutGap.lt-md="10px">
            <div *ngFor="let job of paginatedJobs" class="grid-item">
              <mat-card class="card-image-action" routerLink="/job/{{job.id}}" style="cursor: pointer;">
                <div class="card-image-box">
                  <span class="card-overlay-logo">
                    <img src="{{imageBaseUrl + job.logo}}" width="50px" class="" alt="icon">
                  </span>
                  <div class="card-image-box-size">
                    <img mat-card-md-image class="image job-image" src="{{imageBaseUrl + job.image}}" alt="image"
                      style="border-top-left-radius: 20px; border-top-right-radius: 20px;">
                  </div>
                  <div class="card-hover-content">
                    <h5>{{ job.name }}</h5>
                    <span class="card-hover-subtitle">{{ job.jobType }} - {{ job.address }}</span>
                    <h4>{{ job.salary }} <span *ngIf="job.salary > 100">€/mois</span> <span
                        *ngIf="job.salary < 100">€/heure</span></h4>
                    <p class="card-hover-desc">{{ job.mission }}</p>
                    <div class="card-hover-bottom" fxLayout="row wrap" fxLayoutAlign="space-between center">
                      <button (click)="$event.stopPropagation();" routerLink="/job-application/{{job.id}}"
                        [disabled]="job.jobApplied == 1" mat-button class="btn-postuler">
                        <mat-icon matSuffix svgIcon="icon_postuler"></mat-icon>
                        <span>Postuler</span>
                      </button>
                      <div class="">
                        <a class="card-bottom-icon share-icon">
                          <mat-icon>share</mat-icon>
                        </a>
                        <a class="card-bottom-icon favorite-icon">
                          <mat-icon>favorite_border</mat-icon>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <mat-card-footer fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div class="card-footer-body">
                    <mat-card-title>{{ job.name }}</mat-card-title>
                    <mat-card-subtitle>{{ job.jobType }} - {{ job.address }}</mat-card-subtitle>
                  </div>
                  <button (click)="$event.stopPropagation();" mat-mini-fab color="secondary" class="btn-headphone-fab">
                    <mat-icon matSuffix svgIcon="headphone_audio"></mat-icon>
                  </button>
                </mat-card-footer>
              </mat-card>
            </div>
            <div #shuffleSizer class="sizer-element"></div>
          </div>
          <app-pagination [totalJobs]="jobsList" [jobsPerPage]="jobPerPage" (onPageChange)="displayActivePage($event)">
          </app-pagination>
        </div> -->
        <div infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="600" (scrolled)="onScroll()"
          *ngIf="gridSection">
          <ngx-masonry [options]="myOptions" [ordered]="true" style="margin: auto;" fxShow.xs="false" fxShow="true"
            fxShow.md="true" fxShow.sm="true">
            <div *ngFor="let job of paginatedJobs" ngxMasonryItem class="grid-item">
              <mat-card routerLink="/job/{{job.id}}" class="card-image-action"
                style="box-shadow: 0 5px 5px #3b3e4e45; cursor: pointer;" [style.width]="'217px'">
                <span class="overlay1">
                  <img class="masonry-compagny-logo" style="width: 80% !important;" src="{{ imageBaseUrl + job.logo }}">
                </span>
                <div class="card-image-box" [style.height]="job.cardHeight">
                  <span class="urgent-icon" *ngIf="job.urgent === 1">
                    <mat-icon svgIcon="icon_urgent"></mat-icon>
                  </span>
                  <div class="card-image-box-size">
                    <img mat-card-md-image class="job-image" [style.width]="'217px'" [style.height]="job.cardHeight"
                      src="{{ imageBaseUrl + job.image }}" alt="">
                  </div>
                  <div class="card-hover-content" style="text-align: left;" fxLayout="column"
                    fxLayoutAlign="space-between ">
                    <div>
                      <a style="text-decoration: none;" routerLink="/job/{{job.id}}">
                        <h5>{{ job.companyName === '' ? 'N/A' : job.companyName | uppercase}}</h5>
                      </a>
                      <span class="card-hover-subtitle">{{ job.jobType }} - {{ job.address }}</span>
                      <h4>{{ job.salary === '0.00' ? 'N/A' : job.salary}}
                        <span *ngIf="job.salary !== '0.00'">{{job.currency}}<!--/mois--></span>
                      </h4>
                      <p class="card-hover-desc" [innerHTML]="job.mission"></p>
                    </div>
                    <div>
                    </div>

                    <div class="card-hover-bottom" fxLayout="row" fxLayoutAlign="space-between end"
                      style="    padding-bottom: 10px;">
                      <button (click)="$event.stopPropagation();" fxLayout="row" fxLayoutAlign="space-around center"
                        mat-button class="btn-rounded btn-with-icon btn-primary-gradient"
                        routerLink="/job-application/{{job.id}}" [disabled]="job.jobApplied == 1">
                        <mat-icon svgIcon="icon_postuler" style="margin-right: 5px;"></mat-icon>
                        <span>Postuler</span>
                      </button>
                      <div class="share-like-btn">
                        <button mat-icon-button (click)="$event.stopPropagation(); openShareDialog(job)"
                          class="card-bottom-icon favorite-icon">
                          <mat-icon> share</mat-icon>
                        </button>
                        <button mat-icon-button (click)="$event.stopPropagation(); abbJobToFavorite(job.id)"
                          class="card-bottom-icon favorite-icon">
                          <mat-icon> {{job.jobFavorite == 1 ? 'favorite': 'favorite_border'}}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <mat-card-footer fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div class="card-footer-body">
                    <mat-card-title routerLink="/job/{{job.id}}">{{ job.jobName }}</mat-card-title>
                    <mat-card-subtitle>{{ job.jobType }} - {{ job.address }}</mat-card-subtitle>
                  </div>
                  <button mat-mini-fab color="secondary" class="btn-headphone-fab"
                    (click)="$event.stopPropagation();playAudio(job.name, job.audio, job)">
                    <img class="job-audio-icons" src="assets/image/picto_audio_medium.png" width="40px">
                  </button>
                </mat-card-footer>
              </mat-card>
            </div>
          </ngx-masonry>
          <!-- <div class="dw" fxShow="false" fxShow.xs="false" fxShow="true" fxShow.md="true" fxShow.sm="true"> -->

          <!-- </div> -->
        </div>

        <div infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="600" (scrolled)="onScroll()"
          *ngIf="gridSection">
          <div class="content mobile-dsn" fxShow="false" fxShow.xs="true" fxLayout="row wrap" fxLayoutGap fxFlexFill
            fxLayoutAlign="center top">
            <div fxLayout="row wrap" fxLayoutGap fxFlexFill fxLayoutAlign="center top">
              <div class="jobs" fxFlex="25%" fxFlex.xs="50%" fxFlex.sm="48%" fxFlex.md="25%"
                *ngFor="let job of paginatedJobs">
                <span class="card-overlay-logos">
                  <img width="30px" style="width: 70%" src="{{ imageBaseUrl + job.logo }}">
                  <!-- <img src="{{ imageBaseUrl + jobApply.logo }}" width="60px" class="" alt="icon"> -->
                </span>
                <!-- <img class="company-audio-icon" src="{{ imageBaseUrl + job.logo }}"> -->
                <mat-card class="job-card">
                  <div class="card-image-box-size">
                    <img mat-card-image src="{{ imageBaseUrl + job.image }}" alt="" class="mat-card-image">
                  </div>
                  <span class="urgent-icon" *ngIf="job.urgent === 1">
                    <mat-icon svgIcon="icon_urgent"></mat-icon>
                  </span>
                  <mat-card-content class="job-card-content">
                    <div class="job-actions">
                      <mat-grid-list class="title" cols="3">
                        <mat-grid-tile class="title-tile" [colspan]="2">
                          <div class="row" style="height: 60px">
                            <a routerLink="job/{{job.id}}" class="col-12">
                              <p class="job-title">{{ job.jobName }}</p>
                            </a>
                            <p class="col-12" style="font-size: 8px;margin-left: 18px;">{{
                              job.jobType }} -
                              {{ job.address }}
                            </p>
                          </div>
                        </mat-grid-tile>
                        <mat-grid-tile (click)="$event.stopPropagation()">
                          <img class="job-audio-icon" src="assets/image/picto_audio_medium.png"
                            (click)="$event.stopPropagation();playAudio(job.name, job.audio, job)">
                        </mat-grid-tile>
                      </mat-grid-list>
                    </div>
                    <div class="job-detail" routerLink="/job/{{job.id}}">
                      <div class="title">
                        <a routerLink="job/{{job.id}}">
                          <p class="job-title">{{ job.companyName === '' ? 'N/A' : job.companyName | uppercase}}</p>
                        </a>
                        <p class="job-adress">{{ job.jobType }} - {{ job.address }}</p>
                        <p style="display: flex;">{{ job.salary === '0.00' ? 'N/A' : job.salary}}<br>
                          <span *ngIf="job.salary !== '0.00'">{{job.currency}}<!--/mois--></span>
                        </p>
                        <p class="job-mission" [innerHTML]="job.mission"></p>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="postuler-bloc">
                          <button fxLayout="row" fxLayoutAlign="space-around center" fxFlex="60" mat-button
                            class="postuler" routerLink="job-application/{{job.id}}">
                            <mat-icon svgIcon="icon_postuler"></mat-icon>
                            <span class="postuler-title">Postuler</span>
                          </button>

                          <div fxFlex="50" fxLayout="row">
                            <button mat-icon-button (click)="$event.stopPropagation(); openShareDialog(job)"
                              class="card-bottom-icon">
                              <mat-icon class="favorite-icon"> share</mat-icon>
                            </button>
                            <button mat-icon-button (click)="$event.stopPropagation(); abbJobToFavorite(job.id)"
                              class="card-bottom-icon">
                              <mat-icon class="favorite-icon"> {{job.jobFavorite == 1 ?
                                'favorite':
                                'favorite_border'}}
                              </mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </div>
        </div>

        <!-- End Mosaic -->

        <!-- List -->
        <div infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="600" (scrolled)="onScroll()"
          *ngIf="listSection">
          <div class="list" *ngIf="listSection">
            <mat-card
              *ngFor="let job of paginatedJobs | filterBy: ['jobName','mission']: [f.querry.value, f.querry.value]"
              class="card-list-item p-0" fxLayout="row" fxLayoutAlign="start stretch" routerLink="/job/{{job.id}}"
              style="cursor: pointer;">
              <div class="card-list-image" fxFlex="30" fxFlex.xs="45">

                <img src="{{imageBaseUrl + job.image}}" width="220px" height="100%"
                  style="border-radius: 20px 0 0 20px; object-fit: cover;" class="" alt="icon">
                <span class="card-overlay-logo">
                  <img src="{{imageBaseUrl + job.logo}}" width="60px" class="" alt="icon">
                </span>
              </div>
              <mat-card-content fxFlex="70" fxFlex.xs="55" class="card-list-body" fxLayout="row wrap"
                fxLayoutAlign="space-between center">
                <div class="w-100" fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div class="media w-100">
                    <div class="media-left" fxHide.lt-md>
                      <mat-icon matSuffix svgIcon="category_nettoyage"></mat-icon>
                    </div>
                    <div class="media-body">
                      <mat-card-title>{{ job.jobName }}</mat-card-title>
                      <mat-card-subtitle>{{ job.jobType }} - {{ job.address }}</mat-card-subtitle>
                      <p class="card-body-text" fxHide.lt-md [innerHTML]="job?.mission"></p>
                    </div>
                    <div class="media-right">
                      <p class="item-price">{{ job.salary === '0.00' ? 'N/A' : job.salary}}<br>
                        <span *ngIf="job.salary !== '0.00'">{{job.currency}}<!--/mois--></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-100 card-list-footer" fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <!--<button mat-button color="primary" class="btn-contacter" fxHide.lt-md>
                    <mat-icon matSuffix svgIcon="icon_contact_white"></mat-icon>
                    <span>Contacter l'employeur</span>
                  </button>-->
                  <button mat-mini-fab color="secondary" class="btn-headphone-fab" fxShow.lt-md
                    (click)="$event.stopPropagation(); playAudio(job.name, job.audio, job)">
                    <mat-icon svgIcon="headphone_audio"></mat-icon>
                  </button>
                  <!-- <span style="color: #458076;"><span style="font-weight: 500;">Postulé le:</span>
                    {{job.applicationDate |
                    date:'dd-MM-yyyy'}}</span> -->
                  <div class="" fxLayout="row wrap">
                    <a *ngIf="job.jobFavorite == '0'" class="icon-link">
                      <mat-icon>favorite_border</mat-icon>
                    </a>

                    <a *ngIf="job.jobFavorite == '1'" class="icon-link">
                      <mat-icon class="icon-favorite">favorite</mat-icon>
                    </a>
                  </div>
                </div>
                <button (click)="$event.stopPropagation(); playAudio(job.name, job.audio, job)" mat-mini-fab
                  color="secondary" class="btn-headphone-fab" fxHide.lt-md>
                  <mat-icon svgIcon="headphone_audio"></mat-icon>
                </button>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <!-- End List -->

      </div>
      <!-- End Left side -->

      <!-- Right side -->
      <div class="right-sidebar" fxFlex="30" fxFlex.lt-lg="30" fxFlex.lt-md="100">
        <app-newsletter></app-newsletter>
      </div>
      <!-- End Right side -->
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!jobsService.isVoirCLick">
  <div style="height: 50px; background: linear-gradient(232deg,#bbedc2,#99e6a9 15%,#73c59c 75%,#458076);"></div>
  <!-- Job Category with Map -->
  <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" class="bg-white map-part">
    <!-- Left Side -->
    <div class="left-content" fxHide.lt-md fxFlex="40" fxFlex.lt-lg="50" fxFlex.lt-md="100">
      <div class="mb-4 border-bottom">
        <h5 class="mb-4 text-center">{{ categoryJobNumber }} emplois disponibles</h5>

        <div class="category-row map-category-row">
          <div class="bloc-category">
            <div fxFlex="134px" fxFlex.xs="110px" fxFlex.sm="110px" fxFlex.md="134px" class="bloc-mat-tile"
              *ngFor="let jobCategory of jobCategories">
              <button class="" mat-button routerLink="/job-category/{{jobCategory.id}}">
                <p> {{ jobCategory.name }} </p>
                <mat-icon [svgIcon]="jobCategory.image"></mat-icon>
              </button>
              <img class="category-audio-icon" src="assets/image/picto_audio_medium.png"
                (click)="playAudio(jobCategory.name, jobCategory.audio, jobCategory)">
            </div>
          </div>
        </div>

        <!-- Search Form -->
        <form class="search-form mb-4" [formGroup]="searchForm" (submit)="searchFunction()">
          <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="16px">
            <div class="" fxFlex="40" fxFlex.lt-md="100" *ngFor="let data of array | slice :0:1">
              <mat-form-field appearance="none" class="search-form-field">
                <input class="form-control" formControlName="querry" (keydown.enter)="searchFunction()" matInput
                  placeholder="Métier, compétence, mot clé" #div>
                <span class="icon-before">
                  <mat-icon svgIcon="icon_metier"></mat-icon>
                </span>
                <span class="icon-after">
                  <mat-icon matSuffix svgIcon="icon_micro" (click)="voiceSearch(0)"></mat-icon>
                </span>
              </mat-form-field>
            </div>
            <div class="" fxFlex="40" fxFlex.lt-md="100" *ngFor="let data of array | slice :1:array.length">
              <mat-form-field appearance="none" class="search-form-field">
                <input (keydown.enter)="searchFunction()" formControlName="city" class="form-control" matInput
                  placeholder="Saisissez une ville" #div>
                <span class="icon-before">
                  <mat-icon svgIcon="icon_localisation"></mat-icon>
                </span>
                <span class="icon-after">
                  <mat-icon matSuffix svgIcon="icon_micro" (click)="voiceSearch(1)"></mat-icon>
                </span>
              </mat-form-field>
            </div>
            <button (click)="getAllJobsByCategorie(true)" mat-fab color="secondary" class="search-icon btn-search">
              <mat-icon>search</mat-icon>
            </button>
          </div>
        </form>
      </div>


      <!-- App filter -->
      <app-filters class="filter-btn-oneline" fxShow="true" fxShow.xs="false" fxShow.sm="false"
        (jobResult)="getAllJobsByCategorie()" (urgentJob)="showUrgentJob($event)"
        (currentLocation)="getCurrentLatLong('data')">
      </app-filters>


      <!-- list -->
      <div infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="600" (scrolled)="onScroll()">
        <div class="list" *ngFor="let job of paginatedJobs; let i = index">
          <mat-card [ngClass]="{'marker-job': job.id === markerIconClikedValue}" class="card-list-item p-0"
            fxLayout="row" fxLayoutAlign="start stretch" (click)="updateMapView(job); focusOneJobs(job.id)"
            style="cursor: pointer;">
            <div class="card-list-image">
              <img routerLink="/job/{{job.id}}" src="{{ imageBaseUrl + job.image }}" width="220px" height="100%"
                style="border-radius: 20px 0 0 20px; object-fit: cover;" class="" alt="icon">
              <span class="card-overlay-logo">
                <img src="{{ imageBaseUrl + job.logo }}" width="50px" class="" alt="icon">
              </span>
            </div>
            <mat-card-content class="card-list-body" fxLayout="row wrap" fxLayoutAlign="space-between center">
              <div class="w-100" fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div class="media w-100">
                  <div class="media-body ml-0">
                    <mat-card-title>{{ job.jobName }}</mat-card-title>
                    <mat-card-subtitle>{{ job.jobType }} - {{ job.address }}
                    </mat-card-subtitle>
                  </div>
                  <div class="media-right">
                    <p class="item-price" *ngIf=" job.salary !== '0.00'">{{ job.salary === '0.00' ?
                      'N/A' :
                      job.salary}}<br>{{job.currency}}<!--/mois--></p>
                  </div>
                </div>
              </div>
              <button mat-mini-fab color="secondary" class="btn-headphone-fab"
                (click)="$event.stopPropagation();playAudio(job.name, job.audio, job)">
                <mat-icon svgIcon="headphone_audio"></mat-icon>
              </button>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    <!-- End Left Side -->

    <!-- Right Side -->
    <div fxFlex="60" fxFlex.lt-lg="50" fxFlex.lt-md="100" class="google-map-frame">
      <button (click)="backClick()" mat-button class="btn-close">
        <mat-icon>close</mat-icon>
      </button>
      <div class="map-transport-icon" *ngIf="isAGMDirection">
        <div [ngClass]="{'active' : mapTransportMode=='DRIVING'}" class="map-transport map-car">
          <div class="map-car-img" (click)="getVehicle('DRIVING')">
            <img mat-card-md-image src="assets/image/car.png" alt="image">
          </div>
          <label *ngIf="mapTransportMode=='DRIVING'">{{ mapDistanceDuration }}</label>
        </div>
        <div [ngClass]="{'active' : mapTransportMode=='TRANSIT'}" class="map-transport map-train">
          <div class="map-car-img" (click)="getVehicle('TRANSIT')">
            <img mat-card-md-image src="assets/image/train.png" alt="image">
          </div>
          <label *ngIf="mapTransportMode=='TRANSIT'">{{ mapDistanceDuration }}</label>
        </div>
        <div [ngClass]="{'active' : mapTransportMode=='WALKING'}" class="map-transport map-walking">
          <div class="map-car-img" (click)="getVehicle('WALKING')">
            <img mat-card-md-image src="assets/image/walking.png" alt="image">
          </div>
          <label *ngIf="mapTransportMode=='WALKING'">{{ mapDistanceDuration }}</label>
        </div>
        <div [ngClass]="{'active' : mapTransportMode=='BICYCLING'}" class="map-transport map-bicycle">
          <div class="map-car-img" (click)="getVehicle('BICYCLING')">
            <img mat-card-md-image src="assets/image/bicycle.png" alt="image">
          </div>
          <label *ngIf="mapTransportMode=='BICYCLING'">{{ mapDistanceDuration }}</label>
        </div>
      </div>
      <agm-map [(latitude)]="location.lat" [(longitude)]="location.lng" [(zoom)]="zoom" [disableDefaultUI]="true"
        [(fitBounds)]='location.viewport'>
        <agm-direction *ngIf="isAGMDirection" [travelMode]="mapTransportMode" [waypoints]="waypoints" [origin]="origin"
          [destination]="destination" [renderOptions]="renderOptions">
        </agm-direction>
        <agm-marker (markerClick)="focusOneJobs(job.id); clickedMarker(infoWindowsw)"
          [iconUrl]="'assets/image/icon_metier_map.svg'" *ngFor="let job of paginatedJobs"
          [latitude]="job.options.lat|number:'1.0-8'" [longitude]="job.options.lng|number:'1.0-8'">
          <agm-info-window [disableAutoPan]="false" #infoWindowsw>
            <mat-card class="card-image-action card-image-action-info-window"
              style="box-shadow: 0 5px 5px #3b3e4e45; cursor: pointer;" [style.width]="'217px'">
              <button class="gm-ui-hover-effects" (click)="removeItineraire()">X</button>
              <span class="overlay1">
                <img class="masonry-compagny-logo" style="width: 80% !important;" src="{{ imageBaseUrl + job.logo }}">
              </span>
              <div class="card-image-box">
                <span class="urgent-icon" *ngIf="job.urgent === 1">
                  <mat-icon svgIcon="icon_urgent"></mat-icon>
                </span>
                <div class="card-image-box-size">
                  <img mat-card-md-image class="job-image" [style.width]="'217px'" [style.height]="job.cardHeight"
                    src="{{ imageBaseUrl + job.image }}" alt="">
                </div>
                <div class="card-hover-content" style="text-align: left;" fxLayout="column"
                  fxLayoutAlign="space-between ">
                  <div>
                    <a style="text-decoration: none;" routerLink="/job/{{job.id}}">
                      <h5>{{ job.companyName === '' ? 'N/A' : job.companyName | uppercase}}</h5>
                    </a>
                    <span class="card-hover-subtitle">{{ job.jobType }} - {{ job.address }}</span>
                    <h4>{{ job.salary === '0.00' ? 'N/A' : job.salary}}
                      <span *ngIf=" job.salary !== '0.00'">{{job.currency}}<!--/mois--></span>
                    </h4>
                    <p class="card-hover-desc" [innerHTML]="job.mission"></p>
                  </div>
                  <div class="card-hover-bottom" fxLayout="row" fxLayoutAlign="space-between end"
                    style="padding-bottom: 10px;">
                    <button fxLayout="row" fxLayoutAlign="space-around center" mat-button
                      class="btn-rounded btn-with-icon btn-primary-gradient" routerLink="job-application/{{job.id}}"
                      [disabled]="job.jobApplied == 1">
                      <mat-icon svgIcon="icon_postuler" style="margin-right: 5px;"></mat-icon>
                      <span>Postuler</span>
                    </button>
                    <div class="share-like-btn">
                      <button mat-icon-button (click)="$event.stopPropagation(); openShareDialog(job)"
                        class="card-bottom-icon favorite-icon">
                        <mat-icon> share</mat-icon>
                      </button>
                      <button mat-icon-button (click)="$event.stopPropagation(); abbJobToFavorite(job.id)"
                        class="card-bottom-icon favorite-icon">
                        <mat-icon> {{job.jobFavorite == 1 ? 'favorite': 'favorite_border'}}</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div class="info-window-footer-button">
                    <div class="card-footer-body info-window-footer-body">
                      <button fxLayout="row" fxLayoutAlign="space-around center" (click)="getDirection(job)" mat-button
                        class="btn-rounded btn-with-icon map-draw-path">
                        <mat-icon svgIcon="icon_itinerary"></mat-icon><span>itinéraire</span>
                      </button>
                      <button routerLink="/job/{{job.id}}" class="btn-rounded btn-with-icon map-job-detail">
                        Voir l'offre <mat-icon svgIcon="icon_goTo"></mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </agm-info-window>
        </agm-marker>
        <agm-marker [iconUrl]="'assets/image/icon_localisation_map.svg'" [(latitude)]="location.marker.lat"
          [(longitude)]="location.marker.lng">
        </agm-marker>
      </agm-map>

      <div class="respo-map-search">
        <form [formGroup]="searchForm" class="login-form">
          <div class="respo-map-search-fields">
            <mat-form-field appearance="none" class="search-form-field">
              <input class="form-control" formControlName="querry" (keydown.enter)="searchWithoutCategory()" matInput
                placeholder="Métier, compétence, mot clé" #div>
              <span class="icon-before">
                <mat-icon svgIcon="icon_metier"></mat-icon>
              </span>
              <span class="icon-after">
                <mat-icon matSuffix svgIcon="icon_micro" (click)="voiceSearch(0)"></mat-icon>
              </span>
            </mat-form-field>
          </div>
        </form>
      </div>
      <app-filters fxShow="true" fxShow.xs="true" fxShow.sm="true" class="filter-btn-oneline-mobile"
        (jobResult)="getAllJobsByCategorie(true)" (currentLocation)="getCurrentLatLong('data')">
      </app-filters>

      <!-- list -->
      <div class="map-mobile-list-main" [ngClass]="{'is-conncet-fixed-joblist': userConnected}">
        <div class="list map-mobile-list" fxHide.gt-md *ngFor="let job of paginatedJobs; let i = index">
          <mat-card class="card-list-item p-0" fxLayout="row" fxLayoutAlign="start stretch">
            <div class="card-list-image">
              <img routerLink="/job/{{job.id}}" src="{{ imageBaseUrl + job.image }}" width="220px" height="100%"
                style="border-radius: 20px 0 0 20px; object-fit: cover;" class="" alt="icon">
              <span class="card-overlay-logo">
                <img src="{{ imageBaseUrl + job.logo }}" width="50px" class="" alt="icon">
              </span>
            </div>
            <mat-card-content class="card-list-body" fxLayout="row wrap" fxLayoutAlign="space-between center">
              <div class="w-100" fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div class="media w-100">
                  <div class="media-body ml-0">
                    <mat-card-title routerLink="/job/{{job.id}}">{{ job.jobName }}</mat-card-title>
                    <mat-card-subtitle (touchmove)="updateMapView(job); focusOneJobs(job.id);"
                      (click)="updateMapView(job); focusOneJobs(job.id);">
                      {{ job.jobType }} - {{ job.address }}
                    </mat-card-subtitle>
                  </div>
                </div>
              </div>
              <button mat-mini-fab color="secondary" class="btn-headphone-fab"
                (click)="$event.stopPropagation();playAudio(job.name, job.audio, job)">
                <mat-icon svgIcon="headphone_audio"></mat-icon>
              </button>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</ng-container>
