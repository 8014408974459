import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { JobsService } from './service/jobs/jobs.service';
import { environment } from 'src/environments/environment';
import { Platform } from '@angular/cdk/platform';
import {Subscription} from 'rxjs';
// import {NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent} from 'ngx-cookieconsent';
declare var FCMPlugin: any;
declare var navigator: any;
import {NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent} from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'gOtaf';

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private jobService: JobsService,
    private metaService: Meta,
    private plt: Platform,
    private ccService: NgcCookieConsentService
  ) {
    console.log(plt);

    this.matIconRegistry.addSvgIcon(
      'gotaf',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/gotaf.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'gotaf_color',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/gotaf_color.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'google',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/google.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/facebook.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_social_facebook.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_whatsapp',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_social_whatsapp.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_instagram',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_social_insta.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_social_linkedin.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_twitter',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_social_twitter.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_youtube',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_social_youtube.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_spotify',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_social_spotify.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_tiktok',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_social_tiktok.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_twitch',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_social_twitch.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'audio-yellow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/picto_audio_yellow.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'audio',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/picto_audio_medium.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'headphone_audio',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/picto_audio_seul.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'play',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_lecteur.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_publier',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_publier_white.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_login',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_login.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_register',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_register.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'gotaf_android_get',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_store_Google_Play.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'gotaf_apple_get',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_store_apple.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'category_nettoyage',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_cat_nettoyage.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'category_btp',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_cat_BTP.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'category_restauration',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_cat_restauration.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'category_commerce',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_cat_commerce.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'category_logistique',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_cat_logistique.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_metier',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_metier.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_micro',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_micro.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_localisation',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_localisation.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_recherche.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_save',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_save.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_urgent',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_urgence_map.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_tri',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_trier.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_last_jobs',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_annonces_urgentes_1.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_map',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_voir_carte.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_postuler',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_postuler.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_like',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_like.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_publish',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_publish.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_jobs',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_jobs.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_applied',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_applied.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_candidates',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_candidates.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_share',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_share.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_edit.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_blanckStar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_blanckStar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_star',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_star.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_plus.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_call',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_call.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_starBlanck',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_starBlanck.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_info',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_info.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_question',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_question.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_likeStar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_likeStar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_blackStar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_blackStar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_blackStarBlanck',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_blackStarBlanck.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_qualifications',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_qualifications.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_experiences',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_experiences.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_contacter',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_contacter.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_restauration',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_restauration.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_compare',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_compare.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_grid',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/grid_icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_list',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/list_icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_send',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/send_icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_contact_white',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/contact_icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_company',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_company.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_profile',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_profile.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_mission',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_mission.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_contact',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_contact.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_plan',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_plan.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'play_store',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/play-store.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'app_store',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/app-store.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_cross',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_cross.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_tick',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/icon_tick.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'link_arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/link-arrow.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_goTo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/goTo.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'sampleCv',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/sample_cv_audio.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_itinerary',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/itineraire.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'download',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/download.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'open_in_new_tab',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/open_in_new_black.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'resume_audio',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/image/resume_audio.svg')
    );
  }

  public onDeviceReady() {
    if ((this.plt.ANDROID || this.plt.IOS ) && (this.plt.BLINK == false && this.plt.EDGE == false && this.plt.FIREFOX == false && this.plt.SAFARI == false && this.plt.TRIDENT == false)) {

      FCMPlugin.onNotification(function(data) {
        //data.wasTapped == true means in Background :  Notification was received on device tray and tapped by the user.
        //data.wasTapped == false means in foreground :  Notification was received in foreground. Maybe the user needs to be notified.
        if (data.wasTapped) {
          //Notification was received on device tray and tapped by the user.
          //alert(JSON.stringify(data));
        } else {
          //alert(navigator.notification)
          navigator.notification.alert(
            data.aps.alert.body,  // message
            null,         // callback
            // alertDismissed,         // callback
            data.aps.alert.title,            // title
            'OK'                  // buttonName
        );
          // alert(JSON.stringify(data));
            //Notification was received in foreground. Maybe the user needs to be notified.
            
        }
      });
    }
  }
  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot) {
          if (event.snapshot.routeConfig && event.snapshot.routeConfig.path) {
            if (event.snapshot.routeConfig.path === 'job/:job_id') {
              this.jobService.getJob(event.snapshot.params.job_id).subscribe(data => {
                // this.metaService.removeTag('property= \'og:image\'');
                // this.metaService.removeTag('property= \'og:title\'');
                // this.metaService.removeTag('name= \'image\'');

                // this.metaService.addTag({ property: 'og:image', content: environment.imageBaseUrl + data.image });
                // this.metaService.addTag({ property: 'og:title', content: data.jobName });
                this.metaService.updateTag({ name: 'og:image', content: environment.imageBaseUrl + data.image });
                this.metaService.updateTag({ name: 'og:description', content: data.jobName });
              });
            } else {
              // this.metaService.removeTag('property= \'og:image\'');
              // this.metaService.removeTag('property= \'og:title\'');
              // this.metaService.removeTag('name= \'image\'');

              this.metaService.updateTag({ name: 'og:description', content: 'gOtaf: l\'emploi qui fait du bruit' });
              this.metaService.updateTag({ name: 'og:image', content: 'https://gotaf.fr/assets/image/login_left.jpg' });
            }
          }
        }
      }
    });
    if (this.plt.ANDROID || this.plt.IOS) {
      document.addEventListener("deviceready", this.onDeviceReady, false);
    }
    // this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });
    //
    // this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });
    //
    // this.initializeSubscription = this.ccService.initialize$.subscribe(
    //   (event: NgcInitializeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });
    //
    // this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
    //   (event: NgcStatusChangeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });
    //
    // this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });
    //
    // this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
    //   (event: NgcNoCookieLawEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        this.ccService.close(false);
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

  }

  ngOnDestroy(): void {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}
