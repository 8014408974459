import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { HttpClient } from '@angular/common/http';
import { HttpClientService } from '../http-client/http-client.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { User } from '../../model/user';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  private apiUrl: any;

  constructor(
    private router: Router,
    private http: HttpClientService
  ) {
    this.apiUrl = environment.backendApiUrl;
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  public login(email: string, phone: string, password: string, deviceToken: string, deviceType: string): Observable<any> {
    let headers:HttpHeaders = new HttpHeaders();
    headers.append('Content-Type',  'application/json');
    return this.http.post<any>(this.apiUrl + 'auth/login', { email, phone, password, deviceToken, deviceType }, headers)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
      }));
  }

  public register(data): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'auth/register', data)
      .pipe(map(user => {
        return user;
      }),
        switchMap(() => this.login(data.email, data.userPhone, data.password, data.deviceToken, data.deviceType)),
      );
  }

  public registerUserBySms(data): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'auth/registeruserbysms', data)
      .pipe(map(user => {
        // this.login(user.email, user.userPhone, user.password, user.deviceToken, user.deviceType);
        return user;
      }));
  }

  public logout(): void {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['/']);
  }

  public resetUserPassword(model: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'auth/reset', model);
  }

  public validateUserPhone(model: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'auth/validate', model);
  }

  public confirmUserPhone(model: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'auth/verify', model);
  }
}
