import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UserService } from '../../../service/user/user.service';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackService } from '../../../service/snack/snack.service';
import { JobCategoriesService } from '../../../service/jobCategories/job-categories.service';
import { JobCategory } from '../../../model/jobCategory';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { environment } from '../../../../environments/environment.prod';
import { Chart } from 'chart.js';
import { AudioService } from 'src/app/service/audio.service';
import { JobSinglePlayerService } from 'src/app/service/job-single-player/job-single-player.service';
import { Platform } from '@angular/cdk/platform';
import { ReccordAudioService } from '../../../service/reccord-audio/reccord-audio.service';
import { first } from 'rxjs/operators';
import { JobsService } from 'src/app/service/jobs/jobs.service';
import { Job } from 'src/app/model/job';
import { ReccordAudioComponent } from 'src/app/shared/reccord-audio/reccord-audio.component';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-user-cv-create',
  templateUrl: './user-cv-create.component.html',
  styleUrls: ['./user-cv-create.component.css']
})
export class UserCvCreateComponent implements OnInit, AfterViewInit {
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  @ViewChild(ReccordAudioComponent, {static : true}) reccordAudioComponent : ReccordAudioComponent;
  public cvForm: FormGroup;
  public questionList: any[] = ['Informations personelles', 'Informations générales', 'Qualifications', 'Expériences'];
  audioFile: Blob;
  public user: User;
  private selectedFile: File;
  choix: any[] = [];
  categorieChoix: any[] = [];
  public jobCategories: JobCategory[] = [];
  public experienceLevels: any[] = [];
  private categorieSelected: any[] = [];
  private userStored = JSON.parse(localStorage.getItem('user'));
  public selectedCategorie: any[] = [];
  private experience: any[] = [];
  private audio = new Audio();
  public audioUrl = 'assets/audio/user-cv-create.wav';
  public showPlayer = false;
  public cvAudioUrl = 'assets/audio/example-cv.wav';
  public imageBaseUrl: string = environment.imageBaseUrl;
  public countryCodeSelected = "option2";
  public job: any;
  public isFromExternUrl: number;

  test = true;
  val = true;
  show = false;
  state: any;
  chart;
  yAxe = [];
  xAxe = [];
  selectedIndex: number[] = [];
  data: any[] = [];
  dataset: any[] = [];


  constructor(
    private location: Location,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private snack: SnackService,
    private jobCategoriesService: JobCategoriesService,
    private audioService: AudioService,
    private singlePlayerSvc: JobSinglePlayerService,
    private plt: Platform,
    private audioRecordingService: ReccordAudioService,
    private jobService: JobsService,
    private title: Title,
    private meta: Meta
  ) {
    this.authenticationService.user.subscribe(x => this.user = x);
    this.singlePlayerSvc.getState().subscribe(state => {
      this.state = state;
      state.readableCurrentTime == "" ? state.readableCurrentTime = "00:00" : "";
    });
  }

  ngAfterViewInit(): void {
    console.log(this.reccordAudioComponent.blobUrl);
  }

  ngOnInit(): void {
    // const controlArray = this.jobCategories.map((c) => new FormControl(false));
    // controlArray[0].setValue(true);
    const returnUrl = new URLSearchParams(window.location.search).get('returnUrl');
    const returnUrlArray = returnUrl ? returnUrl.split('/') : [];
    const jobId = returnUrlArray.length > 2 && returnUrlArray[1] == "job-application" ? returnUrlArray[2] : null;
    this.isFromExternUrl = returnUrlArray.length > 3 && returnUrlArray[1] == "job-application" ? +returnUrlArray[3] : 0;

    this.job = null;
    if(jobId)
    {
      this.getJob(Number(jobId));
    }

    this.cvForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      audioFileCheck: ['', [Validators.required]],
      userPhone: ['', [Validators.required]],
      country: ['', Validators.required],
      experienceLevel: [''],
      categorie: this.formBuilder.array([]),
      summary: [''],
      experiences: [''],
      qualification: ['']
    });
    if (this.user && this.user.firstName && this.user.lastName) {
      this.cvForm.controls.firstName.setValue(this.user.firstName);
      this.cvForm.controls.lastName.setValue(this.user.lastName);
      this.cvForm.controls.country.setValue(this.user.country);
      this.cvForm.controls.userPhone.setValue(this.user.phone);
    }

    this.getExperienceLevel();
    this.getCategories();
  }

  public getJob(jobId): void {
    this.jobService.getJob(jobId).subscribe(data => {
      this.job = data;
      this.title.setTitle('Postuler | ' + this.job.jobName + ' chez ' + this.job.companyName);
      // this.meta.updateTag({ name: 'og:description', content: 'Postuler | ' + this.job.jobName + ' chez ' + this.job.companyName }, "name='og:description'");
      this.meta.updateTag({ name: 'description', content: 'Postuler | ' + this.job.jobName + ' chez ' + this.job.companyName }, "name='description'");

      this.playStream(this.imageBaseUrl + this.job.audio);
      if (this.job.audio === null) {
        return;
      } else {
        if (this.job.audio[0] === '[') {
          this.job.audio = JSON.parse(this.job.audio)[0].download_link;
        }
      }
      this.playStream(this.imageBaseUrl + this.job.audio);
    }, ignore => {
      this.job = new Job();
      // this.snack.error('error when getting Job details');
    });
  }
  public playStream(url): void {
    this.singlePlayerSvc.playStream(url).subscribe(events => {
    });
    this.pause();
  }

  public pause(): void {
    this.singlePlayerSvc.pause();
  }

  public play(): void {
    this.singlePlayerSvc.play();
  }

  public onSliderChangeEnd(change): void {
    this.singlePlayerSvc.seekTo(change.value);
  }

  public mute(): void {
    this.val = !this.val;
    this.singlePlayerSvc.mute(!this.val);
    this.show = false;
  }

  public onVolumeChangeEnd(change): void {
    // console.log(change);
    this.singlePlayerSvc.volume(change.value);
  }
  // tslint:disable-next-line:typedef



  public onSliderChangeExp(e): void {
    // console.log('value=', e.value);
    this.cvForm.controls.experienceLevel = e.value;
    this.experience = this.experienceLevels[e.value];
    this.updateChart(e.value - 1);
  }

  public onCheckboxChange(e: MatCheckboxChange): void {
    const checkArray: FormArray = this.cvForm.get('categorie') as FormArray;
    if (e.checked) {
      if (checkArray.controls.length < 2) {
        checkArray.push(new FormControl(e.source.value));
      } else {
        e.source.checked = false;
      }
    } else {
      let i = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value === e.source.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }

    this.categorieSelected = checkArray.value;
  }

  recordAudio(): void { }

  back(): void {
    this.location.back();
  }

  public submitInformations(): void  {
    if(!this.cvForm.controls.audioFileCheck.valid) {
      this.reccordAudioComponent.stopRecording();
      this.reccordAudioComponent.audioRecordingService.getReccordedBlob().subscribe(blob => {
        if(blob)
        {
          this.buildAudioFile(blob);
          this.submitFirstOnlyInformations();
        }
      });
    }
    else {
      this.submitFirstOnlyInformations();
    }
  }
  public submitFirstOnlyInformations(): void {

    if (this.cvForm.invalid) {
      return;
    }
    // if user is connected
    if(this.user)
    {
      this.sendResume(this.user);
    } else { // subscribe user and resume

      if(this.cvForm.value.country == "option1"){
        this.cvForm.controls.country.setValue('1');
      }
      else if(this.cvForm.value.country == "option2"){
        this.cvForm.controls.country.setValue('33');
      }
      else if(this.cvForm.value.country == "option3"){
        this.cvForm.controls.country.setValue('221');
      }
      else if(this.cvForm.value.country == "option4"){
        this.cvForm.controls.country.setValue('229');
      }
      else if(this.cvForm.value.country == "option5"){
        this.cvForm.controls.country.setValue('228');
      }
      else if(this.cvForm.value.country == "option6"){
        this.cvForm.controls.country.setValue('225');
      }
      this.cvForm.value.userPhone.replace(/ /g, '');

      let userSubscription = { "firstName": this.cvForm.value.firstName, "lastName": this.cvForm.value.lastName, "userPhone" : this.cvForm.value.userPhone, "country" : this.cvForm.value.country };

      this.authenticationService.registerUserBySms(userSubscription)
      .subscribe((user) => {
        // console.log(user);
        this.authenticationService.login("", user.userPhone, user.userPassword, "", "")
        .pipe(first())
        .subscribe((user) => {
          this.authenticationService.user.subscribe((x) => {
            this.user = x;
            this.sendResume(this.user);
          });
        });
      });

    }

  }

  public sendResume(userSession: any) {
    const fileName = userSession.phone  + //this.authenticationService.userValue.phone
      this.cvForm.controls.firstName.value
      + this.cvForm.controls.lastName.value + '.mp3';
    // console.log(JSON.stringify(this.selectedCategorie));
    const categorie = JSON.stringify(this.selectedCategorie);
    const level = JSON.stringify(this.experience);
    const sendData = new FormData();
    const info = new FormData();
    const returnUrl = new URLSearchParams(window.location.search).get('returnUrl');
    const returnUrlArray = returnUrl ? returnUrl.split('/') : [];
    const jobId = returnUrlArray.length > 2 && returnUrlArray[1] == "job-application" ? returnUrlArray[2] : null;
    info.append('firstName', this.cvForm.controls.firstName.value);
    info.append('lastName', this.cvForm.controls.lastName.value);
    sendData.append('firstName', this.cvForm.controls.firstName.value);
    sendData.append('lastName', this.cvForm.controls.lastName.value);
    sendData.append('experienceLevel', level);
    sendData.append('qualification', JSON.stringify(this.cvForm.controls.qualification.value));
    sendData.append('categories', categorie);
    sendData.append('summary', this.cvForm.controls.summary.value);
    sendData.append('userId', userSession.id.toString());
    sendData.append('jobIdToApply', jobId != null ? jobId: "");
    console.log(jobId);
    if ((this.plt.IOS || this.plt.ANDROID ) && (this.plt.BLINK == false && this.plt.EDGE == false && this.plt.FIREFOX == false && this.plt.SAFARI == false && this.plt.TRIDENT == false)) {
      if(this.audioRecordingService.isAudioUrl == false){
        return
      }
    }
    else{
      sendData.append('audio', this.audioFile, fileName);
      // console.log("fic audio");
      // console.log(this.audioFile);
    }
    sendData.append('experiences', JSON.stringify(this.cvForm.controls.experiences.value));
    this.userService.addResume(sendData).subscribe((data) => {
      // console.log(data);
      this.userService.updateUserInfos(info, this.authenticationService.userValue.id).subscribe((res) => {
        // console.log(res);

        this.userStored = JSON.parse(localStorage.getItem('user'));
        if(jobId == null)
        {
          this.userStored.hasAudio = true;
        }
        else if(!this.userStored.jobIdsHaveAudio.includes(+jobId))
        {
          this.userStored.jobIdsHaveAudio.push(+jobId);
        }

        this.userStored.firstName = this.cvForm.controls.firstName.value;
        this.userStored.lastName = this.cvForm.controls.lastName.value;
        localStorage.setItem('user', JSON.stringify(this.userStored));

        // console.log(this.route.snapshot.queryParams.returnUrl);
        const returnUrl = this.route.snapshot.queryParams.returnUrl ? this.route.snapshot.queryParams.returnUrl : '';
        //this.router.navigate([returnUrl]);

        this.router.navigateByUrl(returnUrl).then(r => '');

      });
    }, (err) => {
    });
    /*this.userStored.hasAudio = true;
    this.userStored.firstName = this.cvForm.controls.firstName.value;
    this.userStored.lastName = this.cvForm.controls.lastName.value;
    localStorage.setItem('user', JSON.stringify(this.userStored));

    console.log(this.route.snapshot.queryParams.returnUrl);
    const returnUrl = this.route.snapshot.queryParams.returnUrl || '';
    this.router.navigateByUrl(returnUrl).then(r => '');*/
  }

  public buildAudioFile(value: Blob): void {
    this.audioFile = value;
    this.cvForm.controls.audioFileCheck.setValue(true);
  }

  public onFileSelect(event: any): void {
    this.selectedFile = event.target.files[0];
    this.audioFile = this.selectedFile;
    this.cvForm.controls.audioFileCheck.setValue(true);
    // console.log(event);
  }

  public onClick(): void {
    const fileUpload = this.fileUpload.nativeElement;
    const fileName = this.user ? this.authenticationService.userValue.phone +
      this.authenticationService.userValue.firstName
      + this.authenticationService.userValue.lastName :
      this.cvForm.controls.userPhone.value +
      this.cvForm.controls.firstName.value +
      this.cvForm.controls.lastName.value;
    fileUpload.onchange = () => {
      // console.log(fileUpload.files[0]);
      this.audioFile = fileUpload.files[0];
      this.cvForm.controls.audioFileCheck.setValue(true);
    };
    // console.log(this.fileUpload.nativeElement.files);
    fileUpload.click();
  }

  private updateChart(element: any): void {
    for (let index = 0; index < this.experienceLevels.length; index++) {

      if (index === element) {
        this.chart.config.data.datasets[index].borderColor = "#f1ce49";
        this.chart.config.data.datasets[index].borderWidth = 2;
        this.experience = this.experienceLevels[element];
        this.cvForm.controls.experienceLevel = element + 1;
      } else {
        this.chart.config.data.datasets[index].borderColor = "rgba(0, 0, 0, 0.1)";
        this.chart.config.data.datasets[index].borderWidth = 0;
      }
    }
    this.chart.update();
  }

  private getExperienceLevel(): void {
    this.userService.getExperienceLevel().subscribe((data) => {
      // console.log(data);
      this.experienceLevels = data;
      this.experienceLevels.forEach(element => {
        this.yAxe.push(element.id);
        this.xAxe.push(element.name);
      });
      this.experience = this.experienceLevels[0];
      /*
      const ctx = document.getElementById('expChart');
      for (let i = 0; i < this.experienceLevels.length; i++) {
        if (i === 0) {
          this.data.push({
            label: this.xAxe[i],
            backgroundColor: '#b9edc1',
            borderColor: '#f1ce49',
            borderWidth: 2,
            data: [{
              x: 0,
              y: 0.5,
              r: 20,
            }]
          });
        } else {
          this.data.push({
            label: this.xAxe[i],
            backgroundColor: '#6dbc97',
            data: [{
              x: i,
              y: 0.5,
              r: 20,
            }]
          });
        }
      }
      this.chart = new Chart(ctx, {
        type: 'bubble',
        data: {
          datasets: this.data,
        },
        options: {
          onClick: (e) => {
            const element = this.chart.getElementAtEvent(e);
            if (element.length > 0) {
              data = element[0]._datasetIndex;
              this.updateChart(data);
            }
          },
          tooltips: {
            callbacks: {
              label: (t, d) => {
                return d.datasets[t.datasetIndex].label;
              }
            }
          },
          offset: true,
          responsive: true,
          autoSkip: false,
          layout: {
            padding: {
              right: 30,
              left: 0
            }
          },
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          title: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                max: 1,
                min: 0,
                stepSize: 0.5
              },
              display: false,
              gridLines: {
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                fontFamily: 'Montserrat, sans-serif',
                autoSkip: false,
                callback: (value) => {
                  return this.xAxe[value];
                },
                maxRotation: 0,
                beginAtZero: true
              },
              display: true,
              gridLines: {
                display: false
              }
            }]
          }
        }
      });*/
    }, (err) => {
      // console.log(err);
    });
  }

  public getCategories(): void {
    this.jobCategoriesService.getJobCategories().subscribe(data => {
      this.jobCategories = data;
      // add default jobcategory
      if(data.length > 0)
      {
        this.addToSelected(data[0], 1);
      }
      // console.log(this.jobCategories);
    }, ignore => {
      this.jobCategories = [];
      // this.snack.error('error when loading jobCategories');
    });
  }

  public addToSelected(p: any, id: number): void {
    if (this.selectedCategorie.length === 2) {
      const isFound = this.selectedCategorie.findIndex((items) => items.id === p.id);
      if (isFound !== -1) {
        this.selectedIndex.splice(isFound, 1);
        this.selectedCategorie.splice(isFound, 1);
        this.isSelect(id);
      }
      return;
    } else
      if (this.selectedCategorie.length === 0) {
        this.selectedCategorie.push(p);
        this.selectedIndex.push(id);
        this.isSelect(id);
      }
      else {
        const isFound = this.selectedCategorie.findIndex((items) => items.id === p.id);
        if (isFound === -1) {
          this.selectedCategorie.push(p);
          this.selectedIndex.push(id);
          this.isSelect(id);
        } else {
          this.selectedIndex.splice(isFound, 1);
          this.selectedCategorie.splice(isFound, 1);
          this.isSelect(id);
        }
      }
  }

  public isSelect(id: number): boolean {
    const isFound = this.selectedIndex.findIndex((items) => items === id);
    if (isFound === -1) {
      return false;
    } else {
      return true;
    }
  }

  public stop(show: boolean): void {
    this.audioService.stop();
    this.showPlayer = false;
  }

  public playRecord(file: any): void {
    this.playAudio(file.changingThisBreaksApplicationSecurity);
  }

  public playAudio(url): void {
    // tslint:disable-next-line: no-unused-expression
    this.showPlayer = true;

    this.audioService.playStream(url).subscribe(events => {
    });
  }

  // public playStream(url): void {
  //  this.audioService.playStream(url).subscribe(events => {
  //  });
  //}

  public closePlayer(): void {
    this.audioService.stop();
    this.showPlayer = false;
  }

}
