import { Component, OnInit, Inject } from '@angular/core';
import swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { UserService } from '../../../service/user/user.service';
import { AuthenticationService } from '../../../service/authentication/authentication.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackService } from '../../../service/snack/snack.service';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Component({
  selector: 'app-confirm-code',
  templateUrl: './confirm-code.component.html',
  styleUrls: ['./confirm-code.component.css']
})
export class ConfirmCodeComponent implements OnInit {
  public code: string;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  // tslint:disable-next-line: max-line-length
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };
  constructor(
    public dialogRef: MatDialogRef<ConfirmCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private snack: SnackService,
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  public submit(): void {
    this.loading = true;
    this.authenticationService.confirmUserPhone({ country: this.data.country, phone: this.data.userPhone, code: this.code }).subscribe(() => {
      this.dialogRef.close();
      this.loading = false;
      swal.fire('', 'Numéro de télephone vérifié avec succès', 'success');
      this.authenticationService.register(this.data)
        .pipe(first())
        .subscribe({
          next: () => {
            // get return url from query parameters or default to home page
            if (this.data.role_id === '2') {
              const returnUrl = this.route.snapshot.queryParams.returnUrl || '';
              this.router.navigateByUrl(returnUrl).then(r => '');
            } else {
              const returnUrl = this.route.snapshot.queryParams.returnUrl || '/company-job-applied';
              this.router.navigateByUrl(returnUrl);
            }
            this.userService.sendRegisterConfirmSms({ country: this.data.country, phone: this.data.userPhone, password: this.data.password }).subscribe(() => {
              this.snack.info('Bienvenue sur gotaf!'); // TODO: add user firstname on message
            });
          },
          error: (error) => {
            this.snack.error('Une erreur s\'est produite lors de l\'ajout de l\'utilisateur.');
          }
        });
    }, (e) => {
      swal.fire('', 'Code incorecte', 'error');
      this.loading = false;
    });
    // swal.fire('', 'Numéro de télephone vérifié avec succès', '');
    // swal.showLoading();
  }

}
