import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { HttpClientService } from '../http-client/http-client.service';
import { environment } from '../../../environments/environment';
import { Observable, of, fromEvent } from 'rxjs';
import { Job } from '../../model/job';
import { JobCategory } from '../../model/jobCategory';
import { AuthenticationService } from '../authentication/authentication.service';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  public isVoirCLick = true;
  public mapButtonclicked = false;
  private apiUrl: any;
  private imageBaseUrl: string = environment.imageBaseUrl;


  constructor(
    private http: HttpClientService,
  ) {
    this.apiUrl = environment.backendApiUrl;
  }

  public getLastJobs(userId: number, options = {page: 1}): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + 'jobs/last?userId=' + userId + '&page=' + options.page)
      .pipe(
        map((data: any) => {
          data['lastJobs'].map((job: any) => {
            const marker: any = {};
            const address = job.address;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({
              'address': address
            }, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                for (let i = 0; i < results[0].address_components.length; i++) {
                  const types = results[0].address_components[i].types;
                  if (types.indexOf('locality') !== -1) {
                    marker.address_level_2 = results[0].address_components[i].long_name;
                  }
                  if (types.indexOf('country') !== -1) {
                    marker.address_country = results[0].address_components[i].long_name;
                  }
                  if (types.indexOf('postal_code') !== -1) {
                    marker.address_zip = results[0].address_components[i].long_name;
                  }
                  if (types.indexOf('administrative_area_level_1') !== -1) {
                    marker.address_state = results[0].address_components[i].long_name;
                  }
                }
                if (results[0].geometry.location) {
                  marker.lat = Number(results[0].geometry.location.lat());
                  marker.lng = Number(results[0].geometry.location.lng());
                  marker.viewport = results[0].geometry.viewport;
                  marker.jobId = job.id;
                  marker.options = { icon: '../../../../assets/image/icon_metier_map.svg' };
                }
              } else {
              }
            });
            job.options = marker;
          });
          return data;
        })
      );
  }

  public getUrgentJobs(userId: number): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + 'jobs/urgent?userId=' + userId).pipe(
      map((data: any) => {
        data['urgentJobs'].map((job: any) => {
          const marker: any = {};
          const address = job.address;
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({
            'address': address
          }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              for (let i = 0; i < results[0].address_components.length; i++) {
                const types = results[0].address_components[i].types;
                if (types.indexOf('locality') !== -1) {
                  marker.address_level_2 = results[0].address_components[i].long_name;
                }
                if (types.indexOf('country') !== -1) {
                  marker.address_country = results[0].address_components[i].long_name;
                }
                if (types.indexOf('postal_code') !== -1) {
                  marker.address_zip = results[0].address_components[i].long_name;
                }
                if (types.indexOf('administrative_area_level_1') !== -1) {
                  marker.address_state = results[0].address_components[i].long_name;
                }
              }
              if (results[0].geometry.location) {
                marker.lat = Number(results[0].geometry.location.lat());
                marker.lng = Number(results[0].geometry.location.lng());
                marker.viewport = results[0].geometry.viewport;
                marker.jobId = job.id;
                marker.options = { icon: '../../../../assets/image/icon_metier_map.svg' };
              }
            } else {
            }
          });
          job.options = marker;
        });
        return data;
      })
    );
  }

  /**
   * get all jobs list
   */
  public getJobs(userId: number, options=  {page: 1}): Observable<Job[]> {
    return this.http.get<Job[]>(this.apiUrl + 'jobs?page=' + options.page)
      .pipe(
        map((data: any) => {
          data['jobs'].map((job: any) => {
            const marker: any = {};
            const address = job.address;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({
              'address': address
            }, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                for (let i = 0; i < results[0].address_components.length; i++) {
                  const types = results[0].address_components[i].types;
                  if (types.indexOf('locality') !== -1) {
                    marker.address_level_2 = results[0].address_components[i].long_name;
                  }
                  if (types.indexOf('country') !== -1) {
                    marker.address_country = results[0].address_components[i].long_name;
                  }
                  if (types.indexOf('postal_code') !== -1) {
                    marker.address_zip = results[0].address_components[i].long_name;
                  }
                  if (types.indexOf('administrative_area_level_1') !== -1) {
                    marker.address_state = results[0].address_components[i].long_name;
                  }
                }
                if (results[0].geometry.location) {
                  marker.lat = Number(results[0].geometry.location.lat());
                  marker.lng = Number(results[0].geometry.location.lng());
                  marker.viewport = results[0].geometry.viewport;
                  marker.jobId = job.id;
                  marker.options = { icon: '../../../../assets/image/icon_metier_map.svg' };
                }
              } else {
              }
            });
            job.options = marker;
          });
          return data;
        })
      );
  }
  public searchJobs(userId: number, filters = {city: '', query: ''}, options=  {page: 1}): Observable<Job[]> {
    return this.http.get<Job[]>(this.apiUrl + `jobs/search?page=${options.page}&ville=${filters.city}&name=${filters.query}`)
      .pipe(
        map((data: any) => {
          data['jobs'].map((job: any) => {
            const marker: any = {};
            const address = job.address;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({
              'address': address
            }, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                for (let i = 0; i < results[0].address_components.length; i++) {
                  const types = results[0].address_components[i].types;
                  if (types.indexOf('locality') !== -1) {
                    marker.address_level_2 = results[0].address_components[i].long_name;
                  }
                  if (types.indexOf('country') !== -1) {
                    marker.address_country = results[0].address_components[i].long_name;
                  }
                  if (types.indexOf('postal_code') !== -1) {
                    marker.address_zip = results[0].address_components[i].long_name;
                  }
                  if (types.indexOf('administrative_area_level_1') !== -1) {
                    marker.address_state = results[0].address_components[i].long_name;
                  }
                }
                if (results[0].geometry.location) {
                  marker.lat = Number(results[0].geometry.location.lat());
                  marker.lng = Number(results[0].geometry.location.lng());
                  marker.viewport = results[0].geometry.viewport;
                  marker.jobId = job.id;
                  marker.options = { icon: '../../../../assets/image/icon_metier_map.svg' };
                }
              } else {
              }
            });
            job.options = marker;
          });
          return data;
        })
      );
  }

  /**
   * get all jobs by category
   * @param jobCategory
   */
  // public getCategoryJobs(jobCategory: JobCategory): Observable<Job[]> {
  //   return this.http.get<Job[]>(this.apiUrl + '/jobs/category/' + jobCategory.id);
  // }

  /**
   * get all jobs by category
   * @param jobCategoryId
   */

  public getCategoryJobs(jobCategoryId: number): Observable<any[]> {
    return this.http.get<Job[]>(this.apiUrl + '/jobCategory/' + jobCategoryId);
  }

  public getSimilarJobs(jobId: number, categoryId: number): Observable<any[]> {
    return this.http.get<Job[]>(this.apiUrl + 'jobs/similar/' + categoryId + '/' + jobId);
  }

  /**
   * get job detail
   * @param jobId
   */
  public getJob(jobId: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'job/' + jobId)
      .pipe(map(jobs => {
        return jobs['job'];
      }));
  }

  public getJobTypes(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'jobTypes')
      .pipe(map(jobs => {
        return jobs['jobTypes'];
      }));
  }

  /**
   * add job
   * @param job
   */
  public addJob(job: any): Observable<object> {
    return this.http.post(this.apiUrl + 'job/add', job);
  }

  public searchCity(cityName): Observable<object> {
    return this.http.get(`${this.apiUrl}city/search/${cityName}`);
  }

  /**
   * update job
   * @param jobId
   * @param job
   */
  public updateJob(jobId, job: any): Observable<object> {
    return this.http.post(this.apiUrl + 'job/' + jobId + '/update', job);
  }

  /**
   * delete job
   * @param jobId
   */
  public deleteJob(jobId: number): Observable<object> {
    return this.http.delete(this.apiUrl + '/jobs/delete/' + jobId);
  }

  /**
   * get applied jobs per user
   */
  public getJobApplied($userId): Observable<any[]> {
    return this.http.get<any[]>(environment.backendApiUrl + 'job/applied/' + $userId)
      .pipe(map(jobs => {
        return jobs['jobApplied'];
      }));
  }

  /**
   * get favorite jobs per user
   */
  public getJobFavorite($userId): Observable<any[]> {
    return this.http.get<any[]>(environment.backendApiUrl + 'job/favorite/' + $userId)
      .pipe(map(jobs => {
        return jobs['jobFavorite'];
      }));
  }

  /**
   * get job questions per job
   *
   */
  public getJobQuestion($jobId): Observable<any> {
    return this.http.get<any>(environment.backendApiUrl + 'job/question/' + $jobId)
      .pipe(map(jobs => {
        console.log(jobs);
        return jobs;
      }));
  }

  /**
   * add job
   * @param data
   * @param userId
   */
  public jobApplication(data: any, userId: number): Observable<any> {
    return this.http.post(this.apiUrl + `job/application/${userId}`, data);
  }

  /**
   * add job
   * @param jobId
   * @param userId
   */
  public addJobToFavorite(jobId: number, userId: number): Observable<any> {

    return this.http.post(this.apiUrl + `job/addFavorite/${userId}`, { jobId }).pipe(map(result => {
      return result;
    }));
  }

  public saveUserSearch(data): Observable<any> {
    return this.http.post(this.apiUrl + 'savedSearch', { data }).pipe(map(result => {
      return result;
    }));
  }
}
interface ISize { width: number; height: number; }
