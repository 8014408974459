import { UserService } from '../../../service/user/user.service';
import { environment } from '../../../../environments/environment.prod';
import { User } from '../../../model/user';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { AudioFileService } from '../../../service/audiofile.service';
import { AudioService } from '../../../service/audio.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JobCategory } from '../../../model/jobCategory';
import { JobCategoriesService } from '../../../service/jobCategories/job-categories.service';
import { SnackService } from '../../../service/snack/snack.service';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { Role } from '../../../model/role';
import { Platform } from '@angular/cdk/platform';
import { ReccordAudioService } from '../../../service/reccord-audio/reccord-audio.service';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  // public user =
  // { id: 1, companyId: 'Jean-Muriel Azonhoume 1', username: 'Mon offre 1', firstname: 'Mon ', lastname: 'offre',
  // email: 'Monoffre@gmail.com',
  // phone: '988574969552', image: 'votre-offre.jpg', address: 'Gopal Palace', experience: '97' };
  public user: User;
  public imageBaseUrl: string = environment.imageBaseUrl;
  public userResume;
  public audioSource: string;
  public categories: any[] = [];
  public cvForm: FormGroup;
  public audioFile: File;
  public chart;
  public yAxe = [];
  public xAxe = [];
  private experience: any[] = [];
  data: any[] = [];
  dataset: any[] = [];

  public jobCategories: JobCategory[] = [];
  public experiencesLevel: any[] = [];
  public qualification;
  public experienceSelect;
  public changeAudio = false;
  selectedIndex: number[] = [];
  public selectedCategorie: any[] = [];
  public changeCategorie = false;
  public changeExperience = false;
  public showCategoryExperience: boolean[] = [false, false, false, false];
  public size: number;

  constructor(
    private authenticationService: AuthenticationService,
    private userProfileService: UserService,
    private audioService: AudioService,
    private audioFileService: AudioFileService,
    private formBuilder: FormBuilder,
    private jobCategoriesService: JobCategoriesService,
    private snack: SnackService,
    private router: Router,
    private plt: Platform,
    private reccordAudioService: ReccordAudioService

  ) {
    this.authenticationService.user.subscribe(x => this.user = x);
    this.user = JSON.parse(localStorage.getItem('user'));
    console.log('user', this.user);
  }

  ngOnInit(): void {
    this.getUserResume();
    // this.getExperienceLevel();
    this.getCategories();
    this.cvForm = this.formBuilder.group({
      image: [''],
      firstName: [''],
      lastName: [''],
      experienceLevel: [''],
      experienceLevelId: [''],
      summary: [''],
      qualification: [''],
      qualificationUniversity: [''],
      qualificationDescription: [''],
      JobCategoryId: [''],
      address: [''],
    });
  }

  public getUserResume(): void {
    this.userProfileService.getResume(this.user.id).subscribe((data) => {
      this.userResume = data;
      if(data != null){
        this.userResume.categories = JSON.parse(data.categories);
        this.userResume.experienceLevel = JSON.parse(data.experienceLevel);
        this.userResume.qualification = JSON.parse(data.qualification);
        this.qualification = this.userResume.qualification;
        if(data.experience != null){
          data.experience = data.experience.replace(/\\/g, '');
          this.userResume.experience = JSON.parse(data.experience);
        }
      }
        if (data != null && this.userResume.summary != null && this.userResume.summary != "") {
          var qualification = "";
          var university = "";
          var description = "";
          if(this.userResume.qualification && this.userResume.qualification.length > 0){
            qualification =  this.userResume.qualification[0].qualification;
            university =  this.userResume.qualification[0].university;
            description =  this.userResume.qualification[0].description;
          }
          this.cvForm = this.formBuilder.group({
            image: [this.user.image],
            firstName: [this.user.firstName],
            lastName: [this.user.lastName],
            experienceLevel: [this.userResume.experienceLevel.name],
            experienceLevelId: [this.userResume.experienceLevel.id],
            summary: [this.userResume?.summary],
            qualification: [qualification],
            qualificationUniversity: [university],
            qualificationDescription: [description],
            JobCategoryId: ['this.userResume?.JobCategoryId'],
            address: [this.user.address],
          });
        } else {
          this.cvForm = this.formBuilder.group({
            image: [this.user.image],
            firstName: [this.user.firstName],
            lastName: [this.user.lastName],
            experienceLevel: [],
            experienceLevelId: [],
            summary: ['summary'],
            qualification: [],
            qualificationUniversity: [],
            qualificationDescription: [],
            JobCategoryId: ['this.userResume?.JobCategoryId'],
            address: [this.user.address],
          });
        }
        this.getExperienceLevel();

      // this.userResume.categories = JSON.parse(data[0].categories);
      // console.log(this.userResume);
    });
  }

  public playAudio(audioName: string, audioToPlay: string, job: any): void {
    // this.audioFileService.addToPlayer(job);
    // this.audioSource = audioToPlay;
    // this.audioService.getAudioFile(audioName);
    this.audioService.playStream(this.imageBaseUrl + audioToPlay).subscribe(events => {
    });
  }

  public assignValue(e, libele): void {
    const index = Number(libele.slice(-1));
    const data = libele.slice(0, -1);
    if (index >= 0 && index < 4) {
      this.userResume.experience[index][data] = e;
      console.log(this.userResume.experience);
    } else {
      switch (libele) {
        case 'experienceLevel':
          this.experienceSelect = Number(e);
          this.cvForm.get(libele).setValue(this.experiencesLevel[e - 1].name);
          this.cvForm.controls.experienceLevelId.setValue(this.experiencesLevel[e - 1].id);
          break;
        default:
          this.cvForm.get(libele).setValue(e);
          console.log(this.cvForm.value);
      }
    }
  }

  public getCategories(): void {
    this.jobCategoriesService.getJobCategories().subscribe(data => {
      this.jobCategories = data;
      // console.log(this.jobCategories);
    }, ignore => {
      this.jobCategories = [];
      // this.snack.error('error when loading jobCategories');
    });
  }

  // private getExperienceLevel(): void {
  //  this.userProfileService.getExperienceLevel().subscribe( data => {
  //    this.experiencesLevel = data;
  //  });
  // }

  public submit(): void {
    const sendData = new FormData();
      if (this.changeAudio && this.audioFile) {
        sendData.append('audio', this.audioFile);
      }
    if (this.experience) {
      sendData.append('experienceLevel', JSON.stringify(this.experience));
    } else {
      if(this.userResume != null && this.userResume.experienceLevel != null && this.userResume.experienceLevel >= 0 ){
        sendData.append('experienceLevel', JSON.stringify(this.userResume.experienceLevel));
      }
    }
    if (this.changeCategorie && this.selectedCategorie.length >= 1) {
      sendData.append('categories', JSON.stringify(this.selectedCategorie));
    } else {
      if(this.userResume != null && this.userResume.categories != null && this.userResume.categories.length >= 0 ){
        sendData.append('categories', JSON.stringify(this.userResume.categories));
      }
    }
    if(this.qualification != null && this.cvForm.controls.qualification.value != null){
      this.qualification[0].qualification = this.cvForm.controls.qualification.value;
    }
    if(this.qualification != null && this.cvForm.controls.qualificationUniversity.value != null){
      this.qualification[0].university = this.cvForm.controls.qualificationUniversity.value;
    }
    if(this.qualification != null && this.cvForm.controls.qualificationDescription.value != null){
      this.qualification[0].description = this.cvForm.controls.qualificationDescription.value;
    }
    sendData.append('summary', this.cvForm.controls.summary.value);

    if(this.userResume != null && this.userResume.experience != null && this.userResume.experience.length >= 1){
      sendData.append('experience', JSON.stringify(this.userResume.experience));
    }
    
    if(this.qualification != null && this.qualification.length >= 1){
      sendData.append('qualification', JSON.stringify(this.qualification));
    }

    this.userProfileService.updateResume(this.user.id, sendData).subscribe((data) => {
      this.reccordAudioService.stopCapturing('cv',this.user.id);
      console.log({data:data});
       this.snack.info('CV bien modifié !');
      this.router.navigateByUrl('');
      // this.back();
    }, (err) => {
      // console.log(err);
    });
  }

  public buildAudioFile(value: File): void {
    this.audioFile = value;
  }

  public playRecordFile(file: any): void {
    if (this.plt.ANDROID || this.plt.IOS) {
      this.playAudioFile(file);
    }
    else {
      this.playAudioFile(file.changingThisBreaksApplicationSecurity);
    }
  }

  public playAudioFile(url): void {
    this.audioService.playStream(url).subscribe(events => {
    });
  }

  public stopRec(show: boolean): void {
    this.audioService.stop();
  }

  public deleteRecordFile(): void {
    this.audioService.stop();
    this.audioFile = null;
    this.changeAudio = false;
    this.audioService.playStream(this.imageBaseUrl + this.userResume.audio).subscribe(events => {
    });
    this.audioService.pause();
  }

  public addToSelected(p: any, id: number): void {
    if (this.selectedCategorie.length === 2) {
      const isFound = this.selectedCategorie.findIndex((items) => items.id === p.id);
      if (isFound !== -1) {
        this.selectedIndex.splice(isFound, 1);
        this.selectedCategorie.splice(isFound, 1);
        this.isSelect(id);
      }
      return;
    } else
      if (this.selectedCategorie.length === 0) {
        this.selectedCategorie.push(p);
        this.selectedIndex.push(id);
        this.isSelect(id);
      }
      else {
        const isFound = this.selectedCategorie.findIndex((items) => items.id === p.id);
        if (isFound === -1) {
          this.selectedCategorie.push(p);
          this.selectedIndex.push(id);
          this.isSelect(id);
        } else {
          this.selectedIndex.splice(isFound, 1);
          this.selectedCategorie.splice(isFound, 1);
          this.isSelect(id);
        }
      }
  }

  public isSelect(id: number): boolean {
    const isFound = this.selectedIndex.findIndex((items) => items === id);
    if (isFound === -1) {
      return false;
    } else {
      return true;
    }
  }

  public selectExpeCategory(category: any, index: number): void {
    this.userResume.experience[index].jobCategory = category;
    this.showCategoryExperience[index] = false;
  }

  public playAudioCat(url): void {
    this.audioService.playStream(url).subscribe(events => {
    });
  }

  public onSliderChangeExp(e): void {
    console.log('value=', e.value);
    this.cvForm.controls.experienceLevelId = e.value;
    this.experience = this.experiencesLevel[e.value];
    this.updateChart(e.value - 1);
  }

  private getExperienceLevel(): void {
    this.userProfileService.getExperienceLevel().subscribe((data) => {
      console.log(data);
      this.experiencesLevel = data;
      this.experiencesLevel.forEach(element => {
        this.yAxe.push(element.id);
        this.xAxe.push(element.name);
      });
      const ctx = document.getElementById('expChart');
      if(this.userResume != null && this.userResume.experienceLevel != null){
        this.experience = this.userResume.experienceLevel;
        console.log('exp level', this.userResume.experienceLevel?.id);
      }
      for (let i = 0; i < this.experiencesLevel.length; i++) {
        if (i === Number(this.userResume.experienceLevel?.id) - 1) {
          this.data.push({
            label: this.xAxe[i],
            backgroundColor: '#b9edc1',
            borderColor: '#f1ce49',
            borderWidth: 2,
            data: [{
              x: i,
              y: 0,
              r: 10,
            }]
          });
        } else {
          this.data.push({
            label: this.xAxe[i],
            backgroundColor: '#6dbc97',
            data: [{
              x: i,
              y: 0,
              r: 10,
            }]
          });
        }
      }
      this.chart = new Chart(ctx, {
        type: 'bubble',
        data: {
          datasets: this.data,
        },
        options: {
          onClick: (e) => {
            const element = this.chart.getElementAtEvent(e);
            if (element.length > 0) {
              data = element[0]._datasetIndex;
              this.updateChart(data);
            }
          },
          tooltips: {
            callbacks: {
              label: (t, d) => {
                return d.datasets[t.datasetIndex].label;
              }
            }
          },
          offset: true,
          responsive: true,
          autoSkip: false,
          layout: {
            padding: {
              right: 30,
              left: 0,
              top: 0
            }
          },
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          title: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                max: 0,
                min: 0,
                stepSize: 0
              },
              display: false,
              gridLines: {
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                fontFamily: 'Montserrat, sans-serif',
                autoSkip: false,
                callback: (value) => {
                  return this.xAxe[value];
                },
                maxRotation: 0,
                beginAtZero: true,
                fontColor: "white",
                fontSize: 10
              },
              display: true,
              gridLines: {
                display: false
              }
            }]
          }
        }
      });
    }, (err) => {
      // console.log(err);
    });
  }

  private updateChart(element: any): void {
    for (let index = 0; index < this.experiencesLevel.length; index++) {

      if (index === element) {
        this.chart.config.data.datasets[index].borderColor = "#f1ce49";
        this.chart.config.data.datasets[index].borderWidth = 2;
        this.experience = this.experiencesLevel[element];
        console.log(this.experience);
        this.cvForm.controls.experienceLevelId = element + 1;
      } else {
        this.chart.config.data.datasets[index].borderColor = "rgba(0, 0, 0, 0.1)";
        this.chart.config.data.datasets[index].borderWidth = 0;
      }
    }
    this.chart.update();
  }

  public isCompany(): boolean {
    return this.user && this.user.role === Role.Company;
  }


  public changeCategorieStatut() {
    this.changeCategorie = !this.changeCategorie;
  }

  public changeAudioStatut() {
    this.changeAudio = !this.changeAudio;
  }
}
