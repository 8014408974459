import { Component, OnInit } from '@angular/core';
import { User } from '../../../model/user';
import { environment } from '../../../../environments/environment.prod';
import { AuthenticationService } from '../../../service/authentication/authentication.service';
import { FormGroup, FormBuilder, NgForm, Validators } from '@angular/forms';
import { UserService } from '../../../service/user/user.service';
import { SnackService } from '../../../service/snack/snack.service';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css', '../../../shared/css/user-modify-infos.style.css']
})
export class UserDashboardComponent implements OnInit {
  public user: User;
  public userInformation: FormGroup;
  public imageBaseUrl: string = environment.imageBaseUrl;
  userAvatar: File;
  url = '';
  userStored = JSON.parse(localStorage.getItem('user'));
  public userResume;
  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private snack: SnackService
  ) {
    this.authenticationService.user.subscribe(x => this.user = x);
    this.user = this.userStored;
    console.log(this.user);
  }

  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  phoneNumberPattern = '^[0-9 ]*$';
  firstNamePettern = '^[a-zA-Z ]*';
  lastNamePettern = '^[a-zA-Z ]*';
  isFirstBlockErrorShow = false;
  isSecondBlockErrorShow = false;
  ngOnInit(): void {
    this.userInformation = this.formBuilder.group({
      firstName: ['', [Validators.pattern(this.firstNamePettern), Validators.required]],
      lastName: ['', [Validators.pattern(this.lastNamePettern), Validators.required]],
      phone: ['', [Validators.pattern(this.phoneNumberPattern), Validators.required]],
      address: [''],
      email: ['', [Validators.pattern(this.emailPattern), Validators.required]],
      password: [''],
      birthDate: [''],
      changePassword: [false],
    });
    if (this.user) {
      this.userInformation.controls.firstName.setValue(this.userStored.firstName);
      this.userInformation.controls.lastName.setValue(this.userStored.lastName);
      this.userInformation.controls.phone.setValue(this.userStored.phone);
      this.userInformation.controls.address.setValue(this.userStored.address);
      this.userInformation.controls.email.setValue(this.userStored.email);
      if (this.userStored.birthDate != null) {
        var datetime = moment(this.userStored.birthDate);
        this.userInformation.controls.birthDate.setValue(datetime.format('YYYY-MM-DD'));
      }
    }
    this.getResume();
  }

  public getResume(): void {
    this.userService.getResume(this.user.id).subscribe((data) => {
      this.userResume = data;
      this.userResume.categories = JSON.parse(data.categories);
      this.userResume.experienceLevel = JSON.parse(data.experienceLevel);
      console.log(this.userResume);
    });
  }

  public saveUserInformations(): void {
    if (this.userInformation.invalid) {
      this.isSecondBlockErrorShow = true;
      this.isFirstBlockErrorShow = true;
      return;
    }
    const sendData = new FormData();
    if (this.userInformation.controls.firstName.value) {
      sendData.append('firstName', this.userInformation.controls.firstName.value);
    }
    if (this.userInformation.controls.lastName.value) {
      sendData.append('lastName', this.userInformation.controls.lastName.value);
    }
    if (this.userInformation.controls.email.value) {
      sendData.append('email', this.userInformation.controls.email.value);
    }
    if (this.userInformation.controls.birthDate.value) {
      sendData.append('birthDate', this.userInformation.controls.birthDate.value ? formatDate(this.userInformation.controls.birthDate.value, 'yyyy-MM-dd HH:mm:SS', 'fr-FR') : '');
    }
    if (this.userInformation.controls.phone.value) {
      sendData.append('phone', this.userInformation.controls.phone.value);
    }
    if (this.userInformation.controls.password.value) {
      sendData.append('password', this.userInformation.controls.password.value);
    }
    if (this.userInformation.controls.address.value) {
      sendData.append('address', this.userInformation.controls.address.value);
    }
    if (this.userAvatar) {
      sendData.append('image', this.userAvatar);
    }
    this.userService.updateUserInfos(sendData, this.authenticationService.userValue.id).subscribe((data) => {
      console.log(data);
      this.userStored.firstName = data.firstName;
      this.userStored.lastName = data.lastName;
      this.userStored.email = data.email;
      this.userStored.phone = data.phone;
      this.userStored.address = data.address;
      this.userStored.image = data.avatar;
      this.userStored.birthDate = data.birthDate;
      localStorage.setItem('user', JSON.stringify(this.userStored));
      this.snack.info('Infos à jour ! Bien joué ' + this.userStored.firstName + '.');
      // this.router.navigateByUrl('');
      // window.location.reload();
    }, (err) => {
      this.snack.error('erreur');
    });
  }
  validateFirstBlock() {
    this.isFirstBlockErrorShow = true;
  }
  validateSecondBlock() {
    this.isSecondBlockErrorShow = true;
  }
  // public onPersonEdit(e: any): void {
  //   this.userAvatar = e.target.files[0];
  // }



  public onSelectFile(event): void {
    if (event.target.files && event.target.files[0]) {
      this.userAvatar = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.url = e.target.result;
      };
    }
  }
  public delete(): void {
    this.url = null;
  }
}
